@import "./theme/main.scss";
@import "./common/functions.scss";

.white-space-no-wrap {
	white-space: nowrap;
}

.notifications {
	& .cdk-virtual-scroll-viewport {
		width: 100%;
		max-height: 50vh;
		contain: content;

		& .cdk-virtual-scroll-content-wrapper {
			position: relative;
		}
	}
}

.alert {
	font-size: 0.975rem;

	& p {
		margin-bottom: 0;
		font-size: 0.975rem;
		line-height: 1.5;
	}

	&.alert-warning {
		border: 1px solid #ffe8a1;
	}

	&.alert-secondary {
		color: #ffffff;
		background-color: #71869d;
		border: 1px solid #647991;

		& hr {
			border-color: #647991;
		}

		& p {
			color: rgba(255, 255, 255, 0.8);
		}
	}

	&.alert-success {
		color: #ffffff;
		background-color: #00c9a7;
		border: 1px solid #00b092;

		& hr {
			border-color: #00b092;
		}
		& p {
			color: rgba(255, 255, 255, 0.8);
		}
	}
}

.accordion {
	& .accordion-item {
		border: 1px solid #ebebeb;
		border-radius: 0.3125rem;
		margin-bottom: 1rem;

		& .accordion-header {
			padding: 0;
			margin-bottom: 0;
			border-bottom: 1px solid #ebebeb;

			& .accordion-button {
				display: flex;
				text-align: left;
				width: 100%;
				justify-content: space-between;
				color: #000000;
				font-weight: 500;
				background-color: transparent;
				border: none;
				padding: 1rem;
				font-size: 1.1rem;
				border-radius: 5px;

				&:hover,
				&:not(.collapsed) {
					background-color: #f8fafd;
					color: #007bd2;
				}

				& .accordion-button-arrow {
					transform: rotate(0deg);
					transition: all ease-in-out var(--main-transition);
				}
				&.collapsed {
					& .accordion-button-arrow {
						transform: rotate(-90deg);
						transition: all ease-in-out var(--main-transition);
					}
				}
			}
		}

		& .accordion-body {
			color: var(--secondary) !important;
			padding: 1rem;
		}
	}
}
@media screen and (max-width: 419px) {
	.accordion {
		& .accordion-header {
			& .button {
				padding: 0.5rem !important;
			}
		}
	}
}

.btn {
	&.btn-badge {
		cursor: initial;
		border-radius: 6.1875rem;
	}

	&.btn-invest-now {
		background: linear-gradient(to right, #00c854 0%, #7bc800 100%);
		padding: 0 2.5rem;
		height: 50px;
		line-height: 50px;
		border: none;
		width: auto;
		color: var(--white);
		cursor: pointer;

		&.btn-sm {
			padding: 0.625rem 1.125rem;
			line-height: 1.5;
			height: max-content;
		}
	}

	&.btn-begin-now {
		background: linear-gradient(to right, #ff6d1f 0%, #ffca22 100%);
		padding: 0 2rem;
		height: 50px;
		line-height: 50px;
		border: none;
		width: auto;
		color: var(--white);
		display: inline-block;

		&.btn-sm {
			padding: 0.625rem 1.125rem;
			line-height: 1.5;
			height: max-content;
		}
	}

	&.btn-facebook {
		background: #3b5998;
		color: #fff;
	}

	&.btn-whatsapp {
		background: #25d366;
		color: #fff;
	}

	&.btn-linkedin {
		background: #0e76a8;
		color: #fff;
	}

	&.btn-twitter {
		background: #1da1f2;
		color: #fff;
	}

	&.btn-pulse {
		position: relative;

		& * {
			position: relative;
			z-index: 1;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: inherit;
			border-radius: inherit;
			z-index: 0;
			animation: btn-pulse 2s infinite;

			@keyframes btn-pulse {
				0% {
					box-shadow: 0 0 0 0 rgba(0, 123, 210, 0.7);
				}
				70% {
					box-shadow: 0 0 0 10px rgba(0, 123, 210, 0);
				}
				100% {
					box-shadow: 0 0 0 0 rgba(0, 123, 210, 0);
				}
			}
		}
	}
}

.confetti-container {
	position: relative;

	& :not(.confetti-canvas) {
		position: relative;
		z-index: 1;
	}

	& .confetti-canvas {
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: transparent;
		z-index: 0;
	}
}

.sticky {
	position: sticky;
	top: 0;
	left: 0;
}

.iframe-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	&.aspect-ratio-16-9 {
		padding-bottom: 56.25%;
	}

	&.aspect-ratio-4-3 {
		padding-bottom: 75%;
	}

	& .iframe-responsive,
	& iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}
.alert {
	&.border {
		&.alert-warning {
			border: 1px solid #ffe8a1 !important;
		}
		&.alert-primary {
			border: 1px solid #c0d6ff !important;
		}
		&.alert-secondary {
			border: 1px solid #647991 !important;
		}
		&.alert-success {
			border: 1px solid #00b092 !important;
		}
	}
}
.table {
	&.table-striped {
		& tbody {
			& tr:nth-child(even) {
				background-color: #fff;
			}

			& tr:nth-child(odd) {
				background-color: #f8fafd;
			}
		}
	}

	& td.max-content {
		min-width: max-content;
		white-space: nowrap;
	}
}
.skeleton-container {
	&.active {
		& .skeleton-item {
			box-sizing: border-box;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			border-radius: inherit;
			background-color: #dedfe1;
			overflow: hidden;

			&::before {
				background-size: 200px 100%;
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 200px;
				height: 100%;
				content: "";
				background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
				background-repeat: no-repeat;

				@include Animation(skeleton-loading, 1s, infinite, ease-in-out) {
					0% {
						transform: translate3d(-200px, 0, 0);
					}
					100% {
						transform: translate3d(calc(200px + 100vw), 0, 0);
					}
				}
			}
		}
	}
}

.pulse {
	@include Animation(pulse, 1.5s, infinite, ease-in-out) {
		0% {
			opacity: 0;
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
			filter: alpha(opacity=0);
		}

		60% {
			opacity: 1;
			-ms-filter: none;
			-webkit-filter: none;
			filter: none;
		}

		to {
			opacity: 0;
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
			filter: alpha(opacity=0);
		}
	}
}

.document-dom {
	all: initial;

	& * {
		all: revert;
	}

	& body {
		font-size: 11px;
		font-family: "Times New Roman", "Arial";

		& p {
			line-height: 1.8 !important;
		}
	}

	& .container {
		width: 80%;
		margin: auto;
	}

	& .page-break {
		page-break-after: always;
	}

	& ol {
		& li {
			line-height: 1.5;
			text-align: justify;
			margin-bottom: 15px;
		}
	}

	& hr {
		margin: 45px 0;
	}
}
input:-webkit-autofill,
select:-webkit-autofill,
textarea:-webkit-autofill {
	background-clip: text;
	-webkit-background-clip: text;
	font-size: 1rem;
}
