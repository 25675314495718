@import url("~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css");
@import url("~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css");
@import url("~animate.css/animate.min.css");
@import url("~ng-zorro-antd/message/style/index.min.css");
@import url("~ng-zorro-antd/progress/style/index.min.css");

@import "./assets/css/fontawesome-all.min.css";
@import "./assets/css/fonts-googleapis.css";
@import "./assets/css/theme.css";
@import "./assets/css/global/index.scss";

html,
body {
	height: 100%;
	width: 100%;
}

.box {
	display: -webkit-box;
	display: -moz-box;
	-webkit-line-clamp: 4;
	-moz-line-clamp: 4;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	max-width: 100%;
	overflow: hidden;
}

.box-line-1 {
	-webkit-line-clamp: 1;
	-moz-line-clamp: 1;
}
.box-line-2 {
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
}
.box-line-3 {
	-webkit-line-clamp: 3;
	-moz-line-clamp: 3;
}
.box-line-4 {
	-webkit-line-clamp: 4;
	-moz-line-clamp: 4;
}
.box-line-5 {
	-webkit-line-clamp: 5;
	-moz-line-clamp: 5;
}

.grecaptcha-badge {
	display: none;
}

svg {
	display: block;
}

body {
	overflow-x: hidden;
}

.cdk-overlay-container {
	z-index: 2000;
}

.ant-message {
	top: 50px !important;
	z-index: 2000;

	& .ant-message-notice {
		display: flex;
		justify-content: center;
		align-self: center;

		& .ant-message-notice-content {
			padding: 0;
			background: none;
			width: 100%;
			max-width: max-content;
		}

		& .ant-message-custom-content {
			display: flex;
			align-items: center;
			background-color: var(--white);
			color: var(--dark);
			border-radius: 0.3125rem;
			padding: 10px 16px;

			&.ant-message-success {
				background: var(--success);
				color: var(--white);

				& .anticon {
					color: var(--white);
				}
			}

			&.ant-message-info {
				background: var(--secondary);
				color: var(--white);

				& .anticon {
					color: var(--white);
				}
			}

			&.ant-message-warning {
				background: #ff6d1f;
				color: var(--white);

				& .anticon {
					color: var(--white);
				}
			}

			&.ant-message-error {
				background: var(--danger);
				color: var(--white);

				& .anticon {
					color: var(--white);
				}
			}

			& > i {
				display: flex;
				align-items: baseline;
			}
		}
	}
}

.color-contrast {
	background: inherit;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	filter: invert(1) grayscale(1) contrast(9) saturate(0);
}

/* END - Custom style to ng-zorro-antd message, progress */

.peach-gradient {
	color: white !important;
	background: linear-gradient(40deg, #fc6262, #ffd86f) !important;
}

/* INIT - Component app-project-tabs-calculator */
.calculator-body span.irs-single:before {
	content: "";
	height: 20px;
	width: 20px;
	position: absolute;
	left: 50%;
	bottom: -7px;
	transform: rotate(45deg);
	background: #fff;
	border-radius: 0.3125rem;
	margin-left: -10px;
}

.calculator-body span.irs-single strong {
	position: relative;
}

.calculator-body .irs-bar,
.calculator-body .irs-bar-edge {
	height: 10px;
	top: initial;
	bottom: 7px;
}

.calculator-body .irs-slider.single {
	top: initial;
	bottom: 0;
}
/* END - Component app-project-tabs-calculator */

.main-hero-404 svg,
.main-hero-channels svg {
	width: 100%;
}

.invalid-form-field-custom {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #de4437;
}

.c-pointer {
	cursor: pointer !important;
}
.c-initial {
	cursor: initial !important;
}
.sup-small {
	font-size: 1.5rem;
	text-indent: -2pt;
	font-weight: 300;
}

$sizes: "", "-sm", "-md", "-lg", "-xl";

@mixin space-size($size) {
	.space#{$size}-2-5 {
		padding-top: 6rem !important;
		padding-bottom: 6rem !important;
	}
	.space-top#{$size}-2-5 {
		padding-top: 6rem !important;
	}
	.space-bottom#{$size}-2-5 {
		padding-bottom: 6rem !important;
	}
	.space#{$size}-3-5 {
		padding-top: 10rem !important;
		padding-bottom: 10rem !important;
	}
	.space-top#{$size}-3-5 {
		padding-top: 10rem !important;
	}
	.space-bottom#{$size}-3-5 {
		padding-bottom: 10rem !important;
	}
}

@each $size in $sizes {
	@if $size == "-sm" {
		@media (min-width: 576px) {
			@include space-size($size);
		}
	} @else if $size == "-md" {
		@media (min-width: 768px) {
			@include space-size($size);
		}
	} @else if $size == "-lg" {
		@media (min-width: 992px) {
			@include space-size($size);
		}
	} @else if $size == "-xl" {
		@media (min-width: 1200px) {
			@include space-size($size);
		}
	} @else {
		@include space-size($size);
	}
}

.img-preset {
	position: relative;
	background-size: 30% auto;
	background-color: rgba(0, 0, 0, 0.1);
	background-repeat: no-repeat;
	background-position: 50%;

	& img {
		display: inline-block;
		position: absolute;
		width: 100%;
		object-fit: contain;

		&.even {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		&.portrait {
			width: 100%;
			height: auto;
		}
		&.portrait-center {
			width: 100%;
			top: 50%;
			transform: translateY(-50%);
		}
		&.landscape {
			width: auto;
			height: 100%;
		}
		&.landscape-center {
			height: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
