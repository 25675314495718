@mixin Animation($name, $duration: 250ms, $iteration: inifinite, $timing: ease-in-out) {
	animation-name: $name;
	animation-duration: $duration;
	animation-iteration-count: $iteration;
	animation-timing-function: $timing;

	@keyframes #{$name} {
		@content;
	}
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-o-keyframes #{$name} {
		@content;
	}
}
