@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
  --blue: #007bd2;
  --indigo: #2d1582;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #de4437;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #00c9a7;
  --cyan: #00dffc;
  --white: #ffffff;
  --gray: #8c98a4;
  --gray-dark: #343a40;
  --primary: #007bd2;
  --secondary: #77838f;
  --success: #00c9a7;
  --info: #00dffc;
  --warning: #ffc107;
  --danger: #de4437;
  --light: #f8f9fa;
  --dark: #1e2022;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Poppins", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  width: 100%;
  margin: 0;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* color: var(--dark); */
  text-align: left;
  background-color: var(--white);
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.05rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 90%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  font-size: 1.05rem;
  color: var(--primary);
  text-decoration: none;
  background-color: transparent;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--gray);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 1.5;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.1rem;
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e7eaf3;
}

small, .small {
  font-size: 90%;
  font-weight: 400;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: var(--gray);
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--white);
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: var(--gray);
}

code {
  font-size: 87.5%;
  color: var(--pink);
  word-break: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: var(--white);
  background-color: var(--dark);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: var(--dark);
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col, .no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--dark);
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e7eaf3;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e7eaf3;
}

.table tbody+tbody {
  border-top: 2px solid #e7eaf3;
}

.table-sm th, .table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e7eaf3;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #e7eaf3;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody+tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8fafd;
}

.table-hover tbody tr:hover {
  color: var(--dark);
  background-color: rgba(231, 234, 243, 0.4);
}

.table-primary, .table-primary>th, .table-primary>td {
  background-color: #c7dbff;
}

.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody+tbody {
  border-color: #97bbff;
}

.table-hover .table-primary:hover {
  background-color: #aecbff;
}

.table-hover .table-primary:hover>td, .table-hover .table-primary:hover>th {
  background-color: #aecbff;
}

.table-secondary, .table-secondary>th, .table-secondary>td {
  background-color: #d9dce0;
}

.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody+tbody {
  border-color: #b8bfc5;
}

.table-hover .table-secondary:hover {
  background-color: #cbcfd5;
}

.table-hover .table-secondary:hover>td, .table-hover .table-secondary:hover>th {
  background-color: #cbcfd5;
}

.table-success, .table-success>th, .table-success>td {
  background-color: #b8f0e6;
}

.table-success th, .table-success td, .table-success thead th, .table-success tbody+tbody {
  border-color: #7ae3d1;
}

.table-hover .table-success:hover {
  background-color: #a3ecdf;
}

.table-hover .table-success:hover>td, .table-hover .table-success:hover>th {
  background-color: #a3ecdf;
}

.table-info, .table-info>th, .table-info>td {
  background-color: #b8f6fe;
}

.table-info th, .table-info td, .table-info thead th, .table-info tbody+tbody {
  border-color: #7aeefd;
}

.table-hover .table-info:hover {
  background-color: #9ff3fe;
}

.table-hover .table-info:hover>td, .table-hover .table-info:hover>th {
  background-color: #9ff3fe;
}

.table-warning, .table-warning>th, .table-warning>td {
  background-color: #ffeeba;
}

.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody+tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td, .table-hover .table-warning:hover>th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger>th, .table-danger>td {
  background-color: #f6cbc7;
}

.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody+tbody {
  border-color: #ee9e97;
}

.table-hover .table-danger:hover {
  background-color: #f2b7b1;
}

.table-hover .table-danger:hover>td, .table-hover .table-danger:hover>th {
  background-color: #f2b7b1;
}

.table-light, .table-light>th, .table-light>td {
  background-color: #fdfdfe;
}

.table-light th, .table-light td, .table-light thead th, .table-light tbody+tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover>td, .table-hover .table-light:hover>th {
  background-color: #ececf6;
}

.table-dark, .table-dark>th, .table-dark>td {
  background-color: #c0c1c1;
}

.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody+tbody {
  border-color: #8a8b8c;
}

.table-hover .table-dark:hover {
  background-color: #b3b4b4;
}

.table-hover .table-dark:hover>td, .table-hover .table-dark:hover>th {
  background-color: #b3b4b4;
}

.table-white, .table-white>th, .table-white>td {
  background-color: var(--white);
}

.table-white th, .table-white td, .table-white thead th, .table-white tbody+tbody {
  border-color: var(--white);
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover>td, .table-hover .table-white:hover>th {
  background-color: #f2f2f2;
}

.table-indigo, .table-indigo>th, .table-indigo>td {
  background-color: #c4bddc;
}

.table-indigo th, .table-indigo td, .table-indigo thead th, .table-indigo tbody+tbody {
  border-color: #9285be;
}

.table-hover .table-indigo:hover {
  background-color: #b5acd3;
}

.table-hover .table-indigo:hover>td, .table-hover .table-indigo:hover>th {
  background-color: #b5acd3;
}

.table-active, .table-active>th, .table-active>td {
  background-color: rgba(231, 234, 243, 0.4);
}

.table-hover .table-active:hover {
  background-color: rgba(214, 219, 235, 0.4);
}

.table-hover .table-active:hover>td, .table-hover .table-active:hover>th {
  background-color: rgba(214, 219, 235, 0.4);
}

.table .thead-dark th {
  color: var(--white);
  background-color: var(--gray-dark);
  border-color: #454d55;
}

.table .thead-light th {
  color: var(--secondary);
  background-color: #f8fafd;
  border-color: #e7eaf3;
}

.table-dark {
  color: var(--white);
  background-color: var(--gray-dark);
}

.table-dark th, .table-dark td, .table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--dark);
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid #d5dae2;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: var(--dark);
  background-color: var(--white);
  border-color: #d5dae2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: var(--gray);
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: var(--gray);
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: var(--gray);
  opacity: 1;
}

.form-control::placeholder {
  color: var(--gray);
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f8fafd;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: var(--dark);
  background-color: var(--white);
}

.form-control-file, .form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1.125rem + 1px);
  padding-bottom: calc(1.125rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--dark);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.form-control-lg {
  height: calc(1.5em + 2.25rem + 2px);
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.625rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col, .form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
  color: var(--gray);
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--success);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--white);
  background-color: var(--success);
  border-radius: 0.3125rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--success);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.375rem);
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--success);
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .form-control:valid~.valid-feedback, .was-validated .form-control:valid~.valid-tooltip, .form-control.is-valid~.valid-feedback, .form-control.is-valid~.valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: var(--success);
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") var(--white) no-repeat center right 2rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: var(--success);
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .custom-select:valid~.valid-feedback, .was-validated .custom-select:valid~.valid-tooltip, .custom-select.is-valid~.valid-feedback, .custom-select.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid~.valid-feedback, .was-validated .form-control-file:valid~.valid-tooltip, .form-control-file.is-valid~.valid-feedback, .form-control-file.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
  color: var(--success);
}

.was-validated .form-check-input:valid~.valid-feedback, .was-validated .form-check-input:valid~.valid-tooltip, .form-check-input.is-valid~.valid-feedback, .form-check-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label, .custom-control-input.is-valid~.custom-control-label {
  color: var(--success);
}

.was-validated .custom-control-input:valid~.custom-control-label::before, .custom-control-input.is-valid~.custom-control-label::before {
  border-color: var(--success);
}

.was-validated .custom-control-input:valid~.valid-feedback, .was-validated .custom-control-input:valid~.valid-tooltip, .custom-control-input.is-valid~.valid-feedback, .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before, .custom-control-input.is-valid:checked~.custom-control-label::before {
  border-color: #00fcd1;
  background-color: #00fcd1;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before, .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
  border-color: var(--success);
}

.was-validated .custom-file-input:valid~.custom-file-label, .custom-file-input.is-valid~.custom-file-label {
  border-color: var(--success);
}

.was-validated .custom-file-input:valid~.valid-feedback, .was-validated .custom-file-input:valid~.valid-tooltip, .custom-file-input.is-valid~.valid-feedback, .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label, .custom-file-input.is-valid:focus~.custom-file-label {
  border-color: var(--success);
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--danger);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--white);
  background-color: var(--danger);
  border-radius: 0.3125rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--danger);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23de4437' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23de4437' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.375rem);
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--danger);
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback, .was-validated .form-control:invalid~.invalid-tooltip, .form-control.is-invalid~.invalid-feedback, .form-control.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: var(--danger);
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23de4437' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23de4437' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") var(--white) no-repeat center right 2rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: var(--danger);
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.was-validated .custom-select:invalid~.invalid-feedback, .was-validated .custom-select:invalid~.invalid-tooltip, .custom-select.is-invalid~.invalid-feedback, .custom-select.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback, .was-validated .form-control-file:invalid~.invalid-tooltip, .form-control-file.is-invalid~.invalid-feedback, .form-control-file.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid~.form-check-label, .form-check-input.is-invalid~.form-check-label {
  color: var(--danger);
}

.was-validated .form-check-input:invalid~.invalid-feedback, .was-validated .form-check-input:invalid~.invalid-tooltip, .form-check-input.is-invalid~.invalid-feedback, .form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label, .custom-control-input.is-invalid~.custom-control-label {
  color: var(--danger);
}

.was-validated .custom-control-input:invalid~.custom-control-label::before, .custom-control-input.is-invalid~.custom-control-label::before {
  border-color: var(--danger);
}

.was-validated .custom-control-input:invalid~.invalid-feedback, .was-validated .custom-control-input:invalid~.invalid-tooltip, .custom-control-input.is-invalid~.invalid-feedback, .custom-control-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before, .custom-control-input.is-invalid:checked~.custom-control-label::before {
  border-color: #e56d63;
  background-color: #e56d63;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before, .custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: var(--danger);
}

.was-validated .custom-file-input:invalid~.custom-file-label, .custom-file-input.is-invalid~.custom-file-label {
  border-color: var(--danger);
}

.was-validated .custom-file-input:invalid~.invalid-feedback, .was-validated .custom-file-input:invalid~.invalid-tooltip, .custom-file-input.is-invalid~.invalid-feedback, .custom-file-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label, .custom-file-input.is-invalid:focus~.custom-file-label {
  border-color: var(--danger);
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group, .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 500;
  color: var(--dark);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--dark);
  text-decoration: none;
  /*  box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08) !important; */
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  color: var(--white);
  background-color: #1164ff;
  border-color: #045cff;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 145, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
  color: var(--white);
  background-color: #006ebd;
  border-color: #006ebd;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 145, 255, 0.5);
}

.btn-dashed{
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary);
  border-style: dashed;
  font-size: .9rem;
}

.btn-secondary {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  color: var(--white);
  background-color: #65707b;
  border-color: #5f6a74;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 150, 160, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show>.btn-secondary.dropdown-toggle {
  color: var(--white);
  background-color: #5f6a74;
  border-color: #5a636d;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 150, 160, 0.5);
}

.btn-success {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.btn-success:hover {
  color: var(--white);
  background-color: #00a387;
  border-color: #00967d;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show>.btn-success.dropdown-toggle {
  color: var(--white);
  background-color: #00967d;
  border-color: #008972;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-info {
  color: var(--dark);
  background-color: var(--info);
  border-color: var(--info);
}

.btn-info:hover {
  color: var(--white);
  background-color: #00bdd6;
  border-color: #00b2c9;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 194, 219, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: var(--dark);
  background-color: var(--info);
  border-color: var(--info);
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show>.btn-info.dropdown-toggle {
  color: var(--white);
  background-color: #00b2c9;
  border-color: #00a7bc;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 194, 219, 0.5);
}

.btn-warning {
  color: var(--dark);
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-warning:hover {
  color: var(--dark);
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 169, 11, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: var(--dark);
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show>.btn-warning.dropdown-toggle {
  color: var(--dark);
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 169, 11, 0.5);
}

.btn-danger {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-danger:hover {
  color: var(--white);
  background-color: #cd2f22;
  border-color: #c22d20;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 96, 85, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show>.btn-danger.dropdown-toggle {
  color: var(--white);
  background-color: #c22d20;
  border-color: #b72a1e;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 96, 85, 0.5);
}

.btn-light {
  color: var(--dark);
  background-color: var(--light);
  border-color: var(--light);
}

.btn-light:hover {
  color: var(--dark);
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 216, 218, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: var(--dark);
  background-color: var(--light);
  border-color: var(--light);
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show>.btn-light.dropdown-toggle {
  color: var(--dark);
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 216, 218, 0.5);
}

.btn-dark {
  color: var(--white);
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-dark:hover {
  color: var(--white);
  background-color: #0c0d0e;
  border-color: #060707;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 65, 67, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: var(--white);
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show>.btn-dark.dropdown-toggle {
  color: var(--white);
  background-color: #060707;
  border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 65, 67, 0.5);
}

.btn-white {
  color: var(--dark);
  background-color: var(--white);
  border-color: var(--white);
}

.btn-white:hover {
  color: var(--dark);
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white:focus, .btn-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 222, 222, 0.5);
}

.btn-white.disabled, .btn-white:disabled {
  color: var(--dark);
  background-color: var(--white);
  border-color: var(--white);
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show>.btn-white.dropdown-toggle {
  color: var(--dark);
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show>.btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 222, 222, 0.5);
}

.btn-indigo {
  color: var(--white);
  background-color: var(--indigo);
  border-color: var(--indigo);
}

.btn-indigo:hover {
  color: var(--white);
  background-color: #221061;
  border-color: #1e0e56;
}

.btn-indigo:focus, .btn-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 56, 149, 0.5);
}

.btn-indigo.disabled, .btn-indigo:disabled {
  color: var(--white);
  background-color: var(--indigo);
  border-color: var(--indigo);
}

.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active, .show>.btn-indigo.dropdown-toggle {
  color: var(--white);
  background-color: #1e0e56;
  border-color: #1a0c4b;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus, .show>.btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 56, 149, 0.5);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: var(--primary);
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show>.btn-outline-primary.dropdown-toggle {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
}

.btn-outline-secondary {
  color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-secondary:hover {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 131, 143, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: var(--secondary);
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show>.btn-outline-secondary.dropdown-toggle {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 131, 143, 0.5);
}

.btn-outline-success {
  color: var(--success);
  border-color: var(--success);
}

.btn-outline-success:hover {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: var(--success);
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show>.btn-outline-success.dropdown-toggle {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-info {
  color: var(--info);
  border-color: var(--info);
}

.btn-outline-info:hover {
  color: var(--dark);
  background-color: var(--info);
  border-color: var(--info);
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: var(--info);
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show>.btn-outline-info.dropdown-toggle {
  color: var(--dark);
  background-color: var(--info);
  border-color: var(--info);
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.btn-outline-warning {
  color: var(--warning);
  border-color: var(--warning);
}

.btn-outline-warning:hover {
  color: var(--dark);
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: var(--warning);
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show>.btn-outline-warning.dropdown-toggle {
  color: var(--dark);
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: var(--danger);
  border-color: var(--danger);
}

.btn-outline-danger:hover {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: var(--danger);
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show>.btn-outline-danger.dropdown-toggle {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}

.btn-outline-light {
  color: var(--light);
  border-color: var(--light);
}

.btn-outline-light:hover {
  color: var(--dark);
  background-color: var(--light);
  border-color: var(--light);
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: var(--light);
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show>.btn-outline-light.dropdown-toggle {
  color: var(--dark);
  background-color: var(--light);
  border-color: var(--light);
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: var(--dark);
  border-color: var(--dark);
}

.btn-outline-dark:hover {
  color: var(--white);
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: var(--dark);
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show>.btn-outline-dark.dropdown-toggle {
  color: var(--white);
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}

.btn-outline-white {
  color: var(--white);
  border-color: var(--white);
}

.btn-outline-white:hover {
  color: var(--dark);
  background-color: var(--white);
  border-color: var(--white);
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: var(--white);
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show>.btn-outline-white.dropdown-toggle {
  color: var(--dark);
  background-color: var(--white);
  border-color: var(--white);
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-indigo {
  color: var(--indigo);
  border-color: var(--indigo);
}

.btn-outline-indigo:hover {
  color: var(--white);
  background-color: var(--indigo);
  border-color: var(--indigo);
}

.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}

.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: var(--indigo);
  background-color: transparent;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active, .show>.btn-outline-indigo.dropdown-toggle {
  color: var(--white);
  background-color: var(--indigo);
  border-color: var(--indigo);
}

.btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}

.btn-link {
  font-weight: 400;
  color: var(--primary);
  text-decoration: none;
}

.btn-link:hover {
  color: #005d9f;
  text-decoration: none;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: var(--gray);
  pointer-events: none;
}

.btn-lg, .btn-group-lg>.btn {
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.625rem;
}

.btn-sm, .btn-group-sm>.btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  max-height: 1000px;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  max-height: 0;
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 8.4375rem;
  padding: 1rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: var(--dark);
  text-align: left;
  list-style: none;
  background-color: var(--white);
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e7eaf3;
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--secondary);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--dark);
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--white);
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--gray);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--gray);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: var(--secondary);
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn, .btn-group-vertical>.btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group>.btn:hover, .btn-group-vertical>.btn:hover {
  z-index: 1;
}

.btn-group>.btn:focus, .btn-group>.btn:active, .btn-group>.btn.active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child), .btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle), .btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child), .btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-lg+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical>.btn, .btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child), .btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child), .btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn, .btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"], .btn-group-toggle>.btn input[type="checkbox"], .btn-group-toggle>.btn-group>.btn input[type="radio"], .btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control, .input-group>.form-control-plaintext, .input-group>.custom-select, .input-group>.custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group>.form-control+.form-control, .input-group>.form-control+.custom-select, .input-group>.form-control+.custom-file, .input-group>.form-control-plaintext+.form-control, .input-group>.form-control-plaintext+.custom-select, .input-group>.form-control-plaintext+.custom-file, .input-group>.custom-select+.form-control, .input-group>.custom-select+.custom-select, .input-group>.custom-select+.custom-file, .input-group>.custom-file+.form-control, .input-group>.custom-file+.custom-select, .input-group>.custom-file+.custom-file {
  margin-left: -1px;
}

.input-group>.form-control:focus, .input-group>.custom-select:focus, .input-group>.custom-file .custom-file-input:focus~.custom-file-label {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.form-control:not(:last-child), .input-group>.custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child), .input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label, .input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend, .input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus, .input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn+.btn, .input-group-prepend .btn+.input-group-text, .input-group-prepend .input-group-text+.input-group-text, .input-group-prepend .input-group-text+.btn, .input-group-append .btn+.btn, .input-group-append .btn+.input-group-text, .input-group-append .input-group-text+.input-group-text, .input-group-append .input-group-text+.btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray);
  text-align: center;
  white-space: nowrap;
  background-color: var(--white);
  border: 1px solid #d5dae2;
  border-radius: 0.3125rem;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg>.form-control:not(textarea), .input-group-lg>.custom-select {
  height: calc(1.5em + 2.25rem + 2px);
}

.input-group-lg>.form-control, .input-group-lg>.custom-select, .input-group-lg>.input-group-prepend>.input-group-text, .input-group-lg>.input-group-append>.input-group-text, .input-group-lg>.input-group-prepend>.btn, .input-group-lg>.input-group-append>.btn {
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.625rem;
}

.input-group-sm>.form-control:not(textarea), .input-group-sm>.custom-select {
  height: calc(1.5em + 1.25rem + 2px);
}

.input-group-sm>.form-control, .input-group-sm>.custom-select, .input-group-sm>.input-group-prepend>.input-group-text, .input-group-sm>.input-group-append>.input-group-text, .input-group-sm>.input-group-prepend>.btn, .input-group-sm>.input-group-append>.btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.input-group-lg>.custom-select, .input-group-sm>.custom-select {
  padding-right: 2rem;
}

.input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text, .input-group>.input-group-append:not(:last-child)>.btn, .input-group>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  /* padding-left: 1.5rem; */
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: var(--white);
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #d5dae2;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: var(--white);
  background-color: #eaf1ff;
  border-color: #eaf1ff;
}

.custom-control-input:disabled~.custom-control-label {
  color: var(--gray);
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: #f8fafd;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: var(--white);
  border: #97a4af solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3125rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  /* display: none; */
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.custom-switch {
  padding-left: 3rem;
}

.custom-switch .custom-control-label::before {
  left: -3rem;
  width: 2.5rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-3rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #97a4af;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: var(--white);
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--dark);
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  background-color: var(--white);
  border: 1px solid #d5dae2;
  border-radius: 0.3125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: rgba(55, 125, 255, 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: var(--dark);
  background-color: var(--white);
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: var(--gray);
  background-color: #f8fafd;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 1.25rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.125rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 2.25rem + 2px);
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-left: 1.125rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
  border-color: #d5dae2;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.custom-file-input:disabled~.custom-file-label {
  background-color: #f8fafd;
}

.custom-file-input~.custom-file-label::after {
  content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray);
  background-color: var(--white);
  border: 1px solid #d5dae2;
  border-radius: 0.3125rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.5rem);
  padding: 0.75rem 1rem;
  line-height: 1.5;
  color: var(--gray);
  content: "Browse";
  background-color: var(--white);
  border-left: inherit;
  border-radius: 0 0.3125rem 0.3125rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px var(--white), 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px var(--white), 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px var(--white), 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #e7eaf3;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e7eaf3;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #97a4af;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #97a4af;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #97a4af;
}

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  /* display: block !important;
  padding-top: 1rem !important;
  padding-bottom: 1.5rem !important;
  padding-right: 0.875rem !important;
  padding-left: 0.875rem !important; */
  display: block!important;
  padding: 1rem .875rem !important;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: var(--gray);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e7eaf3;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f8fafd #f8fafd #e7eaf3;
}

.nav-tabs .nav-link.disabled {
  color: var(--gray);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--secondary);
  background-color: var(--white);
  border-color: #e7eaf3 #e7eaf3 var(--white);
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.3125rem;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--white);
  background-color: var(--primary);
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar>.container, .navbar>.container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  width: 183px !important;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.3125rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand>.container, .navbar-expand>.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand>.container, .navbar-expand>.container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link, .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: var(--white);
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: var(--white);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link, .navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: var(--white);
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: var(--white);
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: var(--white);
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white);
  background-clip: border-box;
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: var(--white);
  border-bottom: 1px solid #e7eaf3;
}

.card-header:first-child {
  border-radius: calc(0.3125rem - 1px) calc(0.3125rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1rem 1rem;
  background-color: var(--white);
  border-top: 1px solid #e7eaf3;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.3125rem - 1px) calc(0.3125rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -1rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.3125rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.3125rem - 1px);
  border-bottom-left-radius: calc(0.3125rem - 1px);
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group>.card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group>.card:not(:last-child) .card-img-top, .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group>.card:not(:last-child) .card-img-bottom, .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group>.card:not(:first-child) .card-img-top, .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group>.card:not(:first-child) .card-img-bottom, .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion>.card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.3125rem;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #97a4af;
  content: ">";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: var(--secondary);
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: var(--secondary);
  background-color: var(--white);
  border: 0 solid var(--secondary);
}

.page-link:hover {
  z-index: 2;
  color: var(--primary);
  text-decoration: none;
  background-color: rgba(55, 125, 255, 0.1);
  border-color: rgba(55, 125, 255, 0.1);
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.page-item.disabled .page-link {
  color: var(--gray);
  pointer-events: none;
  cursor: auto;
  background-color: var(--white);
  border-color: #e7eaf3;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: var(--white);
  background-color: var(--primary);
}

a.badge-primary:hover, a.badge-primary:focus {
  color: var(--white);
  background-color: #045cff;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
}

.badge-secondary {
  color: var(--white);
  background-color: var(--secondary);
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: var(--white);
  background-color: #5f6a74;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(119, 131, 143, 0.5);
}

.badge-success {
  color: var(--white);
  background-color: var(--success);
}

a.badge-success:hover, a.badge-success:focus {
  color: var(--white);
  background-color: #00967d;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.badge-info {
  color: var(--dark);
  background-color: var(--info);
}

a.badge-info:hover, a.badge-info:focus {
  color: var(--dark);
  background-color: #00b2c9;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.badge-warning {
  color: var(--dark);
  background-color: var(--warning);
}

a.badge-warning:hover, a.badge-warning:focus {
  color: var(--dark);
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: var(--white);
  background-color: var(--danger);
}

a.badge-danger:hover, a.badge-danger:focus {
  color: var(--white);
  background-color: #c22d20;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}

.badge-light {
  color: var(--dark);
  background-color: var(--light);
}

a.badge-light:hover, a.badge-light:focus {
  color: var(--dark);
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: var(--white);
  background-color: var(--dark);
}

a.badge-dark:hover, a.badge-dark:focus {
  color: var(--white);
  background-color: #060707;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}

.badge-white {
  color: var(--dark);
  background-color: var(--white);
}

a.badge-white:hover, a.badge-white:focus {
  color: var(--dark);
  background-color: #e6e6e6;
}

a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-indigo {
  color: var(--white);
  background-color: var(--indigo);
}

a.badge-indigo:hover, a.badge-indigo:focus {
  color: var(--white);
  background-color: #1e0e56;
}

a.badge-indigo:focus, a.badge-indigo.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f8fafd;
  border-radius: 0.625rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.3125rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3.75rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1d4185;
  background-color: #d7e5ff;
  border-color: #c7dbff;
}

.alert-primary hr {
  border-top-color: #aecbff;
}

.alert-primary .alert-link {
  color: #142d5b;
}

.alert-secondary {
  color: #3e444a;
  background-color: #e4e6e9;
  border-color: #d9dce0;
}

.alert-secondary hr {
  border-top-color: #cbcfd5;
}

.alert-secondary .alert-link {
  color: #272b2e;
}

.alert-success {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.alert-success hr {
  border-top-color: #a3ecdf;
}

.alert-success .alert-link {
  color: #00362d;
}

.alert-info {
  color: #007483;
  background-color: #ccf9fe;
  border-color: #b8f6fe;
}

.alert-info hr {
  border-top-color: #9ff3fe;
}

.alert-info .alert-link {
  color: #004750;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #73231d;
  background-color: #f8dad7;
  border-color: #f6cbc7;
}

.alert-danger hr {
  border-top-color: #f2b7b1;
}

.alert-danger .alert-link {
  color: #4a1713;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #101112;
  background-color: #d2d2d3;
  border-color: #c0c1c1;
}

.alert-dark hr {
  border-top-color: #b3b4b4;
}

.alert-dark .alert-link {
  color: black;
}

.alert-white {
  color: #858585;
  background-color:var(--white);
  border-color:var(--white);
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-indigo {
  color: #170b44;
  background-color: #d5d0e6;
  border-color: #c4bddc;
}

.alert-indigo hr {
  border-top-color: #b5acd3;
}

.alert-indigo .alert-link {
  color: #080418;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #eceff3;
  border-radius: 0.3125rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  background-color: var(--primary);
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: var(--secondary);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--primary);
  text-decoration: none;
  background-color: transparent;
}

.list-group-item-action:active {
  color: var(--primary);
  background-color: transparent;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.425rem 1.25rem;
  margin-bottom: -1px;
  background-color: var(--white);
  border: 1px solid #e7eaf3;
}

.list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--gray);
  pointer-events: none;
  background-color: var(--white);
}

.list-group-item.active {
  z-index: 2;
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  background: transparent;
}

.list-group-item-primary {
  color: #1d4185;
  background-color: #c7dbff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1d4185;
  background-color: #aecbff;
}

.list-group-item-primary.list-group-item-action.active {
  color: var(--white);
  background-color: #1d4185;
  border-color: #1d4185;
}

.list-group-item-secondary {
  color: #3e444a;
  background-color: #d9dce0;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #3e444a;
  background-color: #cbcfd5;
}

.list-group-item-secondary.list-group-item-action.active {
  color: var(--white);
  background-color: #3e444a;
  border-color: #3e444a;
}

.list-group-item-success {
  color: #006957;
  background-color: #b8f0e6;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #006957;
  background-color: #a3ecdf;
}

.list-group-item-success.list-group-item-action.active {
  color: var(--white);
  background-color: #006957;
  border-color: #006957;
}

.list-group-item-info {
  color: #007483;
  background-color: #b8f6fe;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #007483;
  background-color: #9ff3fe;
}

.list-group-item-info.list-group-item-action.active {
  color: var(--white);
  background-color: #007483;
  border-color: #007483;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: var(--white);
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #73231d;
  background-color: #f6cbc7;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #73231d;
  background-color: #f2b7b1;
}

.list-group-item-danger.list-group-item-action.active {
  color: var(--white);
  background-color: #73231d;
  border-color: #73231d;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: var(--white);
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #101112;
  background-color: #c0c1c1;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #101112;
  background-color: #b3b4b4;
}

.list-group-item-dark.list-group-item-action.active {
  color: var(--white);
  background-color: #101112;
  border-color: #101112;
}

.list-group-item-white {
  color: #858585;
  background-color: var(--white);
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: var(--white);
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-indigo {
  color: #170b44;
  background-color: #c4bddc;
}

.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: #170b44;
  background-color: #b5acd3;
}

.list-group-item-indigo.list-group-item-action.active {
  color: var(--white);
  background-color: #170b44;
  border-color: #170b44;
}

.close {
  float: right;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  color: var(--dark);
  text-shadow: 0 1px 0 var(--white);
  opacity: .5;
}

.close:hover {
  color: var(--dark);
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: var(--gray);
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.625rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e7eaf3;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e7eaf3;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.modal-footer> :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer> :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 500px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1040;
  display: block;
  margin: 0;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}


.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: var(--dark);
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: var(--dark);
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: var(--dark);
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: var(--dark);
}

.tooltip-inner {
  max-width: 400px;
  padding: 0.25rem 0.5rem;
  color: var(--white);
  text-align: center;
  background-color: var(--dark);
  border-radius: 0.3125rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1040;
  display: block;
  max-width: 276px;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.625rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top>.arrow, .bs-popover-auto[x-placement^="top"]>.arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top>.arrow::before, .bs-popover-auto[x-placement^="top"]>.arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #e7eaf3;
}

.bs-popover-top>.arrow::after, .bs-popover-auto[x-placement^="top"]>.arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: var(--white);
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right>.arrow, .bs-popover-auto[x-placement^="right"]>.arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.625rem 0;
}

.bs-popover-right>.arrow::before, .bs-popover-auto[x-placement^="right"]>.arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #e7eaf3;
}

.bs-popover-right>.arrow::after, .bs-popover-auto[x-placement^="right"]>.arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: var(--white);
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow, .bs-popover-auto[x-placement^="bottom"]>.arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom>.arrow::before, .bs-popover-auto[x-placement^="bottom"]>.arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #e7eaf3;
}

.bs-popover-bottom>.arrow::after, .bs-popover-auto[x-placement^="bottom"]>.arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: var(--white);
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f8fafd;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left>.arrow, .bs-popover-auto[x-placement^="left"]>.arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.625rem 0;
}

.bs-popover-left>.arrow::before, .bs-popover-auto[x-placement^="left"]>.arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #e7eaf3;
}

.bs-popover-left>.arrow::after, .bs-popover-auto[x-placement^="left"]>.arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--white);
}

.popover-header {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: var(--dark);
  background-color: #f8fafd;
  border-bottom: 1px solid #e4ecf7;
  border-top-left-radius: calc(0.625rem - 1px);
  border-top-right-radius: calc(0.625rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.75rem 1rem;
  color: var(--dark);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: var(--white);
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: var(--white);
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--white);
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--white);
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  vertical-align: text-bottom;
  border: 0.15rem solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .5s linear infinite;
  animation: spinner-border .5s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #045cff !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #5f6a74 !important;
}

.bg-success {
  background-color: var(--success) !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #00967d !important;
}

.bg-info {
  background-color: var(--info) !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #00b2c9 !important;
}

.bg-warning {
  background-color: var(--warning) !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: var(--danger) !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #c22d20 !important;
}

.bg-light {
  background-color: var(--light) !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #060707 !important;
}

.bg-white {
  background-color: var(--white) !important;
}

a.bg-white:hover, a.bg-white:focus, button.bg-white:hover, button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-indigo {
  background-color: var(--indigo) !important;
}

a.bg-indigo:hover, a.bg-indigo:focus, button.bg-indigo:hover, button.bg-indigo:focus {
  background-color: #1e0e56 !important;
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e7eaf3 !important;
}

.border-top {
  border-top: 1px solid #e7eaf3 !important;
}

.border-right {
  border-right: 1px solid #e7eaf3 !important;
}

.border-bottom {
  border-bottom: 1px solid #e7eaf3 !important;
}

.border-left {
  border-left: 1px solid #e7eaf3 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-secondary {
  border-color: var(--secondary) !important;
}

.border-success {
  border-color: var(--success) !important;
}

.border-info {
  border-color: var(--info) !important;
}

.border-warning {
  border-color: var(--warning) !important;
}

.border-danger {
  border-color: var(--danger) !important;
}

.border-light {
  border-color: var(--light) !important;
}

.border-dark {
  border-color: var(--dark) !important;
}

.border-white {
  border-color: var(--white) !important;
}

.border-indigo {
  border-color: var(--indigo) !important;
}

.border-white {
  border-color: var(--white) !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: 0.3125rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}

.rounded-right {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-lg {
  border-radius: 0.625rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important;
}

.shadow {
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125) !important;
}

.shadow-lg {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1, .my-1 {
  margin-top: 0.25rem !important;
}

.mr-1, .mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1, .mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2, .my-2 {
  margin-top: 0.5rem !important;
}

.mr-2, .mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5, .my-5 {
  margin-top: 2rem !important;
}

.mr-5, .mx-5 {
  margin-right: 2rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 2rem !important;
}

.ml-5, .mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6, .my-6 {
  margin-top: 2.5rem !important;
}

.mr-6, .mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6, .my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6, .mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7, .my-7 {
  margin-top: 3rem !important;
}

.mr-7, .mx-7 {
  margin-right: 3rem !important;
}

.mb-7, .my-7 {
  margin-bottom: 3rem !important;
}

.ml-7, .mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8, .my-8 {
  margin-top: 3.5rem !important;
}

.mr-8, .mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8, .my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8, .mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9, .my-9 {
  margin-top: 4rem !important;
}

.mr-9, .mx-9 {
  margin-right: 4rem !important;
}

.mb-9, .my-9 {
  margin-bottom: 4rem !important;
}

.ml-9, .mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10, .my-10 {
  margin-top: 4.5rem !important;
}

.mr-10, .mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10, .my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10, .mx-10 {
  margin-left: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.mt-11, .my-11 {
  margin-top: 5rem !important;
}

.mr-11, .mx-11 {
  margin-right: 5rem !important;
}

.mb-11, .my-11 {
  margin-bottom: 5rem !important;
}

.ml-11, .mx-11 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1, .py-1 {
  padding-top: 0.25rem !important;
}

.pr-1, .px-1 {
  padding-right: 0.25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1, .px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2, .py-2 {
  padding-top: 0.5rem !important;
}

.pr-2, .px-2 {
  padding-right: 0.5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2, .px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5, .py-5 {
  padding-top: 2rem !important;
}

.pr-5, .px-5 {
  padding-right: 2rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 2rem !important;
}

.pl-5, .px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6, .py-6 {
  padding-top: 2.5rem !important;
}

.pr-6, .px-6 {
  padding-right: 2.5rem !important;
}

.pb-6, .py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6, .px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7, .py-7 {
  padding-top: 3rem !important;
}

.pr-7, .px-7 {
  padding-right: 3rem !important;
}

.pb-7, .py-7 {
  padding-bottom: 3rem !important;
}

.pl-7, .px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8, .py-8 {
  padding-top: 3.5rem !important;
}

.pr-8, .px-8 {
  padding-right: 3.5rem !important;
}

.pb-8, .py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8, .px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9, .py-9 {
  padding-top: 4rem !important;
}

.pr-9, .px-9 {
  padding-right: 4rem !important;
}

.pb-9, .py-9 {
  padding-bottom: 4rem !important;
}

.pl-9, .px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.pt-10, .py-10 {
  padding-top: 4.5rem !important;
}

.pr-10, .px-10 {
  padding-right: 4.5rem !important;
}

.pb-10, .py-10 {
  padding-bottom: 4.5rem !important;
}

.pl-10, .px-10 {
  padding-left: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.pt-11, .py-11 {
  padding-top: 5rem !important;
}

.pr-11, .px-11 {
  padding-right: 5rem !important;
}

.pb-11, .py-11 {
  padding-bottom: 5rem !important;
}

.pl-11, .px-11 {
  padding-left: 5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -2rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6, .my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6, .mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6, .my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6, .mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7, .my-n7 {
  margin-top: -3rem !important;
}

.mr-n7, .mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7, .my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7, .mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8, .my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8, .mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8, .my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8, .mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9, .my-n9 {
  margin-top: -4rem !important;
}

.mr-n9, .mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9, .my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9, .mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mt-n10, .my-n10 {
  margin-top: -4.5rem !important;
}

.mr-n10, .mx-n10 {
  margin-right: -4.5rem !important;
}

.mb-n10, .my-n10 {
  margin-bottom: -4.5rem !important;
}

.ml-n10, .mx-n10 {
  margin-left: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.mt-n11, .my-n11 {
  margin-top: -5rem !important;
}

.mr-n11, .mx-n11 {
  margin-right: -5rem !important;
}

.mb-n11, .my-n11 {
  margin-bottom: -5rem !important;
}

.ml-n11, .mx-n11 {
  margin-left: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5, .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6, .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6, .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6, .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6, .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7, .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7, .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7, .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7, .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8, .my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8, .mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8, .my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8, .mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9, .my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9, .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9, .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9, .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .mt-sm-10, .my-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-10, .mx-sm-10 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-10, .my-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-10, .mx-sm-10 {
    margin-left: 4.5rem !important;
  }
  .m-sm-11 {
    margin: 5rem !important;
  }
  .mt-sm-11, .my-sm-11 {
    margin-top: 5rem !important;
  }
  .mr-sm-11, .mx-sm-11 {
    margin-right: 5rem !important;
  }
  .mb-sm-11, .my-sm-11 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-11, .mx-sm-11 {
    margin-left: 5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5, .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5, .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5, .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6, .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6, .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6, .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6, .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7, .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7, .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7, .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7, .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8, .py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8, .px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8, .py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8, .px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9, .py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9, .px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9, .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9, .px-sm-9 {
    padding-left: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .pt-sm-10, .py-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-10, .px-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-10, .py-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-10, .px-sm-10 {
    padding-left: 4.5rem !important;
  }
  .p-sm-11 {
    padding: 5rem !important;
  }
  .pt-sm-11, .py-sm-11 {
    padding-top: 5rem !important;
  }
  .pr-sm-11, .px-sm-11 {
    padding-right: 5rem !important;
  }
  .pb-sm-11, .py-sm-11 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-11, .px-sm-11 {
    padding-left: 5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1, .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2, .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5, .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .mt-sm-n6, .my-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n6, .mx-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n6, .my-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n6, .mx-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7, .my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7, .mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7, .my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7, .mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .mt-sm-n8, .my-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n8, .mx-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n8, .my-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n8, .mx-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .mt-sm-n9, .my-sm-n9 {
    margin-top: -4rem !important;
  }
  .mr-sm-n9, .mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .mb-sm-n9, .my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n9, .mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mt-sm-n10, .my-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n10, .mx-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n10, .my-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n10, .mx-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n11 {
    margin: -5rem !important;
  }
  .mt-sm-n11, .my-sm-n11 {
    margin-top: -5rem !important;
  }
  .mr-sm-n11, .mx-sm-n11 {
    margin-right: -5rem !important;
  }
  .mb-sm-n11, .my-sm-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n11, .mx-sm-n11 {
    margin-left: -5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5, .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5, .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5, .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5, .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6, .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6, .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6, .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6, .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7, .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7, .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7, .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7, .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8, .my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8, .mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8, .my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8, .mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9, .my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9, .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9, .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9, .mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .mt-md-10, .my-md-10 {
    margin-top: 4.5rem !important;
  }
  .mr-md-10, .mx-md-10 {
    margin-right: 4.5rem !important;
  }
  .mb-md-10, .my-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-10, .mx-md-10 {
    margin-left: 4.5rem !important;
  }
  .m-md-11 {
    margin: 5rem !important;
  }
  .mt-md-11, .my-md-11 {
    margin-top: 5rem !important;
  }
  .mr-md-11, .mx-md-11 {
    margin-right: 5rem !important;
  }
  .mb-md-11, .my-md-11 {
    margin-bottom: 5rem !important;
  }
  .ml-md-11, .mx-md-11 {
    margin-left: 5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5, .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5, .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5, .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5, .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6, .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6, .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6, .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6, .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7, .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7, .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7, .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7, .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8, .py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8, .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8, .py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8, .px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9, .py-md-9 {
    padding-top: 4rem !important;
  }
  .pr-md-9, .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9, .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9, .px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .pt-md-10, .py-md-10 {
    padding-top: 4.5rem !important;
  }
  .pr-md-10, .px-md-10 {
    padding-right: 4.5rem !important;
  }
  .pb-md-10, .py-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-10, .px-md-10 {
    padding-left: 4.5rem !important;
  }
  .p-md-11 {
    padding: 5rem !important;
  }
  .pt-md-11, .py-md-11 {
    padding-top: 5rem !important;
  }
  .pr-md-11, .px-md-11 {
    padding-right: 5rem !important;
  }
  .pb-md-11, .py-md-11 {
    padding-bottom: 5rem !important;
  }
  .pl-md-11, .px-md-11 {
    padding-left: 5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1, .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1, .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1, .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1, .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2, .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2, .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2, .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2, .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5, .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5, .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5, .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5, .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .mt-md-n6, .my-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n6, .mx-md-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n6, .my-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n6, .mx-md-n6 {
    margin-left: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7, .my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7, .mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7, .my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7, .mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .mt-md-n8, .my-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n8, .mx-md-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n8, .my-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n8, .mx-md-n8 {
    margin-left: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9, .my-md-n9 {
    margin-top: -4rem !important;
  }
  .mr-md-n9, .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9, .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n9, .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mt-md-n10, .my-md-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n10, .mx-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n10, .my-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n10, .mx-md-n10 {
    margin-left: -4.5rem !important;
  }
  .m-md-n11 {
    margin: -5rem !important;
  }
  .mt-md-n11, .my-md-n11 {
    margin-top: -5rem !important;
  }
  .mr-md-n11, .mx-md-n11 {
    margin-right: -5rem !important;
  }
  .mb-md-n11, .my-md-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n11, .mx-md-n11 {
    margin-left: -5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5, .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6, .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6, .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6, .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6, .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7, .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7, .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7, .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7, .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8, .my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8, .mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8, .my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8, .mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9, .my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9, .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9, .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9, .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .mt-lg-10, .my-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-10, .mx-lg-10 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-10, .my-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-10, .mx-lg-10 {
    margin-left: 4.5rem !important;
  }
  .m-lg-11 {
    margin: 5rem !important;
  }
  .mt-lg-11, .my-lg-11 {
    margin-top: 5rem !important;
  }
  .mr-lg-11, .mx-lg-11 {
    margin-right: 5rem !important;
  }
  .mb-lg-11, .my-lg-11 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-11, .mx-lg-11 {
    margin-left: 5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5, .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5, .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5, .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6, .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6, .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6, .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6, .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7, .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7, .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7, .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7, .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8, .py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8, .px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8, .py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8, .px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9, .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9, .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9, .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9, .px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .pt-lg-10, .py-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-10, .px-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-10, .py-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-10, .px-lg-10 {
    padding-left: 4.5rem !important;
  }
  .p-lg-11 {
    padding: 5rem !important;
  }
  .pt-lg-11, .py-lg-11 {
    padding-top: 5rem !important;
  }
  .pr-lg-11, .px-lg-11 {
    padding-right: 5rem !important;
  }
  .pb-lg-11, .py-lg-11 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-11, .px-lg-11 {
    padding-left: 5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1, .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2, .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5, .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .mt-lg-n6, .my-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n6, .mx-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n6, .my-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n6, .mx-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7, .my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7, .mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7, .my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7, .mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .mt-lg-n8, .my-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n8, .mx-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n8, .my-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n8, .mx-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9, .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .mr-lg-n9, .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9, .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n9, .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mt-lg-n10, .my-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n10, .mx-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n10, .my-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n10, .mx-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n11 {
    margin: -5rem !important;
  }
  .mt-lg-n11, .my-lg-n11 {
    margin-top: -5rem !important;
  }
  .mr-lg-n11, .mx-lg-n11 {
    margin-right: -5rem !important;
  }
  .mb-lg-n11, .my-lg-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n11, .mx-lg-n11 {
    margin-left: -5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5, .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6, .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6, .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6, .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6, .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7, .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7, .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7, .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7, .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8, .my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8, .mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8, .my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8, .mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9, .my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9, .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9, .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9, .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .mt-xl-10, .my-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-10, .mx-xl-10 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-10, .my-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-10, .mx-xl-10 {
    margin-left: 4.5rem !important;
  }
  .m-xl-11 {
    margin: 5rem !important;
  }
  .mt-xl-11, .my-xl-11 {
    margin-top: 5rem !important;
  }
  .mr-xl-11, .mx-xl-11 {
    margin-right: 5rem !important;
  }
  .mb-xl-11, .my-xl-11 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-11, .mx-xl-11 {
    margin-left: 5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5, .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5, .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5, .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6, .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6, .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6, .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6, .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7, .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7, .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7, .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7, .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8, .py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8, .px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8, .py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8, .px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9, .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9, .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9, .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9, .px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .pt-xl-10, .py-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-10, .px-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-10, .py-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-10, .px-xl-10 {
    padding-left: 4.5rem !important;
  }
  .p-xl-11 {
    padding: 5rem !important;
  }
  .pt-xl-11, .py-xl-11 {
    padding-top: 5rem !important;
  }
  .pr-xl-11, .px-xl-11 {
    padding-right: 5rem !important;
  }
  .pb-xl-11, .py-xl-11 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-11, .px-xl-11 {
    padding-left: 5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1, .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2, .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5, .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xl-n6, .my-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n6, .mx-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n6, .my-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n6, .mx-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7, .my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7, .mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7, .my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7, .mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xl-n8, .my-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n8, .mx-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n8, .my-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n8, .mx-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9, .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xl-n9, .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9, .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n9, .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mt-xl-n10, .my-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n10, .mx-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n10, .my-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n10, .mx-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n11 {
    margin: -5rem !important;
  }
  .mt-xl-n11, .my-xl-n11 {
    margin-top: -5rem !important;
  }
  .mr-xl-n11, .mx-xl-n11 {
    margin-right: -5rem !important;
  }
  .mb-xl-n11, .my-xl-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n11, .mx-xl-n11 {
    margin-left: -5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: var(--white) !important;
}

.text-primary {
  color: var(--primary) !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545d66 !important;
}

.text-success {
  color: var(--success) !important;
}

a.text-success:hover, a.text-success:focus {
  color: #007d67 !important;
}

.text-info {
  color: var(--info) !important;
}

a.text-info:hover, a.text-info:focus {
  color: #009bb0 !important;
}

.text-warning {
  color: var(--warning) !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: var(--danger) !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ac281c !important;
}

.text-light {
  color: var(--light) !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: var(--dark) !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-white {
  color: var(--white) !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-indigo {
  color: var(--indigo) !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #160a40 !important;
}

.text-body {
  color: var(--dark) !important;
}

.text-muted {
  color: var(--gray) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, *::before, *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre, blockquote {
    border: 1px solid #97a4af;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td, .table th {
    background-color: var(--white) !important;
  }
  .table-bordered th, .table-bordered td {
    border: 1px solid #e7eaf3 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody+tbody {
    border-color: #e7eaf3;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e7eaf3;
  }
}

/*----------------------------------------------------------------------
  * Front - Responsive Website Template version v2.9.0
  * Copyright 2018 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
}

.space-0, .space-top-0 {
  padding-top: 0 !important;
}

.space-0, .space-bottom-0 {
  padding-bottom: 0 !important;
}

.space-1, .space-top-1 {
  padding-top: 2rem !important;
}

.space-1, .space-bottom-1 {
  padding-bottom: 2rem !important;
}

.space-2, .space-top-2 {
  padding-top: 4rem !important;
}

.space-2, .space-bottom-2 {
  padding-bottom: 4rem !important;
}

.space-3, .space-top-3 {
  padding-top: 8rem !important;
}

.space-3, .space-bottom-3 {
  padding-bottom: 8rem !important;
}

.space-4, .space-top-4 {
  padding-top: 12.5rem !important;
}

.space-4, .space-bottom-4 {
  padding-bottom: 12.5rem !important;
}

.space-5, .space-top-5 {
  padding-top: 15rem !important;
}

.space-5, .space-bottom-5 {
  padding-bottom: 15rem !important;
}

@media (min-width: 576px) {
  .space-sm-0, .space-top-sm-0 {
    padding-top: 0 !important;
  }
  .space-sm-0, .space-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
  .space-sm-1, .space-top-sm-1 {
    padding-top: 2rem !important;
  }
  .space-sm-1, .space-bottom-sm-1 {
    padding-bottom: 2rem !important;
  }
  .space-sm-2, .space-top-sm-2 {
    padding-top: 4rem !important;
  }
  .space-sm-2, .space-bottom-sm-2 {
    padding-bottom: 4rem !important;
  }
  .space-sm-3, .space-top-sm-3 {
    padding-top: 8rem !important;
  }
  .space-sm-3, .space-bottom-sm-3 {
    padding-bottom: 8rem !important;
  }
  .space-sm-4, .space-top-sm-4 {
    padding-top: 12.5rem !important;
  }
  .space-sm-4, .space-bottom-sm-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-sm-5, .space-top-sm-5 {
    padding-top: 15rem !important;
  }
  .space-sm-5, .space-bottom-sm-5 {
    padding-bottom: 15rem !important;
  }
}

@media (min-width: 768px) {
  .space-md-0, .space-top-md-0 {
    padding-top: 0 !important;
  }
  .space-md-0, .space-bottom-md-0 {
    padding-bottom: 0 !important;
  }
  .space-md-1, .space-top-md-1 {
    padding-top: 2rem !important;
  }
  .space-md-1, .space-bottom-md-1 {
    padding-bottom: 2rem !important;
  }
  .space-md-2, .space-top-md-2 {
    padding-top: 4rem !important;
  }
  .space-md-2, .space-bottom-md-2 {
    padding-bottom: 4rem !important;
  }
  .space-md-3, .space-top-md-3 {
    padding-top: 8rem !important;
  }
  .space-md-3, .space-bottom-md-3 {
    padding-bottom: 8rem !important;
  }
  .space-md-4, .space-top-md-4 {
    padding-top: 12.5rem !important;
  }
  .space-md-4, .space-bottom-md-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-md-5, .space-top-md-5 {
    padding-top: 15rem !important;
  }
  .space-md-5, .space-bottom-md-5 {
    padding-bottom: 15rem !important;
  }
}

@media (min-width: 992px) {
  .space-lg-0, .space-top-lg-0 {
    padding-top: 0 !important;
  }
  .space-lg-0, .space-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
  .space-lg-1, .space-top-lg-1 {
    padding-top: 2rem !important;
  }
  .space-lg-1, .space-bottom-lg-1 {
    padding-bottom: 2rem !important;
  }
  .space-lg-2, .space-top-lg-2 {
    padding-top: 4rem !important;
  }
  .space-lg-2, .space-bottom-lg-2 {
    padding-bottom: 4rem !important;
  }
  .space-lg-3, .space-top-lg-3 {
    padding-top: 8rem !important;
  }
  .space-lg-3, .space-bottom-lg-3 {
    padding-bottom: 8rem !important;
  }
  .space-lg-4, .space-top-lg-4 {
    padding-top: 12.5rem !important;
  }
  .space-lg-4, .space-bottom-lg-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-lg-5, .space-top-lg-5 {
    padding-top: 15rem !important;
  }
  .space-lg-5, .space-bottom-lg-5 {
    padding-bottom: 15rem !important;
  }
}

@media (min-width: 1200px) {
  .space-xl-0, .space-top-xl-0 {
    padding-top: 0 !important;
  }
  .space-xl-0, .space-bottom-xl-0 {
    padding-bottom: 0 !important;
  }
  .space-xl-1, .space-top-xl-1 {
    padding-top: 2rem !important;
  }
  .space-xl-1, .space-bottom-xl-1 {
    padding-bottom: 2rem !important;
  }
  .space-xl-2, .space-top-xl-2 {
    padding-top: 4rem !important;
  }
  .space-xl-2, .space-bottom-xl-2 {
    padding-bottom: 4rem !important;
  }
  .space-xl-3, .space-top-xl-3 {
    padding-top: 8rem !important;
  }
  .space-xl-3, .space-bottom-xl-3 {
    padding-bottom: 8rem !important;
  }
  .space-xl-4, .space-top-xl-4 {
    padding-top: 12.5rem !important;
  }
  .space-xl-4, .space-bottom-xl-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-xl-5, .space-top-xl-5 {
    padding-top: 15rem !important;
  }
  .space-xl-5, .space-bottom-xl-5 {
    padding-bottom: 15rem !important;
  }
}

/*------------------------------------
  Default Styles
------------------------------------*/

main {
  position: relative;
}

p {
  color: var(--secondary);
  line-height: 1.7;
}

h4, h5, h6, .h4, .h5, .h6 {
  font-weight: 500;
}

h1>a, h2>a, h3>a, h4>a, h5>a, h6>a, .h1>a, .h2>a, .h3>a, .h4>a, .h5>a, .h6>a {
  color: var(--dark);
}

h1>a:hover, h2>a:hover, h3>a:hover, h4>a:hover, h5>a:hover, h6>a:hover, .h1>a:hover, .h2>a:hover, .h3>a:hover, .h4>a:hover, .h5>a:hover, .h6>a:hover {
  color: var(--primary);
}

h1.text-white>a, h2.text-white>a, h3.text-white>a, h4.text-white>a, h5.text-white>a, h6.text-white>a, .h1.text-white>a, .h2.text-white>a, .h3.text-white>a, .h4.text-white>a, .h5.text-white>a, .h6.text-white>a {
  color: var(--white);
}

figure {
  margin-bottom: 0;
}

strong {
  font-weight: 600;
}

svg {
  vertical-align: baseline;
}

table th {
  font-weight: 600;
}

.fa, .fas {
  font-weight: 900;
}

/*------------------------------------
  Highlight Color
------------------------------------*/

::-moz-selection {
  color: var(--white);
  background-color: var(--primary);
}

::selection {
  color: var(--white);
  background-color: var(--primary);
}

.bg-primary ::-moz-selection {
  color: var(--primary);
  background-color: var(--white);
}

.bg-primary ::selection {
  color: var(--primary);
  background-color: var(--white);
}

/*------------------------------------
  Accessibility
------------------------------------*/

:focus, a:focus, button:focus {
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 0 0 transparent;
}

.btn:focus, .btn.focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  box-shadow: 0 0 0 0 transparent;
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .show>.btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-select:focus, .page-link:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-control-input:checked~.custom-control-label::before, .custom-control-input:focus~.custom-control-label::before, .custom-control-input:active~.custom-control-label::before {
  box-shadow: 0 0 0 0 transparent;
  /* display: none; */
}

.custom-file-input:focus~.custom-file-label {
  box-shadow: 0 0 0 0 transparent;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 !important;
}

/*------------------------------------
  Print styles
------------------------------------*/

@media print {
  header, footer, .btn {
    display: none;
  }
}

/*------------------------------------
  Skippy
------------------------------------*/

.u-skippy {
  display: none;
}

/*------------------------------------
  List Group
------------------------------------*/

.list-group {
  list-style: none;
}

.list-group .list-group {
  margin-left: 1rem;
}

.list-group .list-group-item {
  font-size: 0.875rem;
}

.list-group .list-group-icon {
  min-width: 1rem;
  max-width: 1rem;
  color: var(--secondary);
  text-align: center;
  margin-right: .5rem;
}

.list-group .active>.list-group-item {
  color: var(--primary);
}

.list-group .active>.list-group-text {
  color: var(--white);
}

.list-group .disabled .u-icon {
  color: var(--secondary);
  background-color: rgba(119, 131, 143, 0.1);
}

/* Border Radius */

.list-group-rounded-0 .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-rounded-0 .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* Transparent */

.list-group-transparent .list-group-item {
  border-color: transparent;
  background-color: transparent;
}

/* White */

.list-group-white .list-group-item, .list-group-white .list-group-item-action {
  color: var(--white);
}

.list-group-white .list-group-item[href], .list-group-white .list-group-item-action[href] {
  color: rgba(255, 255, 255, 0.7);
}

.list-group-white .list-group-item[href]:hover, .list-group-white .list-group-item-action[href]:hover {
  color: var(--white);
}

.list-group-white.list-group-striped .list-group-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}

.list-group-white .active>.list-group-item {
  color: var(--white);
}

/* Flush */

.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.list-group-flush .list-group-item.active {
  color: var(--primary);
  background-color: transparent;
  border-color: transparent;
}

/* Borderless */

.list-group-borderless .list-group-item {
  border: none;
}

/* Striped */

.list-group-striped .list-group-item:nth-child(even) {
  background-color: #f8fafd;
}

/*------------------------------------
  Links
------------------------------------*/

.link {
  display: inline-block;
}

.link__icon {
  position: relative;
  line-height: .7;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 1.375rem;
  height: 1.375rem;
  font-size: 1rem;
  color: var(--primary);
  background-color: rgba(55, 125, 255, 0.1);
  border-radius: 50%;
  transition: 0.3s;
}

.link__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.link:hover .link__icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* Collapse */

.link-collapse[aria-expanded="false"] .link-collapse__default {
  display: inline-block;
}

.link-collapse[aria-expanded="false"] .link-collapse__active {
  display: none;
}

.link-collapse[aria-expanded="true"] .link-collapse__default {
  display: none;
}

.link-collapse[aria-expanded="true"] .link-collapse__active {
  display: inline-block;
}

/* Link Light */

.link-light {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.7);
}

.link-light:hover {
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.7);
}

/* Link Muted */

.link-muted {
  color: var(--gray);
  border-bottom: 1px dashed #97a4af;
}

.link-muted:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.link-muted__toggle-default {
  display: inline-block;
}

.link-muted__toggle-toggled {
  display: none;
}

.link-muted.toggled .link-muted__toggle-default {
  display: none;
}

.link-muted.toggled .link-muted__toggle-toggled {
  display: inline-block;
}

/* Link White */

.link-white {
  color: var(--white);
  border-bottom: 1px dashed var(--white);
}

.link-white:hover {
  color: var(--white);
}

/*------------------------------------
  Header Default
------------------------------------*/

.u-header {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.u-header__section {
  position: relative;
  z-index: 1;
  background-color: var(--white);
}

.u-header__section-divider {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.u-header__shadow-on-show-hide {
  box-shadow: none;
}

.js-header-fix-moment .u-header__shadow-on-show-hide {
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
}

/* Navbar */

.u-header__navbar {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0;
  padding-right: 0;
}

/* Navbar Space */

@media (max-width: 991.98px) {
  .u-header .u-header__navbar.u-header__navbar--top-space {
    padding-top: 0.25rem;
  }
}

/* Navbar Brand */

.u-header__navbar-brand, .u-header__navbar-brand>img {
  padding-top: 0;
  padding-bottom: 0;
  width: 9.375rem;
}

.u-header__navbar-brand-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.u-header__navbar-brand-vertical {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

.u-header__navbar-brand-vertical .u-header__navbar-brand-text {
  display: block;
  margin-left: 0;
}

.u-header__navbar-brand-text {
  font-size: 2.25rem;
  font-weight: 700;
  margin-left: .5rem;
}

.u-header__navbar-brand-text, .u-header__navbar-brand-text:focus, .u-header__navbar-brand-text:hover {
  color: var(--primary);
}

.u-header__navbar-brand-text-white .u-header__navbar-brand-text, .u-header__navbar-brand-text-white .u-header__navbar-brand-text:focus, .u-header__navbar-brand-text-white .u-header__navbar-brand-text:hover {
  color: var(--white);
}

/* Navbar Collapse */

.u-header__navbar-collapse {
  padding-top: .5rem;
  padding-bottom: 0;
}

/* Navbar Link */

.u-header__navbar-link {
  color: var(--secondary);
  font-size: 0.875rem;
  font-weight: 300;
  padding-left: .5rem;
  padding-right: .5rem;
}

/* Nav Item */

.u-header .u-header__nav-item:first-child .u-header__nav-link {
  padding-left: 0;
}

.u-header__nav-item:hover .u-header__nav-link, .u-header__nav-item:focus .u-header__nav-link {
  color: var(--primary);
}

/* Nav Link */

.u-header .active>.u-header__nav-link {
  color: var(--primary);
}

.u-header__nav-link {
  color: var(--secondary);
}

.u-header__nav-link-toggle {
  position: relative;
}

.u-header__nav-link-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 65%;
  font-weight: 900;
  content: "\f107";
  margin-left: .5rem;
  transition: 0.3s ease;
}

/* Sub Menu */

.u-header__sub-menu {
  width: 100%;
  padding-left: 0;
  list-style: none;
}

.u-header__sub-menu-nav-group {
  padding-left: 0;
  list-style: none;
}

.u-header__sub-menu-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  color: var(--secondary);
}

.u-header__sub-menu-nav-link-toggle {
  position: relative;
}

.u-header__sub-menu-nav-link-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  content: "\f105";
  margin-left: auto;
  margin-right: .04rem;
  transition: 0.3s ease;
}

.u-header__sub-menu .active>.u-header__sub-menu-nav-link {
  color: var(--primary);
}

.u-header__sub-menu-title {
  display: block;
  color: var(--dark);
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Promo */

.u-header__promo {
  background-color: #f8fafd;
}

.u-header__promo-inner {
  padding: 2rem;
}

.u-header__promo-item {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.u-header__promo-item~.u-header__promo-item {
  border-top: 1px solid #e7eaf3;
}

.u-header__promo-link {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.u-header__promo-link:hover .u-header__promo-title {
  color: var(--primary);
}

.u-header__promo-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.u-header__promo-card-deck {
  display: block;
}

.u-header__promo-card-deck:not(:last-child) .u-header__promo-item {
  border-bottom: 1px solid #e7eaf3;
}

.u-header__promo-card-deck .u-header__promo-item {
  border-top: none;
}

.u-header__promo-card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.u-header__promo-icon {
  width: 100%;
  max-width: 2.75rem;
  margin-right: 1rem;
}

.u-header__promo-icon--sm {
  max-width: 1.75rem;
}

.u-header__promo-title {
  display: block;
  color: var(--dark);
  font-size: 1rem;
  font-weight: 500;
}

.u-header__promo-text {
  display: block;
  color: var(--secondary);
}

.u-header__promo-footer {
  background-color: #f8fafd;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.u-header__promo-footer-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.u-header__promo-footer-ver-divider {
  position: relative;
}

.u-header__promo-footer-ver-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 1px solid #bdc5d1;
  content: "";
}

/* Banner */

.u-header__banner {
  position: relative;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  border-bottom-left-radius: 0.3125rem;
}

.u-header__banner::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(30, 32, 34, 0.5);
  border-bottom-left-radius: 0.3125rem;
  content: "";
}

.u-header__banner-content {
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 2rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.u-header__banner-title {
  display: block;
  color: var(--white);
  font-size: 1.5625rem;
  font-weight: 500;
  margin-bottom: .5rem;
}

.u-header__banner-text {
  display: block;
  color: var(--white);
  margin-bottom: 0;
}

/* Product Banner */

.u-header__product-banner {
  background-color: #f8fafd;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  padding-right: 1.5rem;
}

.u-header__product-banner-content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.u-header__product-banner-title {
  display: block;
  color: var(--primary);
  font-size: 1.5625rem;
  font-weight: 500;
  margin-bottom: .5rem;
}

.u-header__product-banner-text {
  color: var(--dark);
  margin-bottom: 0;
}

/* Hide Content */

.u-header.js-header-fix-moment .u-header__hide-content {
  display: none;
}

.u-header.u-header--moved-up.u-header--hide-topbar {
  -webkit-transform: translate3d(0, -33px, 0);
  transform: translate3d(0, -33px, 0);
}

/*------------------------------------
  Header Helpers
------------------------------------*/

.u-header--invisible {
  display: none;
}

.u-header--moved-up {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.u-header--faded {
  opacity: 0;
  visibility: hidden;
}

.u-header__section--hidden {
  position: relative;
}

.u-header[data-header-fix-effect] {
  transition: 0.3s ease;
}

.u-header.u-header--untransitioned {
  transition: none;
}

.u-header.js-header-fix-moment {
  position: fixed;
  top: 0;
  bottom: auto;
}

.u-header--fix-top {
  position: fixed;
}

.u-header.u-header--fix-top[data-effect-compensation] {
  transition: none;
}

/*------------------------------------
  Nav Alignments
------------------------------------*/

@media (min-width: 992px) {
  .u-header__navbar-body, .u-header__navbar-nav {
    margin-left: auto;
  }
  .u-header-left-aligned-nav .u-header__navbar-body, .u-header-left-aligned-nav .u-header__navbar-nav {
    margin-left: 0;
    margin-right: auto;
  }
  .u-header-left-aligned-nav .u-header__navbar-nav {
    width: 100%;
  }
  .u-header-left-aligned-nav .u-header__navbar-brand {
    margin-left: 0;
    margin-right: 3rem;
  }
  .u-header-left-aligned-nav .u-header__nav-last-item {
    margin-left: auto;
  }
  .u-header-center-aligned-nav__col {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .u-header-center-aligned-nav__col-last-item {
    text-align: right;
  }
  .u-header-center-aligned-nav .u-header__navbar-body, .u-header-center-aligned-nav .u-header__navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1199.98px) {
  .u-header-center-aligned-nav__col:not(.u-header-center-aligned-nav__col-last-item) {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .u-header-center-aligned-nav__col-last-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .u-header-center-aligned-nav.u-header--abs-top-sm .u-header__navbar-body, .u-header-center-aligned-nav.u-header--abs-top-sm .u-header__navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
}

/*------------------------------------
  Header Default
------------------------------------*/

.u-header__navbar-nav-scroll {
  max-width: 100%;
  height: 4.25rem;
  overflow: hidden;
}

.u-header__navbar-nav-scroll .u-header__navbar-nav {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/*------------------------------------
  Fullscreen
------------------------------------*/

.u-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
}

.u-fullscreen--toggler {
  z-index: 1001;
}

.u-fullscreen__container, .u-fullscreen__overlay {
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.u-fullscreen__container {
  position: relative;
  z-index: 1000;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.u-fullscreen__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  background-image: linear-gradient(150deg, rgba(45, 21, 130, 0.95) 0%, rgba(25, 160, 255, 0.95) 100%);
  background-repeat: repeat-x;
  transition-delay: 0.5s;
}

.u-fullscreen.u-fullscreen--showed {
  visibility: visible;
}

.u-fullscreen.u-fullscreen--showed .u-fullscreen__container, .u-fullscreen.u-fullscreen--showed .u-fullscreen__overlay {
  opacity: 1;
  visibility: visible;
}

.u-fullscreen.u-fullscreen--showed .u-fullscreen__overlay {
  transition-delay: 0s;
}

.u-fullscreen.u-fullscreen--showed .u-fullscreen__container {
  transition-delay: 0.5s;
}

/*------------------------------------
  Header Fullscreen Style
------------------------------------*/

.u-fullscreen__nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.u-fullscreen__nav-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.u-fullscreen__nav-link {
  display: inline-block;
  color: var(--white);
  font-size: 1.375rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media (min-width: 992px) {
  .u-fullscreen__nav-link {
    font-size: 1.75rem;
  }
}

.u-fullscreen__nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.u-fullscreen__submenu {
  position: relative;
  transition: 0.3s ease-in-out;
}

.u-fullscreen__submenu .u-fullscreen__nav-list {
  border-left: 2px solid rgba(255, 255, 255, 0.6);
  padding-left: 1rem;
}

.u-fullscreen__submenu-nav-link {
  display: inline-block;
  color: var(--white);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.u-fullscreen__submenu-nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.u-fullscreen__submenu-list {
  list-style: none;
  border-left: 2px solid rgba(255, 255, 255, 0.6);
  padding-left: 1rem;
  margin-bottom: 0;
}

.u-fullscreen__submenu-list-link {
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
}

.u-fullscreen__submenu-list-link:hover {
  color: var(--white);
}

/*------------------------------------
  Header Navbar Background Colors
------------------------------------*/

.u-header--navbar-bg .u-header__navbar-brand-text {
  color: var(--white);
}

.u-header--navbar-bg .u-header__nav-item:hover .u-header__nav-link {
  color: var(--white);
}

.u-header--navbar-bg .active>.u-header__nav-link {
  color: var(--white);
}

.u-header--navbar-bg .u-header__nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-header__nav-link:hover {
  color: var(--white);
}

.u-header--navbar-bg .u-header__navbar-link, .u-header--navbar-bg .dropdown-nav-link, .u-header--navbar-bg .btn-text-secondary {
  color: rgba(255, 255, 255, 0.6);
}

.u-header--navbar-bg .u-header__navbar-link:hover, .u-header--navbar-bg .dropdown-nav-link:hover, .u-header--navbar-bg .btn-text-secondary:hover {
  color: var(--white);
}

.u-header--navbar-bg .btn-text-secondary[href]:hover {
  color: var(--white);
}

.u-header--navbar-bg .u-hamburger__inner, .u-header--navbar-bg .u-hamburger__inner::before, .u-header--navbar-bg .u-hamburger__inner::after {
  background-color: rgba(255, 255, 255, 0.5);
}

.u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner, .u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::before, .u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::after {
  background-color: var(--white);
}

/*------------------------------------
  Header Submenu Background Colors
------------------------------------*/

.u-header--sub-menu-dark-bg .u-header__sub-menu, .u-header--sub-menu-dark-bg .u-header__promo {
  background-color: var(--dark);
}

.u-header--sub-menu-dark-bg .u-header__sub-menu-title, .u-header--sub-menu-dark-bg .u-header__promo-title {
  color: rgba(255, 255, 255, 0.8);
}

.u-header--sub-menu-dark-bg .u-header__promo-footer {
  background-color: #1c1d1f;
}

.u-header--sub-menu-dark-bg .u-header__promo-footer-ver-divider::before {
  border-color: rgba(255, 255, 255, 0.1);
}

.u-header--sub-menu-dark-bg .u-header__promo-link::after {
  border-color: rgba(255, 255, 255, 0.1);
}

.u-header--sub-menu-dark-bg .u-header__promo-item {
  border-color: rgba(255, 255, 255, 0.1);
}

.u-header--sub-menu-dark-bg .u-header__sub-menu-nav-link {
  color: var(--gray);
}

.u-header--sub-menu-dark-bg .u-header__sub-menu-nav-link:hover {
  color: var(--primary);
}

.u-header--sub-menu-dark-bg .u-header__product-banner {
  background-color: #1c1d1f;
}

.u-header--sub-menu-dark-bg .u-header__product-banner-text {
  color: var(--gray);
}

.u-header--sub-menu-dark-bg .dropdown-nav-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.u-header--sub-menu-dark-bg .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 576px) {
  .u-header--sub-menu-dark-bg-sm .u-header__sub-menu, .u-header--sub-menu-dark-bg-sm .u-header__promo {
    background-color: var(--dark);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__sub-menu-title, .u-header--sub-menu-dark-bg-sm .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__promo-footer {
    background-color: #1c1d1f;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__sub-menu-nav-link {
    color: var(--gray);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__sub-menu-nav-link:hover {
    color: var(--primary);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__product-banner {
    background-color: #1c1d1f;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__product-banner-text {
    color: var(--gray);
  }
  .u-header--sub-menu-dark-bg-sm .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-sm .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

@media (min-width: 768px) {
  .u-header--sub-menu-dark-bg-md .u-header__sub-menu, .u-header--sub-menu-dark-bg-md .u-header__promo {
    background-color: var(--dark);
  }
  .u-header--sub-menu-dark-bg-md .u-header__sub-menu-title, .u-header--sub-menu-dark-bg-md .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-md .u-header__promo-footer {
    background-color: #1c1d1f;
  }
  .u-header--sub-menu-dark-bg-md .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-md .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-md .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-md .u-header__sub-menu-nav-link {
    color: var(--gray);
  }
  .u-header--sub-menu-dark-bg-md .u-header__sub-menu-nav-link:hover {
    color: var(--primary);
  }
  .u-header--sub-menu-dark-bg-md .u-header__product-banner {
    background-color: #1c1d1f;
  }
  .u-header--sub-menu-dark-bg-md .u-header__product-banner-text {
    color: var(--gray);
  }
  .u-header--sub-menu-dark-bg-md .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-md .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

@media (min-width: 992px) {
  .u-header--sub-menu-dark-bg-lg .u-header__sub-menu, .u-header--sub-menu-dark-bg-lg .u-header__promo {
    background-color: var(--dark);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__sub-menu-title, .u-header--sub-menu-dark-bg-lg .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__promo-footer {
    background-color: #1c1d1f;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__sub-menu-nav-link {
    color: var(--gray);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__sub-menu-nav-link:hover {
    color: var(--primary);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__product-banner {
    background-color: #1c1d1f;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__product-banner-text {
    color: var(--gray);
  }
  .u-header--sub-menu-dark-bg-lg .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-lg .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

@media (min-width: 1200px) {
  .u-header--sub-menu-dark-bg-xl .u-header__sub-menu, .u-header--sub-menu-dark-bg-xl .u-header__promo {
    background-color: var(--dark);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__sub-menu-title, .u-header--sub-menu-dark-bg-xl .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__promo-footer {
    background-color: #1c1d1f;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__sub-menu-nav-link {
    color: var(--gray);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__sub-menu-nav-link:hover {
    color: var(--primary);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__product-banner {
    background-color: #1c1d1f;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__product-banner-text {
    color: var(--gray);
  }
  .u-header--sub-menu-dark-bg-xl .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-xl .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

/*------------------------------------
  Transparent Header
------------------------------------*/

.u-header--bg-transparent:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"]+.u-header__section {
  background-color: var(--white);
}

.u-header--bg-transparent:not(.js-header-fix-moment) .u-header__section {
  background-color: transparent;
  box-shadow: none;
}

.u-header--bg-transparent .u-header__navbar-brand-collapsed, .u-header--bg-transparent .u-header__navbar-brand-collapsed .u-header__navbar-brand-text, .u-header--bg-transparent .u-header__navbar-brand-on-scroll, .u-header--bg-transparent .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
  display: none;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-default, .u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
  display: none;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-on-scroll {
  display: -ms-flexbox;
  display: flex;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
  display: inline-block;
}

@media (min-width: 576px) and (min-width: 576px) {
  .u-header--bg-transparent-sm:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"]+.u-header__section {
    background-color: var(--white);
  }
  .u-header--bg-transparent-sm:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 576px) {
  .u-header--bg-transparent-sm .u-header__navbar-brand-collapsed, .u-header--bg-transparent-sm .u-header__navbar-brand-collapsed .u-header__navbar-brand-text, .u-header--bg-transparent-sm .u-header__navbar-brand-on-scroll, .u-header--bg-transparent-sm .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-sm.js-header-fix-moment .u-header__navbar-brand-default, .u-header--bg-transparent-sm.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-sm.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-sm.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .u-header--bg-transparent-md:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"]+.u-header__section {
    background-color: var(--white);
  }
  .u-header--bg-transparent-md:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .u-header--bg-transparent-md .u-header__navbar-brand-collapsed, .u-header--bg-transparent-md .u-header__navbar-brand-collapsed .u-header__navbar-brand-text, .u-header--bg-transparent-md .u-header__navbar-brand-on-scroll, .u-header--bg-transparent-md .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-md.js-header-fix-moment .u-header__navbar-brand-default, .u-header--bg-transparent-md.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-md.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-md.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .u-header--bg-transparent-lg:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"]+.u-header__section {
    background-color: var(--white);
  }
  .u-header--bg-transparent-lg:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .u-header--bg-transparent-lg .u-header__navbar-brand-collapsed, .u-header--bg-transparent-lg .u-header__navbar-brand-collapsed .u-header__navbar-brand-text, .u-header--bg-transparent-lg .u-header__navbar-brand-on-scroll, .u-header--bg-transparent-lg .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-lg.js-header-fix-moment .u-header__navbar-brand-default, .u-header--bg-transparent-lg.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-lg.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-lg.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .u-header--bg-transparent-xl:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"]+.u-header__section {
    background-color: var(--white);
  }
  .u-header--bg-transparent-xl:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 1200px) {
  .u-header--bg-transparent-xl .u-header__navbar-brand-collapsed, .u-header--bg-transparent-xl .u-header__navbar-brand-collapsed .u-header__navbar-brand-text, .u-header--bg-transparent-xl .u-header__navbar-brand-on-scroll, .u-header--bg-transparent-xl .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-xl.js-header-fix-moment .u-header__navbar-brand-default, .u-header--bg-transparent-xl.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-xl.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-xl.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  .u-header--bg-transparent-sm .u-header__navbar-brand-default, .u-header--bg-transparent-sm .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-header--bg-transparent-md .u-header__navbar-brand-default, .u-header--bg-transparent-md .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-header--bg-transparent-lg .u-header__navbar-brand-default, .u-header--bg-transparent-lg .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .u-header--bg-transparent-xl .u-header__navbar-brand-default, .u-header--bg-transparent-xl .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

.u-header--bg-transparent .u-header__navbar-brand-default, .u-header--bg-transparent .u-header__navbar-brand-on-scroll {
  display: none;
}

.u-header--bg-transparent .u-header__navbar-brand-default {
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767.98px) {
  .u-header--bg-transparent .u-header__navbar-nav {
    background-color: var(--white);
  }
  .u-header--bg-transparent .u-header__navbar-nav {
    padding: 0.875rem 1.5rem;
    padding-top: 1rem;
  }
}

/*------------------------------------
  Header On Scroll
------------------------------------*/

.u-header--white-bg-on-scroll.u-scrolled .u-header__section {
  background-color: var(--white);
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
}

/*------------------------------------
  Header White Nav Links
------------------------------------*/

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
  color: white;
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
  color:var(--white);
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link, .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link, .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
  color: rgba(255, 255, 255, 0.6);
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover, .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover, .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
  color:var(--white);
}

@media (min-width: 576px) {
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: var(--white);
  }
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: var(--white);
  }
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link, .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link, .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: rgba(255, 255, 255, 0.6);
  }
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover, .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover, .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: var(--white);
  }
}

@media (min-width: 768px) {
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: var(--white);
  }
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: rgba(255, 255, 255, 1);
  }
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: var(--white);
  }
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link, .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link, .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: rgba(255, 255, 255, 1);
  }
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover, .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover, .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: var(--white);
  }
}

@media (min-width: 992px) {
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: var(--white);
  }
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: var(--white);
  }
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link, .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link, .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: rgba(255, 255, 255, 0.6);
  }
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover, .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover, .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: var(--white);
  }
}

@media (min-width: 1200px) {
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: var(--white);
  }
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: var(--white);
  }
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link, .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link, .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: rgba(255, 255, 255, 0.6);
  }
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover, .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover, .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: var(--white);
  }
}

/*------------------------------------
  Navbar Expand
------------------------------------*/

.navbar-expand {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.navbar-expand.u-header__navbar--no-space {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__navbar-nav {
  -ms-flex-align: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__nav-last-item {
  text-align: right;
  padding-left: 1rem;
  padding-right: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__nav-link {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 0.875rem;
  padding-left: 0.875rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu {
  border-top: 0.1875rem solid var(--primary);
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  box-shadow: 0 8px 20px rgba(55, 125, 255, 0.075);
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-link {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
  padding-left: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
  padding: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
  margin-top: -1.1875rem;
}

.navbar-expand .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
  right: 20%;
}

.navbar-expand .u-header__mega-menu-wrapper {
  padding: 2.5rem;
  margin-left: 0;
  margin-right: 0;
}

.navbar-expand .u-header__promo {
  border-bottom-right-radius: 0.3125rem;
}

.navbar-expand .u-header__promo-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-expand .u-header__promo-card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.navbar-expand .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
  position: relative;
}

.navbar-expand .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  border-right: 1px solid #e7eaf3;
  height: 100%;
  content: "";
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand-sm.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav {
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid var(--primary);
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(55, 125, 255, 0.075);
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-sm .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-sm .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-sm .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-sm .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-sm .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-sm .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand-md.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__navbar-nav {
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__nav-link {
    padding: 1.5rem .875rem!important;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid var(--primary);
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(55, 125, 255, 0.075);
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-md .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-md .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-md .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-md .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-md .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-md .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-md .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand-lg.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav {
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid var(--primary);
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(55, 125, 255, 0.075);
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-lg .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-lg .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-lg .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-lg .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-lg .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-lg .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand-xl.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav {
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid var(--primary);
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(55, 125, 255, 0.075);
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-xl .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-xl .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-xl .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xl .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-xl .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-xl .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-sm .u-header__nav-link, .navbar-expand-sm .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-sm .u-header__nav-link-toggle::after, .navbar-expand-sm .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-sm .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-sm .hs-mega-menu-opened .u-header__nav-link-toggle::after, .navbar-expand-sm .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-sm .hs-sub-menu-opened>.u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-sm .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-sm .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-sm .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid var(--primary);
  }
  .navbar-expand-sm .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-sm .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
    animation: none;
  }
  .navbar-expand-sm .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-sm .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-md .u-header__nav-link, .navbar-expand-md .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-md .u-header__nav-link-toggle::after, .navbar-expand-md .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-md .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-md .hs-mega-menu-opened .u-header__nav-link-toggle::after, .navbar-expand-md .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-md .hs-sub-menu-opened>.u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-md .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-md .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-md .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid var(--primary);
  }
  .navbar-expand-md .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-md .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
    animation: none;
  }
  .navbar-expand-md .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-md .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-lg .u-header__nav-link, .navbar-expand-lg .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-lg .u-header__nav-link-toggle::after, .navbar-expand-lg .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-lg .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-lg .hs-mega-menu-opened .u-header__nav-link-toggle::after, .navbar-expand-lg .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-lg .hs-sub-menu-opened>.u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-lg .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-lg .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid var(--primary);
  }
  .navbar-expand-lg .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-lg .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
    animation: none;
  }
  .navbar-expand-lg .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-lg .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-xl .u-header__nav-link, .navbar-expand-xl .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-xl .u-header__nav-link-toggle::after, .navbar-expand-xl .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-xl .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xl .hs-mega-menu-opened .u-header__nav-link-toggle::after, .navbar-expand-xl .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-xl .hs-sub-menu-opened>.u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xl .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-xl .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-xl .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid var(--primary);
  }
  .navbar-expand-xl .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-xl .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
    animation: none;
  }
  .navbar-expand-xl .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-xl .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

.navbar-expand .u-header__nav-last-item {
  display: none;
}

.navbar-expand .u-header__nav-link, .navbar-expand .u-header__sub-menu-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-expand .u-header__nav-link-toggle::after, .navbar-expand .u-header__sub-menu-nav-link-toggle::after {
  margin-left: auto;
}

.navbar-expand .u-header__sub-menu-nav-link-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navbar-expand .hs-mega-menu-opened .u-header__nav-link-toggle::after, .navbar-expand .hs-sub-menu-opened .u-header__nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-expand .hs-sub-menu-opened>.u-header__sub-menu-nav-link-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.navbar-expand .u-header__mega-menu-wrapper {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-expand .u-header__sub-menu {
  min-width: 100% !important;
}

.navbar-expand .u-header__sub-menu--spacer {
  padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu:not(.u-header__promo) {
  border-left: 0.1875rem solid var(--primary);
}

.navbar-expand .u-header__sub-menu .u-header__sub-menu {
  padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu-title {
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.navbar-expand .u-header__sub-menu-nav-group {
  padding-top: .5rem;
  padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu {
  padding-left: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
  -webkit-animation: none;
  animation: none;
}

.navbar-expand .u-header-center-aligned-nav__col-last-item {
  display: none;
}

.navbar-expand .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
  border-bottom: 1px solid #e7eaf3;
}

@media (max-width: 767.98px) {
  .navbar-expand-sm .u-header__nav-last-item {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-xs .u-header__banner, .navbar-expand-xs .u-header__product-banner, .navbar-expand-sm .u-header__banner, .navbar-expand-sm .u-header__product-banner, .navbar-expand-md .u-header__banner, .navbar-expand-md .u-header__product-banner, .navbar-expand-lg .u-header__banner, .navbar-expand-lg .u-header__product-banner {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .u-header__banner, .navbar-expand-xl .u-header__product-banner {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-expand-md .u-header__navbar-brand {
    padding-top: .5rem;
  }
  .u-header .u-header__mega-menu-position-right-fix--md.hs-mega-menu.hs-position-right {
    right: 20%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .navbar-expand-sm .u-header__navbar-brand {
    padding-top: .5rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand .u-header__nav-last-item {
    display: inline-block;
  }
  [class*="navbar-expand"]:not(.navbar-expand-xl) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg-collapse-block .navbar-collapse {
    display: block !important;
  }
}

/*------------------------------------
  Header Floating
------------------------------------*/

.u-header--floating {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2rem;
}

.u-header--floating.js-header-fix-moment {
  position: fixed;
  background-color: var(--white);
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
  margin-top: 0;
}

.u-header--floating.js-header-fix-moment .u-header--floating__inner {
  box-shadow: none;
}

.u-header--floating .u-header--floating__scrollbar {
  max-height: 50vh;
}

.u-header--floating .u-header--floating__scrollbar.mCS_destroyed {
  position: static !important;
}

.u-header--floating .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 0 24px;
}

.u-header--floating__inner {
  border-radius: 0.3125rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .u-header--floating-sm {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-sm.js-header-fix-moment {
    position: fixed;
    background-color: var(--white);
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-sm.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-sm .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-sm .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-sm .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 768px) {
  .u-header--floating-md {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-md.js-header-fix-moment {
    position: fixed;
    background-color: var(--white);
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-md.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-md .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-md .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-md .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 992px) {
  .u-header--floating-lg {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-lg.js-header-fix-moment {
    position: fixed;
    background-color: var(--white);
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-lg.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-lg .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-lg .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-lg .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .u-header--floating-xl {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-xl.js-header-fix-moment {
    position: fixed;
    background-color: var(--white);
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-xl.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-xl .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-xl .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-xl .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 575.98px) {
  .u-header--floating-sm .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-sm .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-header--floating-md .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-md .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-header--floating-lg .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-lg .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .u-header--floating-xl .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-xl .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

.u-header--floating .u-header__section {
  box-shadow: none;
}

.u-header--floating .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  display: none;
}

/*------------------------------------
  Absolute Positions
------------------------------------*/

.u-header--abs-top, .u-header--abs-bottom, .u-header--abs-top-2nd-screen {
  position: absolute;
}

.u-header--abs-top {
  top: 0;
  bottom: auto;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) {
  top: 100%;
  bottom: auto;
}

.u-header--abs-top-2nd-screen[data-header-fix-effect] {
  transition: none;
}

.u-header--abs-bottom:not(.js-header-fix-moment) {
  top: auto;
  bottom: 0;
}

.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__sub-menu {
  top: auto;
  bottom: 100%;
}

.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
  top: 0;
  bottom: auto;
}

.u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
}

.u-header--abs-bottom:not(.js-header-fix-moment) .slideInUp {
  -webkit-animation-name: absoluteSlideInUp;
  animation-name: absoluteSlideInUp;
}

@media (min-width: 576px) {
  .u-header--abs-top-sm, .u-header--abs-bottom-sm, .u-header--abs-top-2nd-screen-sm {
    position: absolute;
  }
  .u-header--abs-top-sm {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
    animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 768px) {
  .u-header--abs-top-md, .u-header--abs-bottom-md, .u-header--abs-top-2nd-screen-md {
    position: absolute;
  }
  .u-header--abs-top-md {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
    animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 992px) {
  .u-header--abs-top-lg, .u-header--abs-bottom-lg, .u-header--abs-top-2nd-screen-lg {
    position: absolute;
  }
  .u-header--abs-top-lg {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
    animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 1200px) {
  .u-header--abs-top-xl, .u-header--abs-bottom-xl, .u-header--abs-top-2nd-screen-xl {
    position: absolute;
  }
  .u-header--abs-top-xl {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
    animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu {
    bottom: 52.8%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .u-header--abs-bottom:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu {
    bottom: 52.8%;
  }
}

.u-header--abs-top__inner, .u-header--abs-bottom__inner, .u-header--abs-top-2nd-screen__inner {
  max-height: 50vh;
}

.u-header--abs-top__inner.mCS_destroyed, .u-header--abs-bottom__inner.mCS_destroyed, .u-header--abs-top-2nd-screen__inner.mCS_destroyed {
  position: static !important;
}

[class*="u-header--abs"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 0 24px;
}

/* Disable box-shadow on mobile device */

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none;
}

@-webkit-keyframes absoluteSlideInUp {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
}

@keyframes absoluteSlideInUp {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
}

/*------------------------------------
  Header Sidebar
------------------------------------*/

.u-header-sidebar__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 10.625rem;
}

.u-header-sidebar__content {
  padding: 1.5rem 3rem 3rem;
}

.u-header-sidebar__navbar-brand {
  display: block;
  width: 6.25rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

.u-header-sidebar__footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem;
}

.u-header-sidebar__footer-link {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.75rem;
}

.u-header-sidebar__footer-link:hover {
  color: var(--white);
}

/*------------------------------------
  Sticky Positions
------------------------------------*/

.u-header--sticky-top, .u-header--sticky-bottom {
  position: fixed;
}

.u-header--sticky-top {
  bottom: auto;
  top: 0;
}

.u-header--sticky-bottom {
  top: auto;
  bottom: 0;
}

.u-header--sticky-bottom .u-header__sub-menu {
  top: auto;
  bottom: 100%;
}

.u-header--sticky-bottom .u-header__sub-menu .u-header__sub-menu {
  top: 0;
  bottom: auto;
}

.u-header--sticky-bottom .navbar-nav .u-header__sub-menu {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
}

.u-header--sticky-bottom .slideInUp {
  -webkit-animation-name: stickySlideInUp;
  animation-name: stickySlideInUp;
}

@media (min-width: 576px) {
  .u-header--sticky-top-sm, .u-header--sticky-bottom-sm {
    position: fixed;
  }
  .u-header--sticky-top-sm {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-sm {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-sm .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-sm .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-sm .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-sm .slideInUp {
    -webkit-animation-name: stickySlideInUp;
    animation-name: stickySlideInUp;
  }
}

@media (min-width: 768px) {
  .u-header--sticky-top-md, .u-header--sticky-bottom-md {
    position: fixed;
  }
  .u-header--sticky-top-md {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-md {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-md .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-md .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-md .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-md .slideInUp {
    -webkit-animation-name: stickySlideInUp;
    animation-name: stickySlideInUp;
  }
}

@media (min-width: 992px) {
  .u-header--sticky-top-lg, .u-header--sticky-bottom-lg {
    position: fixed;
  }
  .u-header--sticky-top-lg {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-lg {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-lg .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-lg .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-lg .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-lg .slideInUp {
    -webkit-animation-name: stickySlideInUp;
    animation-name: stickySlideInUp;
  }
}

@media (min-width: 1200px) {
  .u-header--sticky-top-xl, .u-header--sticky-bottom-xl {
    position: fixed;
  }
  .u-header--sticky-top-xl {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-xl {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-xl .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-xl .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-xl .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-xl .slideInUp {
    -webkit-animation-name: stickySlideInUp;
    animation-name: stickySlideInUp;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .u-header--sticky-bottom-md:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu {
    bottom: 52.8%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .u-header--sticky-bottom:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu, .u-header--sticky-bottom-sm:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu {
    bottom: 52.8%;
  }
}

.u-header--sticky-top__inner, .u-header--sticky-bottom__inner {
  max-height: 50vh;
}

.u-header--sticky-top__inner.mCS_destroyed, .u-header--sticky-bottom__inner.mCS_destroyed {
  position: static !important;
}

.u-header--sticky-top__inner .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .u-header--sticky-bottom__inner .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 0 24px;
}

/* Disable box-shadow on mobile device */

.u-header--sticky-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .navbar-nav .u-header__sub-menu {
  box-shadow: none;
}

@-webkit-keyframes stickySlideInUp {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
}

@keyframes stickySlideInUp {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
}

/*------------------------------------
  Header Center Aligned
------------------------------------*/

.u-header--center-aligned .u-hamburger {
  display: none;
}

.u-header--center-aligned .u-header--center-aligned__inner {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding-top: .5rem;
}

.u-header--center-aligned .u-header__navbar-brand {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .u-header--center-aligned-sm .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-sm .u-header--center-aligned__inner {
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-sm .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .u-header--center-aligned-md .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-md .u-header--center-aligned__inner {
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-md .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .u-header--center-aligned-lg .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-lg .u-header--center-aligned__inner {
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-lg .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .u-header--center-aligned-xl .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-xl .u-header--center-aligned__inner {
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-xl .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 575.98px) {
  .u-header--center-aligned-sm .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-sm .u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 767.98px) {
  .u-header--center-aligned-md .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-md .u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 991.98px) {
  .u-header--center-aligned-lg .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-lg .u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 1199.98px) {
  .u-header--center-aligned-xl .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-xl .u-header__navbar {
    padding-top: 0;
  }
}

.u-header--center-aligned .u-header--center-aligned__inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 0.25rem;
}

.u-header--center-aligned .u-header__navbar {
  padding-top: 0;
}

/*------------------------------------
  Header Collapse
------------------------------------*/

.u-header-collapse__nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.u-header-collapse__nav-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.u-header-collapse__nav-link {
  color: var(--dark);
}

.u-header-collapse__nav-pointer {
  position: relative;
}

.u-header-collapse__nav-pointer::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 65%;
  font-weight: 900;
  content: "\f107";
  margin-left: auto;
  transition: 0.3s ease;
}

.u-header-collapse__nav-pointer.active::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.u-header-collapse__nav-link, .u-header-collapse__submenu-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.u-header-collapse__nav-link.active, .u-header-collapse__submenu-nav-link.active {
  color: var(--primary);
}

.u-header-collapse__submenu {
  position: relative;
  transition: 0.3s ease-in-out;
}

.u-header-collapse__submenu .u-header-collapse__nav-list {
  border-left: 2px solid var(--primary);
  padding-left: 1rem;
}

.u-header-collapse__submenu .u-header-collapse__nav-list--no-spacer {
  padding-left: 0;
}

.u-header-collapse__submenu-nav-link {
  color: var(--secondary);
}

.u-header-collapse__submenu-list {
  list-style: none;
  border-left: 2px solid var(--primary);
  padding-left: 0;
  margin-bottom: 0;
}

.u-header-collapse__submenu-list-link {
  display: inline-block;
  color: var(--secondary);
}

/*------------------------------------
  Alert
------------------------------------*/

.alert-text {
  color: inherit;
}

/*------------------------------------
  Avatar
------------------------------------*/

.u-avatar {
  width: 4.125rem;
  height: 4.125rem;
}

.u-xs-avatar {
  width: 1.375rem;
  height: 1.375rem;
}

.u-sm-avatar {
  width: 2rem;
  height: 2rem;
}

.u-lg-avatar {
  width: 5rem;
  height: 5rem;
}

.u-xl-avatar {
  width: 10rem;
  height: 10rem;
}

/*------------------------------------
  Avatar Bordered
------------------------------------*/

.u-sm-avatar--bordered {
  border: 2px solid var(--white);
}

.u-xl-avatar--bordered {
  border: 7px solid var(--white);
}

/*------------------------------------
  Badges
------------------------------------*/

[class*="badge-"] {
  z-index: 3;
}

.badge:empty {
  display: inherit;
}

.badge.badge-pos {
  position: absolute;
}

.btn .badge-pos {
  top: auto;
}

[class*="badge-outline"] {
  border-style: solid;
  border-width: 0.125rem;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}

/*------------------------------------
  Badge Sizes
------------------------------------*/

/* Extra Small */

.badge-xs {
  width: 0.875rem;
  height: 0.875rem;
  line-height: 1.4;
  font-size: 0.5rem;
}

/* Small */

.badge-sm {
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.4;
  font-size: 0.625rem;
}

.badge-sm[class*="badge-outline"] {
  line-height: 1;
}

/* Medium */

.badge-md {
  width: 1.375rem;
  height: 1.375rem;
  line-height: 1.7;
  font-size: 0.625rem;
}

.badge-md[class*="badge-outline"] {
  line-height: 1.3;
}

/* Bigger */

.badge-bigger {
  padding: 0.625rem;
}

/*------------------------------------
  Badge Styles
------------------------------------*/

/* primary badge */

.badge-outline-primary {
  color: var(--white);
  background: var(--primary);
  border-color: var(--white);
}

/* secondary badge */

.badge-outline-secondary {
  color: var(--white);
  background: var(--secondary);
  border-color: var(--white);
}

/* success badge */

.badge-outline-success {
  color: var(--white);
  background: var(--success);
  border-color: var(--white);
}

/* info badge */

.badge-outline-info {
  color: var(--dark);
  background: var(--info);
  border-color: var(--white);
}

/* warning badge */

.badge-outline-warning {
  color: var(--dark);
  background: var(--warning);
  border-color: var(--white);
}

/* danger badge */

.badge-outline-danger {
  color: var(--white);
  background: var(--danger);
  border-color: var(--white);
}

/* light badge */

.badge-outline-light {
  color: var(--dark);
  background: var(--light);
  border-color: var(--white);
}

/* dark badge */

.badge-outline-dark {
  color: var(--white);
  background: var(--dark);
  border-color: var(--white);
}

/* white badge */

.badge-outline-white {
  color: var(--dark);
  background: var(--white);
  border-color: var(--white);
}

/* indigo badge */

.badge-outline-indigo {
  color: var(--white);
  background: var(--indigo);
  border-color: var(--white);
}

.badge-outline-white {
  border-color: var(--white);
}

/*------------------------------------
  Badge Positions
------------------------------------*/

.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.badge-pos--top-left {
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.badge-pos--bottom-left {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.badge-pos--bottom-right {
  bottom: 0;
  right: 0;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

/*------------------------------------
  Badge in Avatar Component
------------------------------------*/

/* Default Size Avatar */

.u-avatar .rounded-circle+.badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(20%, -20%);
  transform: translate(20%, -20%);
}

.u-avatar .rounded-circle+.badge-xs.badge-pos--top-left {
  -webkit-transform: translate(-20%, -20%);
  transform: translate(-20%, -20%);
}

.u-avatar .rounded-circle+.badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(-20%, 20%);
  transform: translate(-20%, 20%);
}

.u-avatar .rounded-circle+.badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(20%, 20%);
  transform: translate(20%, 20%);
}

.u-avatar .rounded-circle+.badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(25%, -25%);
  transform: translate(25%, -25%);
}

.u-avatar .rounded-circle+.badge-sm.badge-pos--top-left {
  -webkit-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}

.u-avatar .rounded-circle+.badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(-25%, 25%);
  transform: translate(-25%, 25%);
}

.u-avatar .rounded-circle+.badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(25%, 25%);
  transform: translate(25%, 25%);
}

.u-avatar .rounded-circle+.badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(30%, -30%);
  transform: translate(30%, -30%);
}

.u-avatar .rounded-circle+.badge-md.badge-pos--top-left {
  -webkit-transform: translate(-30%, -30%);
  transform: translate(-30%, -30%);
}

.u-avatar .rounded-circle+.badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(-30%, 30%);
  transform: translate(-30%, 30%);
}

.u-avatar .rounded-circle+.badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(30%, 30%);
  transform: translate(30%, 30%);
}

/* Small Size Avatar */

.u-sm-avatar .rounded-circle+.badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(25%, -25%);
  transform: translate(25%, -25%);
}

.u-sm-avatar .rounded-circle+.badge-xs.badge-pos--top-left {
  -webkit-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}

.u-sm-avatar .rounded-circle+.badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(-25%, 25%);
  transform: translate(-25%, 25%);
}

.u-sm-avatar .rounded-circle+.badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(25%, 25%);
  transform: translate(25%, 25%);
}

.u-sm-avatar .rounded-circle+.badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .u-sm-avatar .rounded-circle+.badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(35%, -35%);
  transform: translate(35%, -35%);
}

.u-sm-avatar .rounded-circle+.badge-sm.badge-pos--top-left, .u-sm-avatar .rounded-circle+.badge-md.badge-pos--top-left {
  -webkit-transform: translate(-35%, -35%);
  transform: translate(-35%, -35%);
}

.u-sm-avatar .rounded-circle+.badge-sm.badge-pos--bottom-left, .u-sm-avatar .rounded-circle+.badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(-35%, 35%);
  transform: translate(-35%, 35%);
}

.u-sm-avatar .rounded-circle+.badge-sm.badge-pos--bottom-right, .u-sm-avatar .rounded-circle+.badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(35%, 35%);
  transform: translate(35%, 35%);
}

/* Large Size Avatar */

.u-lg-avatar .rounded-circle+.badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-35%, 35%);
  transform: translate(-35%, 35%);
}

.u-lg-avatar .rounded-circle+.badge-xs.badge-pos--top-left {
  -webkit-transform: translate(35%, 35%);
  transform: translate(35%, 35%);
}

.u-lg-avatar .rounded-circle+.badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(35%, -35%);
  transform: translate(35%, -35%);
}

.u-lg-avatar .rounded-circle+.badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(-35%, -35%);
  transform: translate(-35%, -35%);
}

.u-lg-avatar .rounded-circle+.badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-15%, 15%);
  transform: translate(-15%, 15%);
}

.u-lg-avatar .rounded-circle+.badge-sm.badge-pos--top-left {
  -webkit-transform: translate(15%, 15%);
  transform: translate(15%, 15%);
}

.u-lg-avatar .rounded-circle+.badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(15%, -15%);
  transform: translate(15%, -15%);
}

.u-lg-avatar .rounded-circle+.badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(-15%, -15%);
  transform: translate(-15%, -15%);
}

.u-lg-avatar .rounded-circle+.badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.u-lg-avatar .rounded-circle+.badge-md.badge-pos--top-left {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.u-lg-avatar .rounded-circle+.badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.u-lg-avatar .rounded-circle+.badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

/* Extra Large Size Avatar */

.u-xl-avatar .rounded-circle+.badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}

.u-xl-avatar .rounded-circle+.badge-xs.badge-pos--top-left {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}

.u-xl-avatar .rounded-circle+.badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}

.u-xl-avatar .rounded-circle+.badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

.u-xl-avatar .rounded-circle+.badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-65%, 65%);
  transform: translate(-65%, 65%);
}

.u-xl-avatar .rounded-circle+.badge-sm.badge-pos--top-left {
  -webkit-transform: translate(65%, 65%);
  transform: translate(65%, 65%);
}

.u-xl-avatar .rounded-circle+.badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(65%, -65%);
  transform: translate(65%, -65%);
}

.u-xl-avatar .rounded-circle+.badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(-65%, -65%);
  transform: translate(-65%, -65%);
}

.u-xl-avatar .rounded-circle+.badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-45%, 45%);
  transform: translate(-45%, 45%);
}

.u-xl-avatar .rounded-circle+.badge-md.badge-pos--top-left {
  -webkit-transform: translate(45%, 45%);
  transform: translate(45%, 45%);
}

.u-xl-avatar .rounded-circle+.badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(45%, -45%);
  transform: translate(45%, -45%);
}

.u-xl-avatar .rounded-circle+.badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(-45%, -45%);
  transform: translate(-45%, -45%);
}

/*------------------------------------
  Badge in Icon Component
------------------------------------*/

/* Default Size Icon */

.btn-icon.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(20%, -20%);
  transform: translate(20%, -20%);
}

.btn-icon.rounded-circle .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(-20%, -20%);
  transform: translate(-20%, -20%);
}

.btn-icon.rounded-circle .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(-20%, 20%);
  transform: translate(-20%, 20%);
}

.btn-icon.rounded-circle .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(20%, 20%);
  transform: translate(20%, 20%);
}

.btn-icon.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(25%, -25%);
  transform: translate(25%, -25%);
}

.btn-icon.rounded-circle .badge-sm.badge-pos--top-left {
  -webkit-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}

.btn-icon.rounded-circle .badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(-25%, 25%);
  transform: translate(-25%, 25%);
}

.btn-icon.rounded-circle .badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(25%, 25%);
  transform: translate(25%, 25%);
}

.btn-icon.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(30%, -30%);
  transform: translate(30%, -30%);
}

.btn-icon.rounded-circle .badge-md.badge-pos--top-left {
  -webkit-transform: translate(-30%, -30%);
  transform: translate(-30%, -30%);
}

.btn-icon.rounded-circle .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(-30%, 30%);
  transform: translate(-30%, 30%);
}

.btn-icon.rounded-circle .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(30%, 30%);
  transform: translate(30%, 30%);
}

/* Small Size Icon */

.btn-sm.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-sm>.rounded-circle.btn .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(25%, -25%);
  transform: translate(25%, -25%);
}

.btn-sm.rounded-circle .badge-xs.badge-pos--top-left, .btn-group-sm>.rounded-circle.btn .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}

.btn-sm.rounded-circle .badge-xs.badge-pos--bottom-left, .btn-group-sm>.rounded-circle.btn .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(-25%, 25%);
  transform: translate(-25%, 25%);
}

.btn-sm.rounded-circle .badge-xs.badge-pos--bottom-right, .btn-group-sm>.rounded-circle.btn .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(25%, 25%);
  transform: translate(25%, 25%);
}

.btn-sm.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-sm>.rounded-circle.btn .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-sm.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-sm>.rounded-circle.btn .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(35%, -35%);
  transform: translate(35%, -35%);
}

.btn-sm.rounded-circle .badge-sm.badge-pos--top-left, .btn-group-sm>.rounded-circle.btn .badge-sm.badge-pos--top-left, .btn-sm.rounded-circle .badge-md.badge-pos--top-left, .btn-group-sm>.rounded-circle.btn .badge-md.badge-pos--top-left {
  -webkit-transform: translate(-35%, -35%);
  transform: translate(-35%, -35%);
}

.btn-sm.rounded-circle .badge-sm.badge-pos--bottom-left, .btn-group-sm>.rounded-circle.btn .badge-sm.badge-pos--bottom-left, .btn-sm.rounded-circle .badge-md.badge-pos--bottom-left, .btn-group-sm>.rounded-circle.btn .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(-35%, 35%);
  transform: translate(-35%, 35%);
}

.btn-sm.rounded-circle .badge-sm.badge-pos--bottom-right, .btn-group-sm>.rounded-circle.btn .badge-sm.badge-pos--bottom-right, .btn-sm.rounded-circle .badge-md.badge-pos--bottom-right, .btn-group-sm>.rounded-circle.btn .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(35%, 35%);
  transform: translate(35%, 35%);
}

/* Large Size Icon */

.btn-lg.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-lg>.rounded-circle.btn .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-35%, 35%);
  transform: translate(-35%, 35%);
}

.btn-lg.rounded-circle .badge-xs.badge-pos--top-left, .btn-group-lg>.rounded-circle.btn .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(35%, 35%);
  transform: translate(35%, 35%);
}

.btn-lg.rounded-circle .badge-xs.badge-pos--bottom-left, .btn-group-lg>.rounded-circle.btn .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(35%, -35%);
  transform: translate(35%, -35%);
}

.btn-lg.rounded-circle .badge-xs.badge-pos--bottom-right, .btn-group-lg>.rounded-circle.btn .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(-35%, -35%);
  transform: translate(-35%, -35%);
}

.btn-lg.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-lg>.rounded-circle.btn .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-15%, 15%);
  transform: translate(-15%, 15%);
}

.btn-lg.rounded-circle .badge-sm.badge-pos--top-left, .btn-group-lg>.rounded-circle.btn .badge-sm.badge-pos--top-left {
  -webkit-transform: translate(15%, 15%);
  transform: translate(15%, 15%);
}

.btn-lg.rounded-circle .badge-sm.badge-pos--bottom-left, .btn-group-lg>.rounded-circle.btn .badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(15%, -15%);
  transform: translate(15%, -15%);
}

.btn-lg.rounded-circle .badge-sm.badge-pos--bottom-right, .btn-group-lg>.rounded-circle.btn .badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(-15%, -15%);
  transform: translate(-15%, -15%);
}

.btn-lg.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-lg>.rounded-circle.btn .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.btn-lg.rounded-circle .badge-md.badge-pos--top-left, .btn-group-lg>.rounded-circle.btn .badge-md.badge-pos--top-left {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.btn-lg.rounded-circle .badge-md.badge-pos--bottom-left, .btn-group-lg>.rounded-circle.btn .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.btn-lg.rounded-circle .badge-md.badge-pos--bottom-right, .btn-group-lg>.rounded-circle.btn .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

/*------------------------------------
  Buttons Bootstrap Style
------------------------------------*/

/* facebook */

.btn-facebook {
  color: var(--white);
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:hover {
  color: var(--white);
  background-color: #30497c;
  border-color: #2d4373;
}

.btn-facebook:focus, .btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  color: var(--white);
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show>.btn-facebook.dropdown-toggle {
  color: var(--white);
  background-color: #2d4373;
  border-color: #293e6a;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show>.btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

/* google */

.btn-google {
  color: var(--white);
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:hover {
  color: var(--white);
  background-color: #b33628;
  border-color: #a93325;
}

.btn-google:focus, .btn-google.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 94, 79, 0.5);
}

.btn-google.disabled, .btn-google:disabled {
  color: var(--white);
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active, .show>.btn-google.dropdown-toggle {
  color: var(--white);
  background-color: #a93325;
  border-color: #9e3023;
}

.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus, .show>.btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 94, 79, 0.5);
}

/* twitter */

.btn-twitter {
  color: var(--white);
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-twitter:hover {
  color: var(--white);
  background-color: #0d8ddc;
  border-color: #0c85d0;
}

.btn-twitter:focus, .btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

.btn-twitter.disabled, .btn-twitter:disabled {
  color: var(--white);
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active, .show>.btn-twitter.dropdown-toggle {
  color: var(--white);
  background-color: #0c85d0;
  border-color: #0b7ec4;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus, .show>.btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

/* instagram */

.btn-instagram {
  color: var(--white);
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:hover {
  color: var(--white);
  background-color: #345e80;
  border-color: #305777;
}

.btn-instagram:focus, .btn-instagram.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 135, 170, 0.5);
}

.btn-instagram.disabled, .btn-instagram:disabled {
  color: var(--white);
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active, .show>.btn-instagram.dropdown-toggle {
  color: var(--white);
  background-color: #305777;
  border-color: #2d516e;
}

.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus, .show>.btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 135, 170, 0.5);
}

/* github */

.btn-github {
  color: var(--white);
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:hover {
  color: var(--white);
  background-color: #131619;
  border-color: #0e1011;
}

.btn-github:focus, .btn-github.focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 73, 77, 0.5);
}

.btn-github.disabled, .btn-github:disabled {
  color: var(--white);
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active, .show>.btn-github.dropdown-toggle {
  color: var(--white);
  background-color: #0e1011;
  border-color: #08090a;
}

.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus, .show>.btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 73, 77, 0.5);
}

/* gray */

.btn-gray {
  color: var(--dark);
  background-color: #f8fafd;
  border-color: #f8fafd;
}

.btn-gray:hover {
  color: var(--dark);
  background-color: #dae5f5;
  border-color: #d0def2;
}

.btn-gray:focus, .btn-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 217, 220, 0.5);
}

.btn-gray.disabled, .btn-gray:disabled {
  color: var(--dark);
  background-color: #f8fafd;
  border-color: #f8fafd;
}

.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active, .show>.btn-gray.dropdown-toggle {
  color: var(--dark);
  background-color: #d0def2;
  border-color: #c6d7ef;
}

.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus, .show>.btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 217, 220, 0.5);
}

/*------------------------------------
  Custom Buttons
------------------------------------*/

/* primary button :hover, :focus, :active effects */

.btn-primary[href]:hover, .btn-primary[href]:focus, .btn-primary[href]:active, .btn-primary[nzType]:hover, .btn-primary[nzType]:focus, .btn-primary[nzType]:active {
  box-shadow: 0 4px 11px rgba(55, 125, 255, 0.35);
}

.btn-primary:not(label.btn), .btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-primary:not([href]), .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

/* secondary button :hover, :focus, :active effects */

.btn-secondary[href]:hover, .btn-secondary[href]:focus, .btn-secondary[href]:active, .btn-secondary[nzType]:hover, .btn-secondary[nzType]:focus, .btn-secondary[nzType]:active {
  box-shadow: 0 4px 11px rgba(119, 131, 143, 0.35);
}

.btn-secondary:not(label.btn), .btn-secondary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-secondary:not([href]), .btn-secondary:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

/* success button :hover, :focus, :active effects */

.btn-success[href]:hover, .btn-success[href]:focus, .btn-success[href]:active, .btn-success[nzType]:hover, .btn-success[nzType]:focus, .btn-success[nzType]:active {
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}

.btn-success:not(label.btn), .btn-success:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-success:not([href]), .btn-success:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--success);
  border-color: var(--success);
}

.btn-success:hover {
  background-color: var(--success);
  border-color: var(--success);
}

/* info button :hover, :focus, :active effects */

.btn-info[href]:hover, .btn-info[href]:focus, .btn-info[href]:active, .btn-info[nzType]:hover, .btn-info[nzType]:focus, .btn-info[nzType]:active {
  box-shadow: 0 4px 11px rgba(0, 223, 252, 0.35);
}

.btn-info:not(label.btn), .btn-info:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-info:not([href]), .btn-info:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--info);
  border-color: var(--info);
}

.btn-info:hover {
  background-color: var(--info);
  border-color: var(--info);
}

/* warning button :hover, :focus, :active effects */

.btn-warning[href]:hover, .btn-warning[href]:focus, .btn-warning[href]:active, .btn-warning[nzType]:hover, .btn-warning[nzType]:focus, .btn-warning[nzType]:active {
  box-shadow: 0 4px 11px rgba(255, 193, 7, 0.35);
}

.btn-warning:not(label.btn), .btn-warning:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-warning:not([href]), .btn-warning:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-warning:hover {
  background-color: var(--warning);
  border-color: var(--warning);
}

/* danger button :hover, :focus, :active effects */

.btn-danger[href]:hover, .btn-danger[href]:focus, .btn-danger[href]:active, .btn-danger[nzType]:hover, .btn-danger[nzType]:focus, .btn-danger[nzType]:active {
  box-shadow: 0 4px 11px rgba(222, 68, 55, 0.35);
}

.btn-danger:not(label.btn), .btn-danger:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-danger:not([href]), .btn-danger:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-danger:hover {
  background-color: var(--danger);
  border-color: var(--danger);
}

/* light button :hover, :focus, :active effects */

.btn-light[href]:hover, .btn-light[href]:focus, .btn-light[href]:active, .btn-light[nzType]:hover, .btn-light[nzType]:focus, .btn-light[nzType]:active {
  box-shadow: 0 4px 11px rgba(248, 249, 250, 0.35);
}

.btn-light:not(label.btn), .btn-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-light:not([href]), .btn-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--light);
  border-color: var(--light);
}

.btn-light:hover {
  background-color: var(--light);
  border-color: var(--light);
}

/* dark button :hover, :focus, :active effects */

.btn-dark[href]:hover, .btn-dark[href]:focus, .btn-dark[href]:active, .btn-dark[nzType]:hover, .btn-dark[nzType]:focus, .btn-dark[nzType]:active {
  box-shadow: 0 4px 11px rgba(30, 32, 34, 0.35);
}

.btn-dark:not(label.btn), .btn-dark:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-dark:not([href]), .btn-dark:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-dark:hover {
  background-color: var(--dark);
  border-color: var(--dark);
}

/* white button :hover, :focus, :active effects */

.btn-white[href]:hover, .btn-white[href]:focus, .btn-white[href]:active, .btn-white[nzType]:hover, .btn-white[nzType]:focus, .btn-white[nzType]:active {
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}

.btn-white:not(label.btn), .btn-white:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-white:not([href]), .btn-white:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--white);
  border-color: var(--white);
}

.btn-white:hover {
  background-color: var(--white);
  border-color: var(--white);
}

/* indigo button :hover, :focus, :active effects */

.btn-indigo[href]:hover, .btn-indigo[href]:focus, .btn-indigo[href]:active, .btn-indigo[nzType]:hover, .btn-indigo[nzType]:focus, .btn-indigo[nzType]:active {
  box-shadow: 0 4px 11px rgba(45, 21, 130, 0.35);
}

.btn-indigo:not(label.btn), .btn-indigo:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-indigo:not([href]), .btn-indigo:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: var(--indigo);
  border-color: var(--indigo);
}

.btn-indigo:hover {
  background-color: var(--indigo);
  border-color: var(--indigo);
}

/* facebook button :hover, :focus, :active effects */

.btn-facebook[href]:hover, .btn-facebook[href]:focus, .btn-facebook[href]:active, .btn-facebook[nzType]:hover, .btn-facebook[nzType]:focus, .btn-facebook[nzType]:active {
  box-shadow: 0 4px 11px rgba(59, 89, 152, 0.35);
}

.btn-facebook:not(label.btn), .btn-facebook:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-facebook:not([href]), .btn-facebook:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}

/* google button :hover, :focus, :active effects */

.btn-google[href]:hover, .btn-google[href]:focus, .btn-google[href]:active, .btn-google[nzType]:hover, .btn-google[nzType]:focus, .btn-google[nzType]:active {
  box-shadow: 0 4px 11px rgba(209, 65, 48, 0.35);
}

.btn-google:not(label.btn), .btn-google:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-google:not([href]), .btn-google:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:hover {
  background-color: #d14130;
  border-color: #d14130;
}

/* twitter button :hover, :focus, :active effects */

.btn-twitter[href]:hover, .btn-twitter[href]:focus, .btn-twitter[href]:active, .btn-twitter[nzType]:hover, .btn-twitter[nzType]:focus, .btn-twitter[nzType]:active {
  box-shadow: 0 4px 11px rgba(29, 161, 242, 0.35);
}

.btn-twitter:not(label.btn), .btn-twitter:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-twitter:not([href]), .btn-twitter:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-twitter:hover {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

/* instagram button :hover, :focus, :active effects */

.btn-instagram[href]:hover, .btn-instagram[href]:focus, .btn-instagram[href]:active, .btn-instagram[nzType]:hover, .btn-instagram[nzType]:focus, .btn-instagram[nzType]:active {
  box-shadow: 0 4px 11px rgba(63, 114, 155, 0.35);
}

.btn-instagram:not(label.btn), .btn-instagram:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-instagram:not([href]), .btn-instagram:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:hover {
  background-color: #3f729b;
  border-color: #3f729b;
}

/* github button :hover, :focus, :active effects */

.btn-github[href]:hover, .btn-github[href]:focus, .btn-github[href]:active, .btn-github[nzType]:hover, .btn-github[nzType]:focus, .btn-github[nzType]:active {
  box-shadow: 0 4px 11px rgba(36, 41, 46, 0.35);
}

.btn-github:not(label.btn), .btn-github:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-github:not([href]), .btn-github:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:hover {
  background-color: #24292e;
  border-color: #24292e;
}

/* gray button :hover, :focus, :active effects */

.btn-gray[href]:hover, .btn-gray[href]:focus, .btn-gray[href]:active, .btn-gray[nzType]:hover, .btn-gray[nzType]:focus, .btn-gray[nzType]:active {
  box-shadow: 0 4px 11px rgba(248, 250, 253, 0.35);
}

.btn-gray:not(label.btn), .btn-gray:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray:not([href]), .btn-gray:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f8fafd;
  border-color: #f8fafd;
}

.btn-gray:hover {
  background-color: #f8fafd;
  border-color: #f8fafd;
}

/*------------------------------------
  Custom Button Toggle
------------------------------------*/

.btn-custom-toggle-primary {
  color: var(--gray);
  border-color: #d5dae2;
}

.btn-custom-toggle-primary:hover {
  color: var(--primary);
  background-color: transparent;
  border-color: var(--primary);
}

.btn-custom-toggle-primary:not(:disabled):not(.disabled):active, .btn-custom-toggle-primary:not(:disabled):not(.disabled).active, .btn-custom-toggle-primary:not(:disabled):not(.disabled):active, .btn-custom-toggle-primary:not(:disabled):not(.disabled).active {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-custom-toggle-white:hover {
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.2);
}

.btn-custom-toggle-white:not(:disabled):not(.disabled):active, .btn-custom-toggle-white:not(:disabled):not(.disabled).active, .btn-custom-toggle-white:not(:disabled):not(.disabled):active, .btn-custom-toggle-white:not(:disabled):not(.disabled).active {
  color: var(--primary);
}

/*------------------------------------
  Air Button Styles
------------------------------------*/

/* primary air */

.btn-soft-primary {
  color: var(--primary);
  background: rgba(55, 125, 255, 0.1);
  border-color: transparent;
}

.btn-soft-primary:hover, .btn-soft-primary:focus, .btn-soft-primary:active, .btn-soft-primary.active, .btn-soft-primary[nzType]:hover, .btn-soft-primary[nzType]:focus, .btn-soft-primary[nzType]:active, .btn-soft-primary[nzType].active {
  color: var(--white) !important;
  background: var(--primary);
  box-shadow: 0 4px 11px rgba(55, 125, 255, 0.35);
}

.btn-soft-primary.disabled, .btn-soft-primary:disabled, .btn-soft-primary[nzType].disabled, .btn-soft-primary[nzType]:disabled,
.btn-soft-primary.disabled:hover, .btn-soft-primary:disabled:hover, .btn-soft-primary[nzType].disabled:hover, .btn-soft-primary[nzType]:disabled:hover {
  color: var(--primary) !important;
  background-color: rgba(55, 125, 255, 0.1) !important;
  box-shadow: none;
}

.btn-soft-primary:not(:disabled):not(.disabled):active, .btn-soft-primary:not(:disabled):not(.disabled).active, .show>.btn-soft-primary.dropdown-toggle, .btn-soft-primary[nzType]:not(:disabled):not(.disabled):active, .btn-soft-primary[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-primary[nzType].dropdown-toggle {
  color: var(--white);
  background-color: var(--primary);
}

.btn.btn-soft-primary {
  color: var(--primary);
}

/* secondary air */

.btn-soft-secondary {
  color: var(--secondary);
  background: rgba(119, 131, 143, 0.1);
  border-color: transparent;
}

.btn-soft-secondary[href]:hover, .btn-soft-secondary[href]:focus, .btn-soft-secondary[href]:active, .btn-soft-secondary[href].active, .btn-soft-secondary[nzType]:hover, .btn-soft-secondary[nzType]:focus, .btn-soft-secondary[nzType]:active, .btn-soft-secondary[nzType].active {
  color: var(--white);
  background: var(--secondary);
  box-shadow: 0 4px 11px rgba(119, 131, 143, 0.35);
}

.btn-soft-secondary[href].disabled, .btn-soft-secondary[href]:disabled, .btn-soft-secondary[nzType].disabled, .btn-soft-secondary[nzType]:disabled {
  color: var(--secondary);
  background-color: rgba(119, 131, 143, 0.1);
  box-shadow: none;
}

.btn-soft-secondary[href]:not(:disabled):not(.disabled):active, .btn-soft-secondary[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-secondary[href].dropdown-toggle, .btn-soft-secondary[nzType]:not(:disabled):not(.disabled):active, .btn-soft-secondary[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-secondary[nzType].dropdown-toggle {
  color: var(--white);
  background-color: var(--secondary);
}

.btn.btn-soft-secondary {
  color: var(--secondary);
}

/* success air */

.btn-soft-success {
  color: var(--success);
  background: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}

.btn-soft-success[href]:hover, .btn-soft-success[href]:focus, .btn-soft-success[href]:active, .btn-soft-success[href].active, .btn-soft-success[nzType]:hover, .btn-soft-success[nzType]:focus, .btn-soft-success[nzType]:active, .btn-soft-success[nzType].active {
  color: var(--white);
  background: var(--success);
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}

.btn-soft-success[href].disabled, .btn-soft-success[href]:disabled, .btn-soft-success[nzType].disabled, .btn-soft-success[nzType]:disabled {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
  box-shadow: none;
}

.btn-soft-success[href]:not(:disabled):not(.disabled):active, .btn-soft-success[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-success[href].dropdown-toggle, .btn-soft-success[nzType]:not(:disabled):not(.disabled):active, .btn-soft-success[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-success[nzType].dropdown-toggle {
  color: var(--white);
  background-color: var(--success);
}

.btn.btn-soft-success {
  color: var(--success);
}

/* info air */

.btn-soft-info {
  color: var(--info);
  background: rgba(0, 223, 252, 0.1);
  border-color: transparent;
}

.btn-soft-info[href]:hover, .btn-soft-info[href]:focus, .btn-soft-info[href]:active, .btn-soft-info[href].active, .btn-soft-info[nzType]:hover, .btn-soft-info[nzType]:focus, .btn-soft-info[nzType]:active, .btn-soft-info[nzType].active {
  color: var(--dark);
  background: var(--info);
  box-shadow: 0 4px 11px rgba(0, 223, 252, 0.35);
}

.btn-soft-info[href].disabled, .btn-soft-info[href]:disabled, .btn-soft-info[nzType].disabled, .btn-soft-info[nzType]:disabled {
  color: var(--info);
  background-color: rgba(0, 223, 252, 0.1);
  box-shadow: none;
}

.btn-soft-info[href]:not(:disabled):not(.disabled):active, .btn-soft-info[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-info[href].dropdown-toggle, .btn-soft-info[nzType]:not(:disabled):not(.disabled):active, .btn-soft-info[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-info[nzType].dropdown-toggle {
  color: var(--dark);
  background-color: var(--info);
}

.btn.btn-soft-info {
  color: var(--info);
}

/* warning air */

.btn-soft-warning {
  color: var(--warning);
  background: rgba(255, 193, 7, 0.1);
  border-color: transparent;
}

.btn-soft-warning[href]:hover, .btn-soft-warning[href]:focus, .btn-soft-warning[href]:active, .btn-soft-warning[href].active, .btn-soft-warning[nzType]:hover, .btn-soft-warning[nzType]:focus, .btn-soft-warning[nzType]:active, .btn-soft-warning[nzType].active {
  color: var(--dark);
  background: var(--warning);
  box-shadow: 0 4px 11px rgba(255, 193, 7, 0.35);
}

.btn-soft-warning[href].disabled, .btn-soft-warning[href]:disabled, .btn-soft-warning[nzType].disabled, .btn-soft-warning[nzType]:disabled {
  color: var(--warning);
  background-color: rgba(255, 193, 7, 0.1);
  box-shadow: none;
}

.btn-soft-warning[href]:not(:disabled):not(.disabled):active, .btn-soft-warning[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-warning[href].dropdown-toggle, .btn-soft-warning[nzType]:not(:disabled):not(.disabled):active, .btn-soft-warning[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-warning[nzType].dropdown-toggle {
  color: var(--dark);
  background-color: var(--warning);
}

.btn.btn-soft-warning {
  color: var(--warning);
}

/* danger air */

.btn-soft-danger {
  color: var(--danger);
  background: rgba(222, 68, 55, 0.1);
  border-color: transparent;
}

.btn-soft-danger[href]:hover, .btn-soft-danger[href]:focus, .btn-soft-danger[href]:active, .btn-soft-danger[href].active, .btn-soft-danger[nzType]:hover, .btn-soft-danger[nzType]:focus, .btn-soft-danger[nzType]:active, .btn-soft-danger[nzType].active {
  color: var(--white);
  background: var(--danger);
  box-shadow: 0 4px 11px rgba(222, 68, 55, 0.35);
}

.btn-soft-danger[href].disabled, .btn-soft-danger[href]:disabled, .btn-soft-danger[nzType].disabled, .btn-soft-danger[nzType]:disabled {
  color: var(--danger);
  background-color: rgba(222, 68, 55, 0.1);
  box-shadow: none;
}

.btn-soft-danger[href]:not(:disabled):not(.disabled):active, .btn-soft-danger[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-danger[href].dropdown-toggle, .btn-soft-danger[nzType]:not(:disabled):not(.disabled):active, .btn-soft-danger[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-danger[nzType].dropdown-toggle {
  color: var(--white);
  background-color: var(--danger);
}

.btn.btn-soft-danger {
  color: var(--danger);
}

/* light air */

.btn-soft-light {
  color: var(--light);
  background: rgba(248, 249, 250, 0.1);
  border-color: transparent;
}

.btn-soft-light[href]:hover, .btn-soft-light[href]:focus, .btn-soft-light[href]:active, .btn-soft-light[href].active, .btn-soft-light[nzType]:hover, .btn-soft-light[nzType]:focus, .btn-soft-light[nzType]:active, .btn-soft-light[nzType].active {
  color: var(--dark);
  background: var(--light);
  box-shadow: 0 4px 11px rgba(248, 249, 250, 0.35);
}

.btn-soft-light[href].disabled, .btn-soft-light[href]:disabled, .btn-soft-light[nzType].disabled, .btn-soft-light[nzType]:disabled {
  color: var(--light);
  background-color: rgba(248, 249, 250, 0.1);
  box-shadow: none;
}

.btn-soft-light[href]:not(:disabled):not(.disabled):active, .btn-soft-light[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-light[href].dropdown-toggle, .btn-soft-light[nzType]:not(:disabled):not(.disabled):active, .btn-soft-light[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-light[nzType].dropdown-toggle {
  color: var(--dark);
  background-color: var(--light);
}

.btn.btn-soft-light {
  color: var(--light);
}

/* dark air */

.btn-soft-dark {
  color: var(--dark);
  background: rgba(30, 32, 34, 0.1);
  border-color: transparent;
}

.btn-soft-dark[href]:hover, .btn-soft-dark[href]:focus, .btn-soft-dark[href]:active, .btn-soft-dark[href].active, .btn-soft-dark[nzType]:hover, .btn-soft-dark[nzType]:focus, .btn-soft-dark[nzType]:active, .btn-soft-dark[nzType].active {
  color: var(--white);
  background: var(--dark);
  box-shadow: 0 4px 11px rgba(30, 32, 34, 0.35);
}

.btn-soft-dark[href].disabled, .btn-soft-dark[href]:disabled, .btn-soft-dark[nzType].disabled, .btn-soft-dark[nzType]:disabled {
  color: var(--dark);
  background-color: rgba(30, 32, 34, 0.1);
  box-shadow: none;
}

.btn-soft-dark[href]:not(:disabled):not(.disabled):active, .btn-soft-dark[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-dark[href].dropdown-toggle, .btn-soft-dark[nzType]:not(:disabled):not(.disabled):active, .btn-soft-dark[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-dark[nzType].dropdown-toggle {
  color: var(--white);
  background-color: var(--dark);
}

.btn.btn-soft-dark {
  color: var(--dark);
}

/* white air */

.btn-soft-white {
  color: var(--white);
  background: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}

.btn-soft-white[href]:hover, .btn-soft-white[href]:focus, .btn-soft-white[href]:active, .btn-soft-white[href].active, .btn-soft-white[nzType]:hover, .btn-soft-white[nzType]:focus, .btn-soft-white[nzType]:active, .btn-soft-white[nzType].active {
  color: var(--dark);
  background: var(--white);
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}

.btn-soft-white[href].disabled, .btn-soft-white[href]:disabled, .btn-soft-white[nzType].disabled, .btn-soft-white[nzType]:disabled {
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
}

.btn-soft-white[href]:not(:disabled):not(.disabled):active, .btn-soft-white[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-white[href].dropdown-toggle, .btn-soft-white[nzType]:not(:disabled):not(.disabled):active, .btn-soft-white[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-white[nzType].dropdown-toggle {
  color: var(--dark);
  background-color: var(--white);
}

.btn.btn-soft-white {
  color: var(--white);
}

/* indigo air */

.btn-soft-indigo {
  color: var(--indigo);
  background: rgba(45, 21, 130, 0.1);
  border-color: transparent;
}

.btn-soft-indigo[href]:hover, .btn-soft-indigo[href]:focus, .btn-soft-indigo[href]:active, .btn-soft-indigo[href].active, .btn-soft-indigo[nzType]:hover, .btn-soft-indigo[nzType]:focus, .btn-soft-indigo[nzType]:active, .btn-soft-indigo[nzType].active {
  color: var(--white);
  background: var(--indigo);
  box-shadow: 0 4px 11px rgba(45, 21, 130, 0.35);
}

.btn-soft-indigo[href].disabled, .btn-soft-indigo[href]:disabled, .btn-soft-indigo[nzType].disabled, .btn-soft-indigo[nzType]:disabled {
  color: var(--indigo);
  background-color: rgba(45, 21, 130, 0.1);
  box-shadow: none;
}

.btn-soft-indigo[href]:not(:disabled):not(.disabled):active, .btn-soft-indigo[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-indigo[href].dropdown-toggle, .btn-soft-indigo[nzType]:not(:disabled):not(.disabled):active, .btn-soft-indigo[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-indigo[nzType].dropdown-toggle {
  color: var(--white);
  background-color: var(--indigo);
}

.btn.btn-soft-indigo {
  color: var(--indigo);
}

/* facebook air */

.btn-soft-facebook {
  color: #3b5998;
  background: rgba(59, 89, 152, 0.1);
  border-color: transparent;
}

.btn-soft-facebook[href]:hover, .btn-soft-facebook[href]:focus, .btn-soft-facebook[href]:active, .btn-soft-facebook[href].active, .btn-soft-facebook[nzType]:hover, .btn-soft-facebook[nzType]:focus, .btn-soft-facebook[nzType]:active, .btn-soft-facebook[nzType].active {
  color: var(--white);
  background: #3b5998;
  box-shadow: 0 4px 11px rgba(59, 89, 152, 0.35);
}

.btn-soft-facebook[href].disabled, .btn-soft-facebook[href]:disabled, .btn-soft-facebook[nzType].disabled, .btn-soft-facebook[nzType]:disabled {
  color: #3b5998;
  background-color: rgba(59, 89, 152, 0.1);
  box-shadow: none;
}

.btn-soft-facebook[href]:not(:disabled):not(.disabled):active, .btn-soft-facebook[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-facebook[href].dropdown-toggle, .btn-soft-facebook[nzType]:not(:disabled):not(.disabled):active, .btn-soft-facebook[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-facebook[nzType].dropdown-toggle {
  color: var(--white);
  background-color: #3b5998;
}

.btn.btn-soft-facebook {
  color: #3b5998;
}

/* google air */

.btn-soft-google {
  color: #d14130;
  background: rgba(209, 65, 48, 0.1);
  border-color: transparent;
}

.btn-soft-google[href]:hover, .btn-soft-google[href]:focus, .btn-soft-google[href]:active, .btn-soft-google[href].active, .btn-soft-google[nzType]:hover, .btn-soft-google[nzType]:focus, .btn-soft-google[nzType]:active, .btn-soft-google[nzType].active {
  color: var(--white);
  background: #d14130;
  box-shadow: 0 4px 11px rgba(209, 65, 48, 0.35);
}

.btn-soft-google[href].disabled, .btn-soft-google[href]:disabled, .btn-soft-google[nzType].disabled, .btn-soft-google[nzType]:disabled {
  color: #d14130;
  background-color: rgba(209, 65, 48, 0.1);
  box-shadow: none;
}

.btn-soft-google[href]:not(:disabled):not(.disabled):active, .btn-soft-google[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-google[href].dropdown-toggle, .btn-soft-google[nzType]:not(:disabled):not(.disabled):active, .btn-soft-google[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-google[nzType].dropdown-toggle {
  color: var(--white);
  background-color: #d14130;
}

.btn.btn-soft-google {
  color: #d14130;
}

/* twitter air */

.btn-soft-twitter {
  color: #1da1f2;
  background: rgba(29, 161, 242, 0.1);
  border-color: transparent;
}

.btn-soft-twitter[href]:hover, .btn-soft-twitter[href]:focus, .btn-soft-twitter[href]:active, .btn-soft-twitter[href].active, .btn-soft-twitter[nzType]:hover, .btn-soft-twitter[nzType]:focus, .btn-soft-twitter[nzType]:active, .btn-soft-twitter[nzType].active {
  color: var(--white);
  background: #1da1f2;
  box-shadow: 0 4px 11px rgba(29, 161, 242, 0.35);
}

.btn-soft-twitter[href].disabled, .btn-soft-twitter[href]:disabled, .btn-soft-twitter[nzType].disabled, .btn-soft-twitter[nzType]:disabled {
  color: #1da1f2;
  background-color: rgba(29, 161, 242, 0.1);
  box-shadow: none;
}

.btn-soft-twitter[href]:not(:disabled):not(.disabled):active, .btn-soft-twitter[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-twitter[href].dropdown-toggle, .btn-soft-twitter[nzType]:not(:disabled):not(.disabled):active, .btn-soft-twitter[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-twitter[nzType].dropdown-toggle {
  color: var(--white);
  background-color: #1da1f2;
}

.btn.btn-soft-twitter {
  color: #1da1f2;
}

/* instagram air */

.btn-soft-instagram {
  color: #3f729b;
  background: rgba(63, 114, 155, 0.1);
  border-color: transparent;
}

.btn-soft-instagram[href]:hover, .btn-soft-instagram[href]:focus, .btn-soft-instagram[href]:active, .btn-soft-instagram[href].active, .btn-soft-instagram[nzType]:hover, .btn-soft-instagram[nzType]:focus, .btn-soft-instagram[nzType]:active, .btn-soft-instagram[nzType].active {
  color: var(--white);
  background: #3f729b;
  box-shadow: 0 4px 11px rgba(63, 114, 155, 0.35);
}

.btn-soft-instagram[href].disabled, .btn-soft-instagram[href]:disabled, .btn-soft-instagram[nzType].disabled, .btn-soft-instagram[nzType]:disabled {
  color: #3f729b;
  background-color: rgba(63, 114, 155, 0.1);
  box-shadow: none;
}

.btn-soft-instagram[href]:not(:disabled):not(.disabled):active, .btn-soft-instagram[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-instagram[href].dropdown-toggle, .btn-soft-instagram[nzType]:not(:disabled):not(.disabled):active, .btn-soft-instagram[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-instagram[nzType].dropdown-toggle {
  color: var(--white);
  background-color: #3f729b;
}

.btn.btn-soft-instagram {
  color: #3f729b;
}

/* github air */

.btn-soft-github {
  color: #24292e;
  background: rgba(36, 41, 46, 0.1);
  border-color: transparent;
}

.btn-soft-github[href]:hover, .btn-soft-github[href]:focus, .btn-soft-github[href]:active, .btn-soft-github[href].active, .btn-soft-github[nzType]:hover, .btn-soft-github[nzType]:focus, .btn-soft-github[nzType]:active, .btn-soft-github[nzType].active {
  color: var(--white);
  background: #24292e;
  box-shadow: 0 4px 11px rgba(36, 41, 46, 0.35);
}

.btn-soft-github[href].disabled, .btn-soft-github[href]:disabled, .btn-soft-github[nzType].disabled, .btn-soft-github[nzType]:disabled {
  color: #24292e;
  background-color: rgba(36, 41, 46, 0.1);
  box-shadow: none;
}

.btn-soft-github[href]:not(:disabled):not(.disabled):active, .btn-soft-github[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-github[href].dropdown-toggle, .btn-soft-github[nzType]:not(:disabled):not(.disabled):active, .btn-soft-github[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-github[nzType].dropdown-toggle {
  color: var(--white);
  background-color: #24292e;
}

.btn.btn-soft-github {
  color: #24292e;
}

/* gray air */

.btn-soft-gray {
  color: #f8fafd;
  background: rgba(248, 250, 253, 0.1);
  border-color: transparent;
}

.btn-soft-gray[href]:hover, .btn-soft-gray[href]:focus, .btn-soft-gray[href]:active, .btn-soft-gray[href].active, .btn-soft-gray[nzType]:hover, .btn-soft-gray[nzType]:focus, .btn-soft-gray[nzType]:active, .btn-soft-gray[nzType].active {
  color: var(--dark);
  background: #f8fafd;
  box-shadow: 0 4px 11px rgba(248, 250, 253, 0.35);
}

.btn-soft-gray[href].disabled, .btn-soft-gray[href]:disabled, .btn-soft-gray[nzType].disabled, .btn-soft-gray[nzType]:disabled {
  color: #f8fafd;
  background-color: rgba(248, 250, 253, 0.1);
  box-shadow: none;
}

.btn-soft-gray[href]:not(:disabled):not(.disabled):active, .btn-soft-gray[href]:not(:disabled):not(.disabled).active, .show>.btn-soft-gray[href].dropdown-toggle, .btn-soft-gray[nzType]:not(:disabled):not(.disabled):active, .btn-soft-gray[nzType]:not(:disabled):not(.disabled).active, .show>.btn-soft-gray[nzType].dropdown-toggle {
  color: var(--dark);
  background-color: #f8fafd;
}

.btn.btn-soft-gray {
  color: #f8fafd;
}

/*------------------------------------
  Button Sizes
------------------------------------*/

/* Wide */

.btn-wide {
  min-width: 9.6875rem;
}

.btn-sm-wide {
  min-width: 8.4375rem;
}

/* Extra Small */

.btn-xs {
  font-size: 0.6875rem;
  font-weight: 400;
  padding: 0.4375rem 0.9375rem;
}

/* Icons */

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
}

.btn-icon.btn-sm, .btn-group-sm>.btn-icon.btn {
  font-size: 1.2rem;
  width: 2.2rem;
  height: 2.2rem;
}

.btn-icon.btn-lg, .btn-group-lg>.btn-icon.btn {
  font-size: 1.75rem;
  width: 5rem;
  height: 5rem;
}

/*------------------------------------
  Text Buttons Styles
------------------------------------*/

/* primary text button */

.btn-text-primary {
  color: var(--primary);
  background: transparent;
}

.btn-text-primary[href]:hover, .btn-text-primary[href]:focus, .btn-text-primary[href]:active, .btn-text-primary[nzType]:hover, .btn-text-primary[nzType]:focus, .btn-text-primary[nzType]:active {
  color: var(--dark);
}

/* secondary text button */

.btn-text-secondary {
  color: var(--secondary);
  background: transparent;
}

.btn-text-secondary[href]:hover, .btn-text-secondary[href]:focus, .btn-text-secondary[href]:active, .btn-text-secondary[nzType]:hover, .btn-text-secondary[nzType]:focus, .btn-text-secondary[nzType]:active {
  color: var(--dark);
}

/* success text button */

.btn-text-success {
  color: var(--success);
  background: transparent;
}

.btn-text-success[href]:hover, .btn-text-success[href]:focus, .btn-text-success[href]:active, .btn-text-success[nzType]:hover, .btn-text-success[nzType]:focus, .btn-text-success[nzType]:active {
  color: var(--dark);
}

/* info text button */

.btn-text-info {
  color: var(--info);
  background: transparent;
}

.btn-text-info[href]:hover, .btn-text-info[href]:focus, .btn-text-info[href]:active, .btn-text-info[nzType]:hover, .btn-text-info[nzType]:focus, .btn-text-info[nzType]:active {
  color: var(--dark);
}

/* warning text button */

.btn-text-warning {
  color: var(--warning);
  background: transparent;
}

.btn-text-warning[href]:hover, .btn-text-warning[href]:focus, .btn-text-warning[href]:active, .btn-text-warning[nzType]:hover, .btn-text-warning[nzType]:focus, .btn-text-warning[nzType]:active {
  color: var(--dark);
}

/* danger text button */

.btn-text-danger {
  color: var(--danger);
  background: transparent;
}

.btn-text-danger[href]:hover, .btn-text-danger[href]:focus, .btn-text-danger[href]:active, .btn-text-danger[nzType]:hover, .btn-text-danger[nzType]:focus, .btn-text-danger[nzType]:active {
  color: var(--dark);
}

/* light text button */

.btn-text-light {
  color: var(--light);
  background: transparent;
}

.btn-text-light[href]:hover, .btn-text-light[href]:focus, .btn-text-light[href]:active, .btn-text-light[nzType]:hover, .btn-text-light[nzType]:focus, .btn-text-light[nzType]:active {
  color: #bdc5d1;
}

/* dark text button */

.btn-text-dark {
  color: var(--dark);
  background: transparent;
}

.btn-text-dark[href]:hover, .btn-text-dark[href]:focus, .btn-text-dark[href]:active, .btn-text-dark[nzType]:hover, .btn-text-dark[nzType]:focus, .btn-text-dark[nzType]:active {
  color: var(--dark);
}

/* white text button */

.btn-text-white {
  color: var(--white);
  background: transparent;
}

.btn-text-white[href]:hover, .btn-text-white[href]:focus, .btn-text-white[href]:active, .btn-text-white[nzType]:hover, .btn-text-white[nzType]:focus, .btn-text-white[nzType]:active {
  color: var(--dark);
}

/* indigo text button */

.btn-text-indigo {
  color: var(--indigo);
  background: transparent;
}

.btn-text-indigo[href]:hover, .btn-text-indigo[href]:focus, .btn-text-indigo[href]:active, .btn-text-indigo[nzType]:hover, .btn-text-indigo[nzType]:focus, .btn-text-indigo[nzType]:active {
  color: var(--dark);
}

/*------------------------------------
  Buttons
------------------------------------*/

label.btn {
  cursor: pointer !important;
}

/* Pill */

.btn-pill {
  border-radius: 6.1875rem;
}

/* Icon */

.btn-icon {
  position: relative;
  line-height: 0;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items:center;
}

/* Background Transparent */

[class*="btn-soft-"].btn-bg-transparent {
  background-color: transparent;
}

/* Toggle */

.btn__toggle-default {
  display: inline-block;
}

.btn__toggle-toggled {
  display: none;
}

.btn.toggled .btn__toggle-default {
  display: none;
}

.btn.toggled .btn__toggle-toggled {
  display: inline-block;
}

/*------------------------------------
  Brand
------------------------------------*/

.brand {
  font-size: 2rem;
  font-weight: 700;
  margin-left: .5rem;
}

.brand-primary, .brand-primary:focus, .brand-primary:hover {
  color: var(--primary);
}

.brand-light, .brand-light:focus, .brand-light:hover {
  color: var(--white);
}

.brand-dark, .brand-dark:focus, .brand-dark:hover {
  color: var(--dark);
}

/*------------------------------------
  Breadcrumb
------------------------------------*/

.breadcrumb-white {
  background-color: transparent;
}

.breadcrumb-white .breadcrumb-item {
  color: var(--white);
}

.breadcrumb-white .breadcrumb-item+.breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.7);
}

.breadcrumb-white .breadcrumb-link {
  color: rgba(255, 255, 255, 0.7);
}

.breadcrumb-no-gutter {
  padding: 0;
}

/*------------------------------------
  Card
------------------------------------*/

.card-2-count {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.card-3-count {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}

.card-4-count {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}

.card-1-gap {
  -webkit-column-gap: 1.5rem;
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.card-2-gap {
  -webkit-column-gap: 2rem;
  -moz-column-gap: 2rem;
  column-gap: 2rem;
}

.card-3-gap {
  -webkit-column-gap: 3rem;
  -moz-column-gap: 3rem;
  column-gap: 3rem;
}

.card-4-gap {
  -webkit-column-gap: 4rem;
  -moz-column-gap: 4rem;
  column-gap: 4rem;
}

.card-5-gap {
  -webkit-column-gap: 5rem;
  -moz-column-gap: 5rem;
  column-gap: 5rem;
}

.card-columns {
  orphans: 1;
  widows: 1;
}

.card-columns .card {
  display: inline-block;
  width: 100%;
}

@media (min-width: 576px) {
  .card-sm-2-count {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .card-sm-3-count {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .card-sm-4-count {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .card-sm-1-gap {
    -webkit-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .card-sm-2-gap {
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
  .card-sm-3-gap {
    -webkit-column-gap: 3rem;
    -moz-column-gap: 3rem;
    column-gap: 3rem;
  }
  .card-sm-4-gap {
    -webkit-column-gap: 4rem;
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }
  .card-sm-5-gap {
    -webkit-column-gap: 5rem;
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }
  .card-sm-columns {
    orphans: 1;
    widows: 1;
  }
  .card-sm-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .card-md-2-count {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .card-md-3-count {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .card-md-4-count {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .card-md-1-gap {
    -webkit-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .card-md-2-gap {
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
  .card-md-3-gap {
    -webkit-column-gap: 3rem;
    -moz-column-gap: 3rem;
    column-gap: 3rem;
  }
  .card-md-4-gap {
    -webkit-column-gap: 4rem;
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }
  .card-md-5-gap {
    -webkit-column-gap: 5rem;
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }
  .card-md-columns {
    orphans: 1;
    widows: 1;
  }
  .card-md-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .card-lg-2-count {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .card-lg-3-count {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .card-lg-4-count {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .card-lg-1-gap {
    -webkit-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .card-lg-2-gap {
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
  .card-lg-3-gap {
    -webkit-column-gap: 3rem;
    -moz-column-gap: 3rem;
    column-gap: 3rem;
  }
  .card-lg-4-gap {
    -webkit-column-gap: 4rem;
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }
  .card-lg-5-gap {
    -webkit-column-gap: 5rem;
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }
  .card-lg-columns {
    orphans: 1;
    widows: 1;
  }
  .card-lg-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .card-xl-2-count {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .card-xl-3-count {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .card-xl-4-count {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .card-xl-1-gap {
    -webkit-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .card-xl-2-gap {
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
  .card-xl-3-gap {
    -webkit-column-gap: 3rem;
    -moz-column-gap: 3rem;
    column-gap: 3rem;
  }
  .card-xl-4-gap {
    -webkit-column-gap: 4rem;
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }
  .card-xl-5-gap {
    -webkit-column-gap: 5rem;
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }
  .card-xl-columns {
    orphans: 1;
    widows: 1;
  }
  .card-xl-columns .card {
    display: inline-block;
    width: 100%;
  }
}

/* Background Color */

.card-bg-light {
  background-color: #f8fafd;
}

.card-bg-light:hover {
  background-color: #f4f7fc;
}

/* Frame */

.card-frame {
  border: 1px solid #e7eaf3;
  transition: 0.3s;
}

.card-frame-highlighted, .card-frame:hover {
  border-color: rgba(55, 125, 255, 0.3);
  box-shadow: 0 0 35px rgba(55, 125, 255, 0.125);
}

.card-text-dark {
  color: var(--dark);
}

.card-text-dark:hover {
  color: var(--primary);
}

/* Collapse */

.card-collapse {
  padding: 0;
  transition: 0.3s ease-in-out;
}

.card-collapse:hover {
  background-color: #f8fafd;
}

.card-collapse .card-body {
  color: var(--secondary);
}

.card-btn {
  color: var(--dark);
  text-align: left;
  white-space: inherit;
}

.card-btn-arrow {
  display: inline-block;
  color: var(--primary);
  margin-left: 1rem;
  transition: 0.3s ease-in-out;
}

.collapsed .card-btn-arrow {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/* IE image rendering fix */

.card-img-top, .card-img-bottom {
  min-height: 1px;
}

/*------------------------------------
  Card Gutters
------------------------------------*/

@media (min-width: 576px) {
  .card-sm-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-sm-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-sm-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-sm-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-sm-gutters-3 {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
  }
  .card-sm-gutters-3 .card {
    margin-right: .9375rem;
    margin-left: .9375rem;
  }
}

@media (min-width: 768px) {
  .card-md-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-md-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-md-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-md-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-md-gutters-3 {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
  }
  .card-md-gutters-3 .card {
    margin-right: .9375rem;
    margin-left: .9375rem;
  }
}

@media (min-width: 992px) {
  .card-lg-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-lg-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-lg-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-lg-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-lg-gutters-3 {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
  }
  .card-lg-gutters-3 .card {
    margin-right: .9375rem;
    margin-left: .9375rem;
  }
}

/*------------------------------------
  Card Group - Break
------------------------------------*/

@media (max-width: 767.98px) {
  .card-group-sm-break {
    display: block;
  }
  .card-group-sm-break>.card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-sm-break>.card+.card {
    border-left: 1px solid #e7eaf3;
  }
  .card-group-sm-break>.card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break>.card:first-child .card-img-top, .card-group-sm-break>.card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-sm-break>.card:first-child .card-img-bottom, .card-group-sm-break>.card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break>.card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break>.card:last-child .card-img-top, .card-group-sm-break>.card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break>.card:last-child .card-img-bottom, .card-group-sm-break>.card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}

@media (max-width: 991.98px) {
  .card-group-md-break {
    display: block;
  }
  .card-group-md-break>.card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-md-break>.card+.card {
    border-left: 1px solid #e7eaf3;
  }
  .card-group-md-break>.card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break>.card:first-child .card-img-top, .card-group-md-break>.card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-md-break>.card:first-child .card-img-bottom, .card-group-md-break>.card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break>.card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break>.card:last-child .card-img-top, .card-group-md-break>.card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break>.card:last-child .card-img-bottom, .card-group-md-break>.card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}

@media (max-width: 1199.98px) {
  .card-group-lg-break {
    display: block;
  }
  .card-group-lg-break>.card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-lg-break>.card+.card {
    border-left: 1px solid #e7eaf3;
  }
  .card-group-lg-break>.card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break>.card:first-child .card-img-top, .card-group-lg-break>.card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-lg-break>.card:first-child .card-img-bottom, .card-group-lg-break>.card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break>.card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break>.card:last-child .card-img-top, .card-group-lg-break>.card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break>.card:last-child .card-img-bottom, .card-group-lg-break>.card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}

/*------------------------------------
  Sidebar Navigation
------------------------------------*/

/* Disable content revealing on page load */

.u-sidebar[aria-labelledby] {
  opacity: 0;
}

.u-sidebar[aria-labelledby][role="tabpanel"] {
  opacity: 1;
}

.u-sidebar[aria-labelledby].u-unfold--css-animation, .u-sidebar[aria-labelledby].u-unfold--jquery-slide {
  opacity: 1;
}

.u-sidebar.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1002;
  width: 23.4375rem;
  height: 100%;
  background-color: var(--white);
  box-shadow: -13px 0 20px rgba(119, 131, 143, 0.08);
}

.u-sidebar__body, .u-sidebar__content {
  height: 100%;
}

.u-sidebar--left {
  right: auto;
  left: 0;
  box-shadow: 13px 0 20px rgba(119, 131, 143, 0.08);
}

.u-sidebar__scroller {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.u-sidebar__container {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.u-sidebar__footer {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

/*------------------------------------
  Sidebar Account
------------------------------------*/

.u-sidebar--account__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 10.3125rem;
}

.u-sidebar__content--account {
  padding: 1.5rem 3rem;
}

/* Holder */

.u-sidebar--account__holder {
  background-color: #f8fafd;
  padding: 1.5rem 3rem;
}

.u-sidebar--account__holder-img {
  width: 3rem;
  border-radius: 0.3125rem;
}

.u-sidebar--account__holder-text {
  display: block;
  font-size: .75rem;
  color: var(--gray);
}

/* Toggle */

.u-sidebar--account__toggle-bg {
  position: relative;
  top: 1px;
  padding: .25rem .25rem .25rem .75rem;
  background-color: rgba(55, 125, 255, 0.1);
  border-radius: 6.1875rem;
}

@media (max-width: 767.98px) {
  .u-sidebar--account__toggle-bg {
    padding-left: .25rem;
  }
}

.u-sidebar--account__toggle-bg:hover .u-sidebar--account__toggle-text {
  color: var(--primary);
}

.u-sidebar--account__toggle-text {
  color: var(--dark);
  font-size: .75rem;
  vertical-align: middle;
  margin-right: .3125rem;
}

@media (max-width: 767.98px) {
  .u-sidebar--account__toggle-text {
    display: none;
  }
}

.u-sidebar--account__toggle-img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

/* List */

.u-sidebar--account__list {
  margin-bottom: 0;
}

.u-sidebar--account__list-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.u-sidebar--account__list-link {
  display: block;
  font-size: .875rem;
  color: #97a4af;
  border-radius: 0.3125rem;
  padding: .625rem;
  transition: 0.2s ease-in-out;
}

.u-sidebar--account__list-link.active, .u-sidebar--account__list-link:hover {
  color: var(--primary);
  background-color: rgba(55, 125, 255, 0.1);
}

.u-sidebar--account__list-link.active .u-sidebar--account__list-icon, .u-sidebar--account__list-link:hover .u-sidebar--account__list-icon {
  color: var(--primary);
}

.u-sidebar--account__list-icon {
  display: inline-block;
  text-align: center;
  width: 1.125rem;
  font-size: .8125rem;
  color: var(--gray);
  transition: 0.2s ease-in-out;
}

.u-sidebar--account__list-divider {
  border-top: 1px solid #e4ecf7;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* Footer Links */

.u-sidebar__footer--account {
  padding: 0 3rem 1.5rem;
}

.u-sidebar__footer--account__text {
  position: relative;
  z-index: 1;
  font-size: .875rem;
  color: rgba(255, 255, 255, 0.7);
}

.u-sidebar__footer--account__text:hover {
  color: var(--white);
}

/*------------------------------------
  Sidebar Shopping Cart
------------------------------------*/

.u-sidebar__cart-footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 13.4375rem;
}

/*------------------------------------
  Clients
------------------------------------*/

.u-clients {
  width: 100%;
  max-width: 8rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/*------------------------------------
  Close
------------------------------------*/

.close-light {
  color: rgba(255, 255, 255, 0.7);
}

.close-light:not(:disabled):not(.disabled):hover, .close-light:not(:disabled):not(.disabled):focus {
  color: var(--white);
}

/*------------------------------------
  Divider
------------------------------------*/

hr.divider{
  width: 100%;
  height: 1px;
}

.u-divider {
  position: relative;
  display: inline-block;
  font-size: 1rem;
}

.u-divider::before, .u-divider::after {
  position: absolute;
  top: 50%;
  content: "";
}

.u-divider::before {
  right: 100%;
  background-image: linear-gradient(45deg, transparent 0%, #bdc5d1 100%);
  background-repeat: repeat-x;
}

.u-divider::after {
  left: 100%;
  background-image: linear-gradient(45deg, #bdc5d1 0%, transparent 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Divider Sizes
------------------------------------*/

.u-divider::before, .u-divider::after {
  width: 5rem;
  height: 0.0625rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.u-divider::before {
  margin-right: .625rem;
}

.u-divider::after {
  margin-left: .625rem;
}

@media (max-width: 767.98px) {
  .u-divider::before, .u-divider::after {
    width: 4rem;
  }
}

.u-divider--xs {
  font-size: 0.75rem;
}

.u-divider--xs::before, .u-divider--xs::after {
  width: 2rem;
  height: 0.0625rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*------------------------------------
  Divider Styles
------------------------------------*/

/* Text */

.u-divider--text {
  color: var(--gray);
}

/*------------------------------------
  Vertical Divider
------------------------------------*/

.u-ver-divider {
  position: relative;
}

.u-ver-divider::after {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid #e7eaf3;
  content: "";
}

/* Breakpoint */

@media (max-width: 575.98px) {
  .u-ver-divider--none-sm::after {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-ver-divider--none-md::after {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-ver-divider--none-lg::after {
    display: none;
  }
}

/*------------------------------------
  Divider Sizes
------------------------------------*/

/* Extra Small */

.u-ver-divider--xs::after {
  top: 50%;
  height: 1rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Small */

.u-ver-divider--sm::after {
  top: 50%;
  height: 2rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Large */

.u-ver-divider--lg::after {
  top: 50%;
  height: 3rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Extra Large */

.u-ver-divider--xl::after {
  top: 50%;
  height: 4rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*------------------------------------
  Dropdown
------------------------------------*/

/* Disable content revealing on page load */

.dropdown-unfold[aria-labelledby] {
  opacity: 0;
}

.dropdown-unfold[aria-labelledby][role="tabpanel"] {
  display: block;
  opacity: 1;
}

.dropdown-unfold[aria-labelledby].u-unfold--css-animation, .dropdown-unfold[aria-labelledby].u-unfold--jquery-slide {
  display: block;
  opacity: 1;
}

.dropdown-unfold.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.dropdown-unfold.show {
  display: block;
  opacity: 1;
}

/* Menu */

.dropdown-menu {
  margin-top: .5rem;
  font-size: 0.875rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-shadow: 0 5px 9px rgba(55, 125, 255, 0.075), 0 5px 9px rgba(119, 131, 143, 0.075);
}

/* Nav Link */

.dropdown-nav-link {
  color: var(--secondary);
  font-weight: 300;
  font-size: 0.875rem;
}

.dropdown-nav-link:hover {
  color: var(--dark);
}

/* Item */

.dropdown-item {
  font-size: 0.875rem;
}

.dropdown-item:hover {
  color: var(--primary);
}

.dropdown-item.active {
  color: var(--primary);
}

.dropdown-item-icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 0.8125rem;
  min-width: 1rem;
  max-width: 1rem;
  margin-right: .5rem;
  height: 12px;
}

/* Toggle */

.dropdown-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-size: 80%;
  font-weight: 900;
  content: "\f078";
  margin-left: .5rem;
}

.dropdown-toggle-collapse::after {
  transition: 0.3s;
}

.dropdown-toggle-collapse[aria-expanded="true"]::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

/* Dropdown Positions */

.dropdown-menu-top {
  top: 0;
}

.dropdown-menu-bottom {
  top: auto;
  bottom: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-top {
    top: 0;
  }
  .dropdown-menu-sm-bottom {
    top: auto;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-top {
    top: 0;
  }
  .dropdown-menu-md-bottom {
    top: auto;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-top {
    top: 0;
  }
  .dropdown-menu-lg-bottom {
    top: auto;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-top {
    top: 0;
  }
  .dropdown-menu-xl-bottom {
    top: auto;
    bottom: 0;
  }
}

/*------------------------------------
  Dropdown Card
------------------------------------*/

.dropdown-card {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .dropdown-card {
    min-width: 25rem;
  }
}

/*------------------------------------
  Dropdown Sizes
------------------------------------*/

@media (min-width: 576px) {
  .dropdown-menu-size-md {
    width: 440px;
  }
  .dropdown-menu-size-lg {
    width: 340px;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-size-lg {
    width: 440px;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-size-lg {
    width: 740px;
  }
}

/*------------------------------------
  Form
------------------------------------*/

.custom-select:focus, .form-control:focus {
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
  border-color: rgba(55, 125, 255, 0.5);
}

.form-label {
  display: block;
  text-transform: uppercase;
  font-size: 80%;
  font-weight: 500;
}

/* Form Borderless */

.input-group-borderless .form-control, .input-group-borderless .input-group-text {
  border: none;
}

.input-group-borderless .form-control:focus, .input-group-borderless .input-group-text:focus {
  box-shadow: none;
}

/*------------------------------------
  Form Pill
------------------------------------*/

.input-group-pill {
  border-radius: 6.1875rem;
}

.input-group-pill>.form-control:first-child {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.input-group-pill>.form-control:last-child {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.input-group-pill .input-group-prepend>.input-group-text, .input-group-pill .input-group-prepend>.btn {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.input-group-pill .input-group-append>.input-group-text, .input-group-pill .input-group-append>.btn {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

/*------------------------------------
  Form Sizes
------------------------------------*/

/* Default Size */

.input-group.input-group-pill>.form-control:first-child {
  padding-left: 1.5rem;
}

.input-group.input-group-pill .input-group-prepend>.input-group-text, .input-group.input-group-pill .input-group-prepend>.btn {
  padding-left: 1.25rem;
}

.input-group.input-group-pill .input-group-append>.input-group-text, .input-group.input-group-pill .input-group-append>.btn {
  padding-right: 1.25rem;
}

.input-group .input-group-prepend>.input-group-text, .input-group .input-group-append>.input-group-text {
  font-size: 0.875rem;
}

/* Extra Small Size */

.input-group-xs.input-group-pill>.form-control:first-child {
  padding-left: 1.375rem;
}

.input-group-xs.input-group-pill .input-group-prepend>.input-group-text, .input-group-xs.input-group-pill .input-group-prepend>.btn {
  padding-left: 1.25rem;
}

.input-group-xs.input-group-pill .input-group-append>.input-group-text, .input-group-xs.input-group-pill .input-group-append>.btn {
  padding-right: 1.25rem;
}

.input-group-xs .input-group-prepend>.input-group-text, .input-group-xs .input-group-append>.input-group-text {
  font-size: 0.625rem;
}

.form-control-xs {
  height: calc(1.925rem + 1px);
  padding: 0.4375rem 0.9375rem;
  font-size: 0.75rem;
  line-height: 1.4;
}

/* Small Size */

.input-group-sm.input-group-pill>.form-control:first-child {
  padding-left: 1rem;
}

.input-group-sm.input-group-pill .input-group-prepend>.input-group-text, .input-group-sm.input-group-pill .input-group-prepend>.btn {
  padding-left: 1rem;
}

.input-group-sm.input-group-pill .input-group-append>.input-group-text, .input-group-sm.input-group-pill .input-group-append>.btn {
  padding-right: 1rem;
}

.input-group-sm .input-group-prepend>.input-group-text, .input-group-sm .input-group-append>.input-group-text {
  font-size: 0.75rem;
}

/* Large Size */

.input-group-lg.input-group-pill>.form-control:first-child {
  padding-left: 2rem;
}

.input-group-lg.input-group-pill .input-group-prepend>.input-group-text, .input-group-lg.input-group-pill .input-group-prepend>.btn {
  padding-left: 1.375rem;
}

.input-group-lg.input-group-pill .input-group-append>.input-group-text, .input-group-lg.input-group-pill .input-group-append>.btn {
  padding-right: 1.5rem;
}

.input-group-lg .input-group-prepend>.input-group-text, .input-group-lg .input-group-append>.input-group-text {
  font-size: 1rem;
}

/*------------------------------------
  Focus States
------------------------------------*/

.u-focus-state .form-label+.form-control, .u-focus-state .custom-select, .u-focus-state .input-group, .u-focus-state .form-control:first-child:last-child {
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}

.u-focus-state .input-group-text {
  color: var(--primary);
}

.u-focus-state:focus, .u-focus-state *:focus, .u-focus-state .input-group-text, .u-focus-state .form-control {
  border-color: rgba(55, 125, 255, 0.5);
}

.u-focus-state .form-control {
  box-shadow: none;
}

/*------------------------------------
  Success State
------------------------------------*/

.u-has-success .form-label+.form-control, .u-has-success .custom-select, .u-has-success .input-group, .u-has-success .form-control:first-child:last-child {
  box-shadow: 0 0 10px rgba(0, 201, 167, 0.1);
}

.u-has-success .input-group-text {
  color: var(--success);
}

.u-has-success:focus, .u-has-success *:focus, .u-has-success .input-group-text, .u-has-success .form-control {
  border-color: rgba(0, 201, 167, 0.5);
}

/*------------------------------------
  Error State
------------------------------------*/

.u-has-error .form-label+.form-control, .u-has-error .custom-select, .u-has-error .input-group, .u-has-error .form-control:first-child:last-child {
  box-shadow: 0 0 10px rgba(222, 68, 55, 0.1);
}

.u-has-error .input-group-text {
  color: var(--danger);
}

.u-has-error:focus, .u-has-error *:focus, .u-has-error .input-group-text, .u-has-error .form-control {
  border-color: rgba(222, 68, 55, 0.5);
}

/*------------------------------------
  Bookmark Checkbox
------------------------------------*/

.bookmark-checkbox {
  position: relative;
  display: block;
  line-height: 0;
}

.bookmark-checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.bookmark-checkbox-label {
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 0.8175rem;
  color: var(--secondary);
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 0;
  transition: 0.3s;
}

.bookmark-checkbox-label::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  font-family: "Font Awesome 5 Free";
}

.bookmark-checkbox-input:checked~.bookmark-checkbox-label {
  color: var(--white);
  background-color: var(--primary);
  box-shadow: 0 3px 6px 0 rgba(55, 125, 255, 0.25);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*------------------------------------
  Checkbox Outline
------------------------------------*/

.checkbox-outline {
  padding-left: 0;
  margin-right: 0;
}

.checkbox-outline__label {
  border: 1px solid #e7eaf3;
  cursor: pointer;
}

.checkbox-outline__input:checked~.checkbox-outline__label {
  border-color: var(--primary);
}

/*------------------------------------
  Checkbox Icon
------------------------------------*/

.checkbox-icon__label::after {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: none;
  font-family: "Font Awesome 5 Free";
  content: "\f058";
  font-weight: 900;
  color: var(--primary);
  font-size: 1.5rem;
}

.checkbox-icon__input:checked~.checkbox-icon__label::after {
  display: block;
  border-color: var(--primary);
}

/*------------------------------------
  Checkbox Switch
------------------------------------*/

.custom-switch {
  padding-left: 3.25rem;
}

.custom-switch .custom-control-label::before {
  left: -3.25rem;
  height: 1.5rem;
  border: none;
  background-color: #e7eaf3;
  border-radius: 6.1875rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.625rem);
  left: calc(-2.875rem);
  background-color: var(--white);
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  -webkit-transform: translateX(1rem);
  transform: translateX(1rem);
}

/*------------------------------------
  Custom Checkbox
------------------------------------*/

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  border-color: rgba(55, 125, 255, 0);
}

/*------------------------------------
  File Attachment Button
------------------------------------*/

.file-attachment-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.file-attachment-btn__label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

/*------------------------------------
  File Attachment Input
------------------------------------*/

.file-attachment-input {
  position: relative;
  display: block;
  background-color: #f8fafd;
  border: 2px dashed #e7eaf3;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0.3125rem;
  padding: 4rem;
  margin-bottom: 0;
}

.file-attachment-input__label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

.file-attachment-input:hover {
  background-color: #f5f8fc;
}

/*------------------------------------
  File Attachment Link
------------------------------------*/

.file-attachment-link {
  position: relative;
  top: .4375rem;
  overflow: hidden;
}

.file-attachment-link input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.file-attachment-link__label {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}

.file-attachment-link:hover .u-file-attachment-link__label {
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Google Map Interactive
------------------------------------*/

.u-gmap-interactive {
  height: 15.625rem;
  border: 4px solid var(--white);
  border-radius: 0.3125rem;
  box-shadow: 0 0 45px rgba(140, 152, 164, 0.125);
}

.u-gmap-interactive .gm-style>div:first-child>div+div>div:last-child>div>div:first-child>div:first-child {
  display: none;
}

.u-gmap-interactive .gm-style-iw {
  min-width: 11.0625rem;
  background-color: var(--white);
  text-align: center;
  overflow: inherit;
  box-shadow: 0 0 45px rgba(140, 152, 164, 0.4);
  border-radius: 0.3125rem;
  padding-top: 1.5rem;
  padding-bottom: .5rem;
}

.u-gmap-interactive .gm-style-iw::before {
  position: absolute;
  bottom: -.75rem;
  left: 50%;
  width: 1rem;
  height: .875rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.1 14.5'%3E %3Cpath fill='var(--white)' d='M9.8,13.5L15.9,3c0.8-1.3-0.2-3-1.7-3H2C0.5,0-0.5,1.7,0.3,3l6.1,10.5C7.1,14.8,9,14.8,9.8,13.5z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.u-gmap-interactive .gm-style-iw img {
  max-width: 100%;
}

.u-gmap-interactive__info {
  padding-right: .5rem;
  padding-bottom: .5rem;
  padding-left: .5rem;
}

/*------------------------------------
  Go To
------------------------------------*/

.u-go-to {
  display: none;
  position: relative;
  z-index: 100;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--primary);
  color: var(--white);
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  opacity: 0.5;
  transition: 0.3s ease-out;
}

.u-go-to__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.u-go-to:hover, .u-go-to:focus:hover {
  color: var(--white);
  opacity: 1;
}

/*------------------------------------
  Got to Section - Vertical Arrow
------------------------------------*/

.u-go-to-ver-arrow {
  display: inline-block;
  color: var(--primary);
  background-color: rgba(55, 125, 255, 0.1);
  padding: 2rem 1rem;
  border-radius: 6.1875rem;
}

.u-go-to-ver-arrow:hover .u-go-to-ver-arrow__inner {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}

.u-go-to-ver-arrow__inner {
  transition: 0.2s ease-in-out;
}

/*------------------------------------
  Got to Section - Modern
------------------------------------*/

.u-go-to-modern {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.125rem;
  color: var(--primary);
  background-color: var(--white);
  border-radius: 50%;
  box-shadow: 0 0 1.25rem rgba(140, 152, 164, 0.2);
}

.u-go-to-modern .u-go-to-modern__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*------------------------------------
  Got to Section - Wave
------------------------------------*/

.u-go-to-wave {
  width: 13.125rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575.98px) {
  .u-go-to-wave {
    width: 10rem;
  }
}

.u-go-to-wave__icon {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*------------------------------------
  Hamburgers
------------------------------------*/

.u-hamburger {
  font-size: inherit;
  line-height: 0;
  padding: 0.25rem;
}

.u-hamburger:hover .u-hamburger__inner, .u-hamburger:hover .u-hamburger__inner::before, .u-hamburger:hover .u-hamburger__inner::after {
  background-color: var(--primary);
}

.u-hamburger__box {
  width: 1.5625rem;
  height: 0.875rem;
  display: inline-block;
  position: relative;
}

.u-hamburger__inner {
  display: block;
  top: 0.0625rem;
  margin: top, -0.0625rem;
}

.u-hamburger__inner, .u-hamburger__inner::before, .u-hamburger__inner::after {
  width: 1.5625rem;
  height: 0.125rem;
  background-color: var(--gray);
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.u-hamburger__inner::before, .u-hamburger__inner::after {
  content: "";
  display: block;
}

.u-hamburger__inner::before {
  top: 0.375rem;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.u-hamburger__inner::after {
  top: 0.75rem;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner, .u-hamburger .is-active .u-hamburger__inner {
  -webkit-transform: translate3d(0, 0.375rem, 0) rotate(45deg);
  transform: translate3d(0, 0.375rem, 0) rotate(45deg);
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::before, .u-hamburger .is-active .u-hamburger__inner::before {
  -webkit-transform: rotate(-45deg) translate3d(-0.22321rem, -0.25rem, 0);
  transform: rotate(-45deg) translate3d(-0.22321rem, -0.25rem, 0);
  opacity: 0;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::after, .u-hamburger .is-active .u-hamburger__inner::after {
  -webkit-transform: translate3d(0, -0.75rem, 0) rotate(-90deg);
  transform: translate3d(0, -0.75rem, 0) rotate(-90deg);
}

.u-hamburger--white .u-hamburger__inner, .u-hamburger--white .u-hamburger__inner::before, .u-hamburger--white .u-hamburger__inner::after {
  background-color: rgba(255, 255, 255, 0.8);
}

.u-hamburger--white:hover .u-hamburger__inner, .u-hamburger--white:hover .u-hamburger__inner::before, .u-hamburger--white:hover .u-hamburger__inner::after {
  background-color: var(--white);
}

.js-header-fix-moment .u-hamburger--white .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner::before, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner::after {
  background-color: var(--gray);
}

.js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::before, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::after {
  background-color: var(--primary);
}

/*------------------------------------
  Hero v1
------------------------------------*/

.u-hero-v1 {
  position: relative;
}

.u-hero-v1__main {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 992px) {
  .u-hero-v1__main {
    height: 70vh;
  }
}

.u-hero-v1__main::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(38, 60, 100, 0.3);
  content: "";
}

.u-hero-v1__secondary {
  position: relative;
}

@media (min-width: 992px) {
  .u-hero-v1__secondary {
    height: 30vh;
  }
}

.u-hero-v1__last {
  position: absolute;
  bottom: 0;
  left: 58.333333%;
}

.u-hero-v1__last-inner {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 30%;
  padding-left: 3rem;
  padding-right: 3rem;
}

.u-hero-v1__last-next {
  color: #0049d0;
}

.u-hero-v1__last-prev {
  color: #962319;
}

/*------------------------------------
  Indicator Dots
------------------------------------*/

.u-indicator-dots {
  position: relative;
}

@media (min-width: 768px) {
  .u-indicator-dots::after {
    position: absolute;
    right: -2.1875rem;
    top: 50%;
    width: 2.4375rem;
    height: .75rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 16'%3E %3Ccircle fill='%23e7eaf3' cx='27.7' cy='8.1' r='7.7'/%3E %3Ccircle fill='%23e7eaf3' cx='5' cy='8.1' r='5'/%3E %3Ccircle fill='%23e7eaf3' cx='50' cy='8.1' r='5'/%3E %3C/svg%3E");
    background-repeat: no-repeat;
    content: "";
    margin-top: -.375rem;
  }
}

/*------------------------------------
  Indicator - Vertical Dashed
------------------------------------*/

.u-indicator-vertical-dashed-item:not(:last-child) {
  position: relative;
  margin-bottom: 1rem;
}

.u-indicator-vertical-dashed-item:not(:last-child)::after {
  position: absolute;
  top: 1.625rem;
  bottom: -.8125rem;
  left: .625rem;
  border-left: 2px dashed #e7eaf3;
  content: "";
}

/*------------------------------------
  Indicator Vertical Line
------------------------------------*/

.u-indicator-vertical-line {
  position: relative;
}

@media (min-width: 768px) {
  .u-indicator-vertical-line::after {
    position: absolute;
    right: -25px;
    top: 0;
    height: 4rem;
    border-left: 1px solid rgba(255, 255, 255, 0.45);
    content: "";
    transform-origin: 0 0;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}

/*------------------------------------
  Indicator Steps
------------------------------------*/

.u-indicator-steps {
  position: relative;
  padding-left: 2.5rem;
}

.u-indicator-steps::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -.25rem;
  width: 2rem;
  height: .0625rem;
  border-top: 1px solid #e7eaf3;
  margin: auto auto auto 0;
  content: "";
}

.u-indicator-steps::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -.3125rem;
  width: .0625rem;
  height: 100%;
  border-right: 1px solid #e7eaf3;
  margin: auto auto auto 0;
  content: "";
}

.u-indicator-steps:first-child::after {
  height: 50%;
  top: auto;
}

.u-indicator-steps:last-child::after {
  height: 50%;
  bottom: auto;
}

.u-indicator-steps__inner {
  min-width: 3rem;
}

/*------------------------------------
  Media Player
------------------------------------*/

.u-media-player {
  color: var(--dark);
  transition: all 0.2s ease-in-out;
}

.u-media-player__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 3.75rem;
  height: 3.75rem;
  font-size: 0.8125rem;
  border-radius: 50%;
  color: var(--dark);
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
}

.u-media-player__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-left: .125rem;
}

.u-media-player__icon--box-shadow {
  box-shadow: 0 0 2.5rem rgba(140, 152, 164, 0.3);
}

.u-media-player:hover .u-media-player__icon, .u-media-player:focus .u-media-player__icon {
  color: var(--primary);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.u-media-player:hover .u-media-player__icon--primary, .u-media-player:focus .u-media-player__icon--primary {
  background-color: var(--primary);
}

.u-media-player:hover .u-media-player__icon--success, .u-media-player:focus .u-media-player__icon--success {
  background-color: var(--success);
}

/* Colors */

.u-media-player:hover .u-media-player__icon--primary, .u-media-player:hover .u-media-player__icon--success, .u-media-player:focus .u-media-player__icon--primary, .u-media-player:focus .u-media-player__icon--success {
  color: var(--white);
}

.u-media-player__icon--primary {
  color: var(--primary);
  background-color: rgba(55, 125, 255, 0.1);
}

.u-media-player__icon--success {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
}

/* Large Size */

.u-media-player__icon--lg {
  width: 5rem;
  height: 5rem;
  font-size: 1rem;
}

/* Extra Large Size */

.u-media-player__icon--xl {
  width: 6.25rem;
  height: 6.25rem;
  font-size: 1rem;
}

/* Positions */

.u-media-player--centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .u-media-player--left-minus-50x-top-50x-lg {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

/*------------------------------------
  Media Viewer
------------------------------------*/

.u-media-viewer {
  position: relative;
  display: block;
}

.u-media-viewer:hover .u-media-viewer__icon {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.u-media-viewer__container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.u-media-viewer__icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem;
  color: var(--white);
  background-color: var(--primary);
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  transition: 0.3s ease-in-out;
}

.u-media-viewer__icon:hover, .u-media-viewer__icon:focus {
  color: var(--white);
}

.u-media-viewer__icon--active {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.u-media-viewer__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*------------------------------------
  Video Player
------------------------------------*/

.u-video-player {
  position: relative;
  background-color: #000;
}

.u-video-player__preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 1;
  -o-object-fit: cover;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.u-video-player__played .u-video-player__preview {
  opacity: 0;
  pointer-events: none;
}

.u-video-player__btn {
  z-index: 3;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.u-video-player__played .u-video-player__btn {
  -webkit-animation: videoPlayerButton 0.4s ease-in-out forwards;
  animation: videoPlayerButton 0.4s ease-in-out forwards;
  pointer-events: none;
}

.u-video-player__btn:hover .u-video-player__icon, .u-video-player__btn:focus .u-video-player__icon {
  color: var(--primary);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.u-video-player__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.8125rem;
  border-radius: 50%;
  color: var(--dark);
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
}

.u-video-player__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-left: .125rem;
}

/* Large Size */

.u-video-player__icon--lg {
  width: 5rem;
  height: 5rem;
  font-size: 1rem;
}

/* Positions */

.u-video-player__centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
    transform: translate(-50%, -50%) scale(1.3);
  }
}

@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
    transform: translate(-50%, -50%) scale(1.3);
  }
}

/*------------------------------------
  Mockup Browser v1
------------------------------------*/

.u-browser-v1 {
  position: relative;
  max-width: 53.125rem;
}

.u-browser-v1__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 85%;
  border-radius: 0.5rem;
  box-shadow: 0 0.625rem 2.5rem 0.625rem rgba(140, 152, 164, 0.175);
}

/*------------------------------------
  Mockup Devices v1
------------------------------------*/

.u-devices-v1 {
  position: absolute;
  top: 3rem;
  left: 50%;
  width: 40.625rem;
  -webkit-transform: rotate(-17deg);
  transform: rotate(-17deg);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.u-devices-v1__tablet-svg, .u-devices-v1__phone-svg {
  border-radius: 2.125rem;
  box-shadow: 0 0 10px 6px rgba(30, 32, 34, 0.05);
}

.u-devices-v1__tablet {
  width: 26.875rem;
  height: 34.3125rem;
}

.u-devices-v1__phone {
  width: 13.75rem;
  height: 27.5rem;
  margin-right: 1.25rem;
}

/*------------------------------------
  Devices v2
------------------------------------*/

.u-devices-v2 {
  position: relative;
  overflow: hidden;
}

.u-devices-v2__tablet {
  position: absolute;
  left: 0;
  top: 0%;
  z-index: 1;
  width: 34.375rem;
  height: 50.875rem;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
  -webkit-transform-origin: 90% -20%;
  transform-origin: 90% -20%;
}

@media (max-width: 991.98px) {
  .u-devices-v2__tablet {
    -webkit-transform-origin: 100% -40%;
    transform-origin: 100% -40%;
  }
}

.u-devices-v2__tablet-svg {
  border-radius: 1.85rem;
  box-shadow: 0 0 0.75rem 0.5rem rgba(30, 32, 34, 0.1);
}

.u-devices-v2__phone {
  position: absolute;
  right: 0;
  bottom: -25%;
  z-index: 1;
  width: 19.8125rem;
  height: 41.5625rem;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.u-devices-v2__phone--left-position {
  position: absolute;
  right: auto;
  left: 0;
  -webkit-transform-origin: 100% 40%;
  transform-origin: 100% 40%;
}

@media (max-width: 1199.98px) {
  .u-devices-v2__phone--left-position {
    -webkit-transform-origin: 100% 65%;
    transform-origin: 100% 65%;
  }
}

.u-devices-v2__phone-svg {
  border-radius: 3rem;
  box-shadow: 0 0 0.75rem 0.5rem rgba(30, 32, 34, 0.1);
}

/*------------------------------------
  Devices v3
------------------------------------*/

.u-devices-v3 {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 0;
  width: 40%;
  border-radius: 4.0625rem;
  box-shadow: 10px 15px 55px 15px rgba(140, 152, 164, 0.1);
}

/*------------------------------------
  Mockup Devices v4
------------------------------------*/

.u-devices-v4 {
  -webkit-transform: rotate(-25deg);
  transform: rotate(-25deg);
}

.u-devices-v4__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 700px;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.u-devices-v4__laptop {
  width: 35.9375rem;
  height: 20.75rem;
}

.u-devices-v4__tablet-svg, .u-devices-v4__tablet-ver-svg {
  border-radius: 1.375rem;
  box-shadow: 0 0 0.625rem 0.375rem rgba(30, 32, 34, 0.05);
}

.u-devices-v4__tablet {
  width: 17.1875rem;
  height: 25.4375rem;
  margin-left: 1.5625rem;
}

.u-devices-v4__tablet-ver {
  width: 25rem;
  height: 16.875rem;
}

.u-devices-v4__phone {
  width: 6.25rem;
  height: 13.0625rem;
  margin-left: 1.5625rem;
}

.u-devices-v4__phone-svg {
  border-radius: 1rem;
  box-shadow: 0 0 0.625rem 0.375rem rgba(30, 32, 34, 0.05);
}

/*------------------------------------
  Modal Window
------------------------------------*/

.u-modal-window {
  display: none;
  max-height: 85vh;
}

/*------------------------------------
  Modal Window - Facebook
------------------------------------*/

.u-modal--facebook {
  width: 31.25rem;
  border-radius: 0.3125rem;
}

.u-modal--facebook__body {
  position: relative;
  background-color: #3b5998;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.u-modal--facebook__body::after {
  position: absolute;
  left: 5rem;
  top: 100%;
  display: block;
  border-style: solid;
  border-width: 1.125rem 1.375rem 0 0;
  border-color: #3b5998 transparent transparent transparent;
  content: "";
}

.u-modal--facebook__space {
  padding: 2rem;
}

.u-modal--facebook__content {
  background-color: var(--white);
  padding: 2rem;
}

.u-modal--facebook__navbar {
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.u-modal--facebook__navbar-logo {
  width: 7.5rem;
}

.u-modal--facebook__text {
  font-weight: 300;
  color: var(--white);
  margin-bottom: 0;
}

/*------------------------------------
  Page Preloader
------------------------------------*/

.page-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  background-color: var(--white);
}

.page-preloader__content-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

/*------------------------------------
  Pagination
------------------------------------*/

.page-link {
  cursor: pointer;
  text-align: center;
  min-width: 2.25rem;
}

.page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.page-item .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.page-item .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

/*------------------------------------
  Navs
------------------------------------*/

.nav-classic {
  border-bottom: 1px solid #e7eaf3;
}

.nav-classic .nav-link {
  color: var(--secondary);
  border-bottom: 3px solid transparent;
  border-radius: 0;
  padding: 1rem 1.5rem;
  transition: 0.3s;
}

.nav-classic .nav-link:hover {
  color: var(--primary);
}

.nav-classic .nav-link.active {
  color: var(--primary);
  border-bottom-color: var(--primary);
}

.nav-borderless {
  border-color: transparent;
}

.nav-rounded {
  border-radius: 0.3125rem;
}

.nav-rounded .nav-item:first-child .nav-link {
  border-bottom-left-radius: 0.3125rem;
}

.nav-rounded .nav-item:last-child .nav-link {
  border-bottom-right-radius: 0.3125rem;
}

.nav-shadow {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}

.nav .nav-item:not(:first-child) {
  margin-left: .25rem;
}

.nav .nav-item:not(:last-child) {
  margin-right: .25rem;
}

/* White Color */

.nav-white .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.nav-white .nav-link.active {
  color: var(--primary);
  background-color: var(--white);
}

.nav-white .nav-link:not(.active):hover {
  color: var(--white);
}

/* White Border Color */

.nav-border-white .nav-link {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 2px solid transparent;
}

.nav-border-white .nav-link.active {
  color: var(--white);
  border-bottom-color: var(--white);
}

.nav-border-white .nav-link:not(.active):hover {
  color: var(--white);
}

/* Box */

.nav-box .nav-link {
  color: var(--dark);
  border: 1px solid transparent;
  border-radius: 0.3125rem;
}

.nav-box .nav-link.active {
  color: var(--primary);
  background-color: var(--white);
  border-color: #e7eaf3;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
}

.nav-box .nav-link:hover {
  border-color: #e7eaf3;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
}

/* Nav Steps */

.nav-icon .nav-item {
  color: var(--secondary);
}

.nav-icon .nav-item.active {
  color: var(--primary);
}

.nav-icon .nav-item.active .nav-icon-action {
  color: var(--white);
  background-color: var(--primary);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nav-icon-action {
  position: relative;
  display: block;
  text-align: center;
  z-index: 1;
  line-height: .7;
  width: 4rem;
  height: 4rem;
  font-size: 1.375rem;
  color: var(--secondary);
  background-color: #f1f2f3;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: .5rem;
  transition: .3s ease-in-out;
  transform: scale(.8) !important;
}

.nav-icon-action-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*------------------------------------
  Blog Thumb Minimal
------------------------------------*/

.u-blog-thumb-minimal {
  padding: .5rem;
  transition: 0.3s ease-in-out;
}

.u-blog-thumb-minimal, .u-blog-thumb-minimal__img {
  border-radius: 0.3125rem;
}

.u-blog-thumb-minimal:hover {
  background-color: #196eff;
}

.u-blog-thumb-minimal__img-wrapper {
  width: 5rem;
}

/*------------------------------------
  Vertical Progress Bar
------------------------------------*/

.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #f8fafd;
  height: 12.5rem;
}

/*------------------------------------
  Paging Modern
------------------------------------*/

.u-paging-modern .u-paging-modern__arrow-icon-prev, .u-paging-modern .u-paging-modern__arrow-icon-next {
  transition: 0.3s;
}

.u-paging-modern .u-paging-modern__arrow-icon-prev {
  margin-right: 1rem;
}

.u-paging-modern .u-paging-modern__arrow-icon-next {
  margin-left: 1rem;
}

.u-paging-modern:hover .u-paging-modern__arrow-icon-prev {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}

.u-paging-modern:hover .u-paging-modern__arrow-icon-next {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}

.u-paging-modern-view-all {
  color: var(--white);
  text-align: center;
  background-color: var(--warning);
}

.u-paging-modern-view-all__icon {
  display: block;
  font-size: 2rem;
  margin-bottom: .5rem;
  transition: 0.3s;
}

.u-paging-modern-view-all:hover {
  color: var(--white);
}

.u-paging-modern-view-all:hover .u-paging-modern-view-all__icon {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

/*------------------------------------
  Popover
------------------------------------*/

.popover-header {
  font-weight: 500;
}

/*------------------------------------
  Quantity
------------------------------------*/

.u-quantity {
  width: 4rem;
}

.u-quantity>.u-quantity__input {
  padding-right: 1.875rem;
}

.u-quantity>.u-quantity__input:not(:last-child) {
  border-radius: 0.3125rem;
}

.u-quantity__arrows {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: .75rem;
  z-index: 3;
}

.u-quantity__arrows-inner {
  display: block;
  color: var(--secondary);
  cursor: pointer;
}

.u-quantity__arrows-inner:hover {
  color: var(--primary);
}

/*------------------------------------
  Search Form - Push Top
------------------------------------*/

.u-search-push-top {
  display: none;
  background-color: #f8fafd;
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-shadow: inset 0 -10px 20px -10px rgba(151, 164, 175, 0.05);
}

.u-search-push-top__content {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.u-search-push-top__close-btn {
  position: absolute;
  top: -1.5625rem;
  right: .4375rem;
}

@media (max-width: 575.98px) {
  .u-search-push-top {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

/* Banner */

.u-search-push-top__banner {
  background-color: var(--white);
  box-shadow: 0 10px 25px rgba(151, 164, 175, 0.05);
  padding-right: 1.5625rem;
  padding-top: 1.875rem;
  overflow: hidden;
}

.u-search-push-top__banner-container {
  position: relative;
  width: 75%;
  min-height: 11.5625rem;
}

.u-search-push-top__banner-img {
  position: absolute;
  bottom: -.625rem;
  left: -.625rem;
  transition: all .3s ease-in-out;
}

.u-search-push-top__banner:hover .u-search-push-top__banner-img:first-child {
  bottom: 0;
  left: 0;
}

/*------------------------------------
  Search Form - Slide Down
------------------------------------*/

.u-search-slide-down {
  position: absolute;
  z-index: 1003;
  top: 5rem;
  left: calc(50% - 18rem) !important;
  width: 36rem;
}

.u-search-slide-down-trigger.active .u-search-slide-down-trigger__icon::before {
  content: "\f00d";
}

.u-search-slide-down-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  display: none;
  width: 100%;
  height: 100%;
}

.u-search-slide-down .u-search-slide-down__input {
  opacity: 0;
  -webkit-transform: translateY(-2rem);
  transform: translateY(-2rem);
  transition: opacity 0.2s, -webkit-transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98);
  transition: transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s;
  transition: transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s, -webkit-transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98);
}

.u-search-slide-down .u-search-slide-down__suggestions {
  opacity: 0;
  -webkit-transform: translateY(-3rem);
  transform: translateY(-3rem);
  transition: opacity 0.2s, -webkit-transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98);
  transition: transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s;
  transition: transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s, -webkit-transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98);
}

.u-search-slide-down.active .u-search-slide-down__input, .u-search-slide-down.active .u-search-slide-down__suggestions {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.u-search-slide-down.active .u-search-slide-down__suggestions {
  transition-delay: 0.2s;
}

/*------------------------------------
  Slick
------------------------------------*/

.u-slick {
  position: relative;
}

.u-slick--transform-off.slick-transform-off .slick-track {
  -webkit-transform: none !important;
  transform: none !important;
}

.u-slick-thumb-progress {
  width: 110%;
  height: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*------------------------------------
  Slick Equal Height
------------------------------------*/

.u-slick--equal-height .slick-list {
  height: 100%;
}

.u-slick--equal-height .slick-track {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.u-slick--equal-height .slick-track .slick-slide {
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

/*------------------------------------
  Slick Gutters
------------------------------------*/

/* Gutters X */

.u-slick--gutters-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.u-slick--gutters-1 .slick-slide {
  margin-left: .25rem;
  margin-right: .25rem;
}

.u-slick--gutters-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.u-slick--gutters-2 .slick-slide {
  margin-left: .5rem;
  margin-right: .5rem;
}

.u-slick--gutters-3 {
  margin-left: -.9375rem;
  margin-right: -.9375rem;
}

.u-slick--gutters-3 .slick-slide {
  margin-left: .9375rem;
  margin-right: .9375rem;
}

/*------------------------------------
  Slick Zoom
------------------------------------*/

.u-slick-zoom .slick-slide .u-slick-zoom__slide {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 0.5s ease-in-out;
}

.u-slick-zoom .slick-center .u-slick-zoom__slide {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------------------------------
  Slick Arrows
------------------------------------*/

.u-slick__arrow {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: var(--primary);
  background-color: rgba(55, 125, 255, 0.1);
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.3s;
}

.u-slick__arrow:hover {
  color: var(--white);
  background-color: var(--primary);
}

.u-slick__arrow-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.u-slick__arrow-inner--left {
  left: 0;
}

.u-slick__arrow-inner--right {
  right: 0;
}

/* Flat */

.u-slick__arrow--flat {
  color: var(--white);
  background-color: rgba(55, 125, 255, 0.8);
}

/* Flat White */

.u-slick__arrow--flat-white {
  color: var(--dark);
  background-color: var(--white);
}

.u-slick__arrow--flat-white:hover {
  color: var(--primary);
  background-color: var(--white);
}

/* Offset */

@media (min-width: 992px) {
  .u-slick__arrow--offset.u-slick__arrow-inner--left {
    left: -2.5rem;
  }
  .u-slick__arrow--offset.u-slick__arrow-inner--right {
    right: -2.5rem;
  }
}

/* Vertical Center Alignment */

.u-slick__arrow-centered--y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/*------------------------------------
  Slick Arrows Classic
------------------------------------*/

.u-slick__arrow-classic {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.1);
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.3s;
}

.u-slick__arrow-classic:hover {
  color: var(--white);
  background-color: var(--primary);
}

.u-slick__arrow-classic-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.u-slick__arrow-classic-inner--left {
  left: 0;
}

.u-slick__arrow-classic-inner--right {
  right: 0;
}

/*------------------------------------
  Slick Paging
------------------------------------*/

.u-slick__paging {
  position: absolute;
  bottom: 2rem;
  right: 0;
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.u-slick__paging .u-paging__current {
  color: var(--white);
  font-size: 3.875rem;
  font-weight: 300;
  line-height: 1;
}

.u-slick__paging .u-paging__divider {
  position: relative;
  margin-right: .5rem;
  margin-left: .25rem;
}

.u-slick__paging .u-paging__divider::before {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  content: "\002f";
}

.u-slick__paging .u-paging__total {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Slick Pagination
------------------------------------*/

.u-slick__pagination {
  padding-left: 0;
}

.u-slick__pagination:not(.u-slick__pagination--block) {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
}

.u-slick__pagination li {
  display: -ms-flexbox;
  display: flex;
  pointer-events: all;
  margin: 0 .25rem;
  cursor: pointer;
}

.u-slick__pagination li span {
  display: inline-block;
  width: 0.9375rem;
  height: 0.9375rem;
  box-sizing: border-box;
  background-color: #007BCE;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  transition: 0.3s;
}

.u-slick__pagination li.slick-active span {
  background-color: transparent;
  border-color: #007BCE;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.u-slick__pagination li button {
  display: inline-block;
  width: 0.9375rem;
  height: 0.9375rem;
  box-sizing: border-box;
  background-color: #007BCE;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  transition: 0.3s;
}

.u-slick__pagination li.slick-active button {
  background-color: transparent;
  border-color: #007BCE;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

/* White Version */

.u-slick__pagination--white li span {
  background-color: var(--white);
}

.u-slick__pagination--white li.slick-active span {
  border-color: var(--white);
}

/* Block */

.u-slick__pagination--block li {
  display: block;
}

/* Vertical Center Alignment */

.u-slick__pagination-centered--y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@media (min-width: 992px) {
  /* Vertical Option */
  .u-slick__pagination--vertical-lg li {
    display: block;
    margin: .25rem 0;
  }
  /* Vertical Option Positions */
  .u-slick__pagination--vertical-pos-v1-lg {
    position: absolute;
    top: 50%;
    left: -15.7%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

/*------------------------------------
  Slick Pagination Modern
------------------------------------*/

.u-slick--pagination-modern {
  width: 100%;
  max-width: 12.5rem;
}

.u-slick--pagination-modern .slick-slide {
  cursor: pointer;
  opacity: 0.7;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: 0.3s;
}

.u-slick--pagination-modern .slick-current {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------------------------------
  Slick Pagination Interactive
------------------------------------*/

.u-slick--pagination-interactive__text {
  color: rgba(255, 255, 255, 0.7);
}

.u-slick--pagination-interactive .slick-slide {
  cursor: pointer;
  color: var(--white);
  background: rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

.u-slick--pagination-interactive .slick-slide:hover {
  background: rgba(255, 255, 255, 0.15);
}

.u-slick--pagination-interactive .slick-slide:hover.slick-center {
  background: var(--white);
}

.u-slick--pagination-interactive .slick-center {
  background: var(--white);
}

.u-slick--pagination-interactive .slick-center .u-slick--pagination-interactive__title {
  color: var(--primary);
}

.u-slick--pagination-interactive .slick-center .u-slick--pagination-interactive__text {
  color: var(--secondary);
}

/*------------------------------------
  Spinner
------------------------------------*/

.spinner-border, .spinner-grow {
  vertical-align: middle;
}

/*------------------------------------
  Stats Progress
------------------------------------*/

.u-stats-progress {
  position: relative;
  width: 13.75rem;
  height: 13.75rem;
  border-radius: 50%;
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
  margin-left: auto;
  margin-right: auto;
}

.u-stats-progress__info {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  padding: 2rem;
  text-align: center;
}

/*------------------------------------
  SVG
------------------------------------*/

.svg-preloader {
  z-index: -1;
  background: url(../svg/circle-preloader.svg) center no-repeat !important;
  overflow: hidden;
  transition: all 0.4s ease-in;
}

.svg-preloader *:not(.u-icon):not(.btn):not(.u-media-player):not(.u-media-player__icon):not(.u-label) {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

/*------------------------------------
  SVG IE10+ specific styles go here
------------------------------------*/

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .u-header__promo-icon {
    height: 48px;
  }
  .ie-height-24 {
    height: 24px;
  }
  .ie-height-40 {
    height: 40px;
  }
  .ie-height-48 {
    height: 48px;
  }
  .ie-height-56 {
    height: 56px;
  }
  .ie-height-72 {
    height: 72px;
  }
  .ie-height-90 {
    height: 90px;
  }
  .ie-height-111 {
    height: 111px;
  }
  .ie-main-hero {
    height: 686px;
  }
  .ie-soft-triangle-shape {
    height: 610px;
  }
  .ie-browser {
    height: 565px;
  }
  .ie-wave-1-bottom {
    height: 100px;
  }
  .ie-vault, .ie-responsive, .ie-easy-payment {
    height: 210px;
  }
  .ie-devices-v1-tablet {
    height: 38.5rem;
  }
  .ie-devices-v1-phone {
    height: 27.5rem;
  }
  .ie-devices-v2-tablet {
    height: 37.875rem;
  }
  .ie-devices-v2-iphone {
    height: 31rem;
  }
  .ie-devices-v3-iphone {
    height: 633px;
  }
  .ie-devices-v4-tablet-horizontal {
    height: 16.875rem;
  }
  .ie-devices-v4-tablet {
    height: 25.4375rem;
  }
  .ie-devices-v4-laptop {
    height: 20.75rem;
  }
  .ie-devices-v4-iphone {
    height: 13.0625rem;
  }
  .ie-wave-6-top-left {
    height: 379px;
  }
  .ie-double-ellipse-top-right {
    height: 431px;
  }
  .ie-double-ellipse-top-left {
    height: 788px;
  }
  .ie-double-ellipse-bottom-right {
    height: 252px;
  }
  .ie-ellipse-mockup {
    height: 656px;
  }
  .ie-irregular-shape-2-right {
    height: 660px;
  }
  .ie-irregular-shape-3-bottom {
    height: 255px;
  }
  .ie-circle-chart {
    height: 219px;
  }
  .ie-curved-shape {
    height: 55.8px;
  }
  .ie-subscribe-illustration {
    height: 329px;
  }
  .ie-subscribe-1 {
    height: 315px;
  }
  .ie-subscribe-2 {
    height: 295px;
  }
  .ie-subscribe-2-flat-icons {
    height: 200px;
  }
  .ie-color-gradient {
    height: 566px;
  }
  .ie-for-sale, .ie-buyer {
    height: 208px;
  }
  .ie-events, .ie-data-report, .ie-image-upload {
    height: 219px;
  }
  .ie-analysis, .ie-in-the-office, .ie-make-it-rain {
    height: 200px;
  }
  .ie-house-agency {
    height: 381px;
  }
  .ie-laptop-and-iphone {
    height: 421px;
  }
  .ie-get-answered {
    height: 386px;
  }
  .ie-bg-elements-1 {
    height: 420px;
  }
  .ie-bg-elements-2 {
    height: 374px;
  }
  .ie-bg-elements-3 {
    height: 583px;
  }
  .ie-bg-elements-4 {
    height: 850px;
  }
  .ie-circle-1 {
    height: 379px;
  }
  .ie-go-to-wave {
    height: 46px;
  }
  .ie-graphic-illustration-1 {
    height: 270px;
  }
  .ie-app-development {
    height: 328px;
  }
  .ie-we-have-an-idea {
    height: 335px;
  }
  .ie-chatting-girl, .ie-chatting-boy {
    height: 328px;
  }
  .ie-virtual-reality {
    height: 320px;
  }
  .ie-maintenance-mode {
    height: 200px;
  }
  .ie-non-standard-hero-shape {
    height: 556px;
  }
  .ie-enterprise-2 {
    height: 267px;
  }
  .ie-abstract-shapes-1 {
    height: 554px;
  }
  .ie-abstract-shapes-2, .ie-abstract-shapes-3, .ie-abstract-shapes-4 {
    height: 532px;
  }
  .ie-abstract-shapes-6 {
    height: 187px;
  }
  .ie-abstract-shapes-7 {
    height: 624px;
  }
  .ie-abstract-shapes-9 {
    height: 182px;
  }
  .ie-abstract-shapes-10 {
    height: 573px;
  }
  .ie-abstract-shapes-11 {
    height: 192px;
  }
  .ie-abstract-shapes-12 {
    height: 597px;
  }
  .ie-abstract-shapes-13, .ie-abstract-shapes-14 {
    height: 615px;
  }
  .ie-showcase-mockup-1 {
    height: 384px;
  }
  .ie-showcase-mockup-2 {
    height: 371px;
  }
  .ie-showcase-mockup-3 {
    height: 535px;
  }
  .ie-knowledgebase-community {
    height: 447px;
  }
  .ie-knowledgebase-community-2 {
    height: 542px;
  }
  .ie-files {
    height: 293px;
  }
  .ie-half-circle-1-1 {
    height: 1136px;
  }
  .ie-half-circle-2-1 {
    height: 835px;
  }
  .ie-half-circle-2-2 {
    height: 660px;
  }
  .ie-iphone-x-1 {
    height: 601px;
  }
  .ie-half-iphone-x-1-iphone {
    height: 372px;
  }
  .ie-circles-hero, .ie-circles-cta {
    display: none;
  }
  .ie-communicating-men {
    height: 531px;
  }
  .ie-devices-1 {
    height: 637px;
  }
  .ie-support-man, .ie-list-app {
    height: 215px;
  }
  .ie-mobile-article {
    height: 268px;
  }
  .ie-working-men {
    height: 325px;
  }
  .ie-server-woman {
    height: 432px;
  }
  .ie-drawing-woman {
    height: 415px;
  }
  .ie-subscribe-3 {
    height: 338px;
  }
  .ie-hiker-man {
    height: 405px;
  }
  .ie-chatting-man {
    height: 268px;
  }
}

/*------------------------------------
  Tables
------------------------------------*/

.table-heighlighted thead th {
  border-bottom-color: var(--dark);
  border-top: none;
}

.table-heighlighted tfoot td {
  border-top: 2px solid var(--dark);
}

.table-light-bordered {
  border-color: rgba(255, 255, 255, 0.3);
}

.table-light-bordered thead th, .table-light-bordered th, .table-light-bordered td {
  border-color: rgba(255, 255, 255, 0.3);
}

/*------------------------------------
  Timeline
------------------------------------*/

.u-timeline {
  list-style: none;
  padding-left: 1rem;
  margin-bottom: 0;
}

.u-timeline__item {
  position: relative;
  padding-right: .9375rem;
  padding-left: 2rem;
}

.u-timeline__item:not(:last-child) {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.u-timeline__item::before {
  position: absolute;
  top: 3.5rem;
  bottom: 0;
  left: 0;
  height: auto;
  border-left: 0.1875rem solid #e7eaf3;
  content: "";
}

.u-timeline__icon {
  position: absolute;
  left: -.875rem;
}

.u-timeline__heading {
  position: relative;
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: .75rem;
  margin-bottom: 1rem;
}

.u-timeline__heading::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 0.1rem solid #e7eaf3;
  content: "";
}

/*------------------------------------
  Sticky Blocks
------------------------------------*/

[class*="sticky-block"] {
  max-width: 100%;
}

/*------------------------------------
  Background Gradients
------------------------------------*/

.gradient-half-primary-body-v1 {
  background-image: linear-gradient(150deg, var(--indigo) 0%, #19a0ff 100%);
  background-repeat: repeat-x;
  background-attachment: fixed;
}

.gradient-half-primary-v1 {
  background-image: linear-gradient(45deg, #00c1da 0, #003bca 100%);
  background-repeat: repeat-x;
}

.gradient-half-primary-v2 {
  background-image: linear-gradient(0deg, rgba(55, 125, 255, 0.05) 0%, transparent 100%);
  background-repeat: repeat-x;
}

.gradient-half-primary-v3 {
  background-image: linear-gradient(0deg, rgba(55, 125, 255, 0.1) 0%, transparent 100%);
  background-repeat: repeat-x;
}

.gradient-half-primary-v4 {
  background-image: linear-gradient(150deg, var(--indigo) 0%, #19a0ff 85%);
  background-repeat: repeat-x;
}

.gradient-half-primary-v5 {
  background-image: linear-gradient(150deg, var(--primary) 0%, var(--indigo) 100%);
  background-repeat: repeat-x;
}

.gradient-half-info-v1 {
  background-image: linear-gradient(0deg, var(--primary) 0%, var(--info) 100%);
  background-repeat: repeat-x;
}

.gradient-half-warning-v1 {
  background-image: linear-gradient(25deg, var(--warning) 30%, var(--danger) 100%);
  background-repeat: repeat-x;
}

.gradient-half-warning-v2 {
  background-image: linear-gradient(150deg, var(--warning) 0%, #efa02e 100%);
  background-repeat: repeat-x;
}

.gradient-half-warning-v3 {
  background-image: linear-gradient(150deg, var(--warning) 0%, var(--danger) 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/

[class*="gradient-overlay-half"] {
  position: relative;
  z-index: 1;
}

[class*="gradient-overlay-half"]::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
}

.gradient-overlay-half-primary-video-v1::before {
  z-index: 1;
  background: linear-gradient(45deg, #00c1da 0%, #003bca 100%);
}

.gradient-overlay-half-primary-v1::before {
  background-image: linear-gradient(45deg, #00c1da 0, #003bca 100%);
  background-repeat: repeat-x;
  opacity: .75;
  /*opacity: 1;*/
}

.gradient-overlay-half-primary-v2::before {
  background-image: linear-gradient(30deg, rgba(25, 160, 255, 0.85) 0%, rgba(45, 21, 130, 0.9) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-primary-v4::before {
  background-image: linear-gradient(0deg, rgba(55, 125, 255, 0.025) 0%, var(--white) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-indigo-v1::before {
  background-image: linear-gradient(45deg, transparent 50%, rgba(45, 21, 130, 0.05) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-info-v1::before {
  background-image: linear-gradient(0deg, rgba(55, 125, 255, 0.92) 0%, rgba(0, 223, 252, 0.92) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v1::before {
  background: rgba(0,0,0,.45) radial-gradient(rgba(0,0,0,0),rgba(0,0,0,0.1),rgba(0,0,0,0.8));
  background-repeat: repeat-x;
  opacity: .75;
}

.gradient-overlay-half-dark-v2::before {
  background-image: linear-gradient(150deg, rgba(34, 48, 73, 0.675) 0%, rgba(119, 131, 143, 0.3) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v3::before {
  background: linear-gradient(45deg, rgba(52, 58, 64, .98) 0%, rgba(52, 58, 64, .75) 100%);
}

/*------------------------------------
  Fill Colors
------------------------------------*/

.fill-none {
  fill: none !important;
}

.fill-white {
  fill: var(--white);
}

.fill-dark {
  fill: var(--dark) !important;
}

.fill-primary {
  fill: var(--primary) !important;
}

.fill-primary-lighter {
  fill: #1895ec !important;
}

.fill-primary-darker {
  fill: #005d9e !important;
}

.fill-success {
  fill: var(--success) !important;
}

.fill-success-lighter {
  fill: #13d7b6 !important;
}

.fill-info {
  fill: var(--info) !important;
}

.fill-danger {
  fill: var(--danger) !important;
}

.fill-danger-lighter {
  fill: #ec5245 !important;
}

.fill-warning {
  fill: var(--warning) !important;
}

.fill-warning-lighter {
  fill: #fdd14e !important;
}

.fill-warning-darker {
  fill: #efa02e !important;
}

.fill-gray-100 {
  fill: var(--light) !important;
}

.fill-gray-200 {
  fill: #f8fafd !important;
}

.fill-gray-300 {
  fill: #e7eaf3 !important;
}

.fill-gray-400 {
  fill: #bdc5d1 !important;
}

.fill-gray-500 {
  fill: #97a4af !important;
}

.fill-gray-600 {
  fill: var(--gray) !important;
}

/*------------------------------------
  Stop Colors
------------------------------------*/

.stop-color-white {
  stop-color: var(--white) !important;
}

.stop-color-primary {
  stop-color: var(--primary) !important;
}

.stop-color-primary-darker {
  stop-color: #196eff !important;
}

.stop-color-primary-lighter {
  stop-color: #19a0ff !important;
}

.stop-color-warning {
  stop-color: var(--warning) !important;
}

.stop-color-danger {
  stop-color: var(--danger) !important;
}

.stop-color-info {
  stop-color: var(--info) !important;
}

.stop-color-indigo {
  stop-color: var(--indigo) !important;
}

/*------------------------------------
  Stroke Colors
------------------------------------*/

.stroke-white {
  stroke: var(--white) !important;
}

.stroke-primary {
  stroke: var(--primary) !important;
}

.stroke-primary-lighter {
  stroke: #19a0ff !important;
}

.stroke-primary-darker {
  stroke: #196eff !important;
}

.stroke-success {
  stroke: var(--success) !important;
}

.stroke-danger {
  stroke: var(--danger) !important;
}

.stroke-warning {
  stroke: var(--warning) !important;
}

.stroke-info {
  stroke: var(--info) !important;
}

.stroke-gray-200 {
  stroke: #f8fafd !important;
}

.stroke-gray-300 {
  stroke: #e7eaf3 !important;
}

.stroke-gray-400 {
  stroke: #bdc5d1 !important;
}

.stroke-gray-500 {
  stroke: #97a4af !important;
}

/*------------------------------------
  Background Image Style
------------------------------------*/

.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 76%;
}

.bg-img-hero-bottom {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-hero-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-img-hero-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

/*------------------------------------
  Background Soft Colors
------------------------------------*/

.bg-soft-primary {
  background-color: rgba(55, 125, 255, 0.1);
}

.bg-soft-secondary {
  background-color: rgba(119, 131, 143, 0.1);
}

.bg-soft-success {
  background-color: rgba(0, 201, 167, 0.1);
}

.bg-soft-info {
  background-color: rgba(0, 223, 252, 0.1);
}

.bg-soft-warning {
  background-color: rgba(255, 193, 7, 0.1);
}

.bg-soft-danger {
  background-color: rgba(222, 68, 55, 0.1);
}

.bg-soft-light {
  background-color: rgba(248, 249, 250, 0.1);
}

.bg-soft-dark {
  background-color: rgba(30, 32, 34, 0.1);
}

.bg-soft-white {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-soft-indigo {
  background-color: rgba(45, 21, 130, 0.1);
}

.bg-soft-facebook {
  background-color: rgba(59, 89, 152, 0.1);
}

.bg-soft-google {
  background-color: rgba(209, 65, 48, 0.1);
}

.bg-soft-twitter {
  background-color: rgba(29, 161, 242, 0.1);
}

.bg-soft-instagram {
  background-color: rgba(63, 114, 155, 0.1);
}

.bg-soft-github {
  background-color: rgba(36, 41, 46, 0.1);
}

.bg-soft-gray {
  background-color: rgba(248, 250, 253, 0.1);
}

.bg-soft-light {
  background-color: var(--light);
}

/*------------------------------------
  Borders-radius
------------------------------------*/

.rounded-pill {
  border-radius: 6.1875rem;
}

.rounded-top-pill {
  border-top-left-radius: 6.1875rem;
  border-top-right-radius: 6.1875rem;
}

.rounded-right-pill {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.rounded-bottom-pill {
  border-bottom-right-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.rounded-left-pill {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.rounded-top-left-pill {
  border-top-left-radius: 6.1875rem;
}

.rounded-bottom-left-pill {
  border-bottom-left-radius: 6.1875rem;
}

.rounded-pseudo {
  border-radius: 0.3125rem;
}

.rounded-pseudo::before, .rounded-pseudo::after {
  border-radius: 0.3125rem;
}

.rounded-top-pseudo {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.rounded-top-pseudo::before, .rounded-top-pseudo::after {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

/*------------------------------------
  Borders
------------------------------------*/

.border-dashed {
  border: 1px dashed #e7eaf3;
}

/*------------------------------------
  Position Spaces
------------------------------------*/

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute;
  }
}

@media (min-width: 768px) {
  .top-md-0 {
    top: 0;
  }
  .right-md-0 {
    right: 0;
  }
  .bottom-md-0 {
    bottom: 0;
  }
  .left-md-0 {
    left: 0;
  }
  .position-md-absolute {
    position: absolute;
  }
}

@media (min-width: 992px) {
  .top-lg-0 {
    top: 0;
  }
  .right-lg-0 {
    right: 0;
  }
  .bottom-lg-0 {
    bottom: 0;
  }
  .left-lg-0 {
    left: 0;
  }
  .position-lg-absolute {
    position: absolute;
  }
}

/*------------------------------------
  Content Centered
------------------------------------*/

.content-centered-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@media (min-width: 768px) {
  .content-centered-y--md {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

@media (min-width: 992px) {
  .content-centered-y--lg {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

/*------------------------------------
  Margin Spacing
------------------------------------*/

.ml-n2 {
  margin-left: -.5rem;
}

.ml-n3 {
  margin-left: -1rem;
}

.mr-n2 {
  margin-right: -.5rem;
}

.mt-n1 {
  margin-top: -.25rem;
}

.mt-n5 {
  margin-top: -2rem;
}

.mt-n6 {
  margin-top: -2.5rem;
}

.mt-n9 {
  margin-top: -4rem;
}

.mt-n23 {
  margin-top: -10rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n9 {
  margin-bottom: -4rem;
}

/* Gutters X */

.mx-gutters-1 {
  margin-right: -.25rem;
  margin-left: -.25rem;
}

.mx-gutters-1>.col, .mx-gutters-1>[class*="col-"] {
  padding-right: .25rem;
  padding-left: .25rem;
}

.mx-gutters-2 {
  margin-right: -.5rem;
  margin-left: -.5rem;
}

.mx-gutters-2>.col, .mx-gutters-2>[class*="col-"] {
  padding-right: .5rem;
  padding-left: .5rem;
}

/*------------------------------------
  Opacity
------------------------------------*/

.opacity-md {
  opacity: .2;
}

/*------------------------------------
  Box Shadow
------------------------------------*/

.shadow-primary-lg {
  box-shadow: 0 0 50px rgba(55, 125, 255, 0.4) !important;
}

.shadow-soft {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25) !important;
}

/*------------------------------------
  Width
------------------------------------*/

.min-width-3 {
  min-width: 1rem !important;
}

.min-width-4 {
  min-width: 1.5rem !important;
}

.min-width-15 {
  min-width: 7rem !important;
}

.min-width-21 {
  min-width: 10rem !important;
}

.max-width-3 {
  max-width: 1rem !important;
}

.max-width-4 {
  max-width: 1.5rem !important;
}

.max-width-5 {
  max-width: 2rem !important;
}

.max-width-6 {
  max-width: 2.5rem !important;
}

.max-width-8 {
  max-width: 3.5rem;
}

.max-width-9 {
  max-width: 4rem;
}

.max-width-10 {
  max-width: 4.5rem;
}

.max-width-11 {
  max-width: 5rem;
}

.max-width-15 {
  max-width: 7rem;
}

.max-width-19 {
  max-width: 9rem;
}

.max-width-23 {
  max-width: 11rem;
}

.max-width-27 {
  max-width: 13rem;
}

.max-width-35 {
  max-width: 17rem;
}

.max-width-40 {
  max-width: 19.5rem;
}

.max-width-50 {
  max-width: 24.5rem;
}

.max-width-60 {
  max-width: 29.5rem;
}

/*------------------------------------
  Height
------------------------------------*/

.height-40vh {
  height: 40vh;
}

.height-60vh {
  height: 60vh;
}

.height-100vh {
  height: 100vh;
}

.height-4 {
  height: .25rem;
}

.height-250 {
  height: 15.625rem;
}

.height-380 {
  height: 23.75rem;
}

.height-400 {
  height: 25rem;
}

.height-450 {
  height: 28.125rem;
}

.min-height-100vh {
  min-height: 100vh;
}

.min-height-155 {
  min-height: 9.6875rem;
}

.min-height-300 {
  min-height: 18.75rem;
}

.min-height-380 {
  min-height: 23.75rem;
}

.min-height-450 {
  min-height: 28.125rem;
}

.min-height-550 {
  min-height: 34.375rem;
}

.min-height-600 {
  min-height: 37.5rem;
}

.min-height-620 {
  min-height: 38.75rem;
}

@media (min-width: 576px) {
  .height-sm-100vh {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .height-md-60vh {
    height: 60vh;
  }
  .height-md-100vh {
    height: 100vh;
  }
  .min-height-md-100vh {
    height: 100%;
  }
}

@media (min-width: 992px) {
  .height-lg-100vh {
    height: 100vh;
  }
  .min-height-lg-auto {
    min-height: auto;
  }
  .min-height-lg-100vh {
    min-height: 100vh;
  }
}

@media (min-width: 1200px) {
  .min-height-xl-100vh {
    min-height: 100vh;
  }
}

/*------------------------------------
  SVG Dividers
------------------------------------*/

.svg-divider-polygon {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 35%, 0 95%);
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 95%);
}

/*------------------------------------
  Typography Font Size
------------------------------------*/

.font-size-1 {
  font-size: 0.875rem !important;
}

.font-size-2 {
  font-size: 1.5rem !important;
}

.font-size-3 {
  font-size: 2rem !important;
}

.font-size-4 {
  font-size: 2.5rem !important;
}

.font-size-5 {
  font-size: 3rem !important;
}

@media (max-width: 991.98px) {
  .font-size-md-down-3 {
    font-size: 2rem;
  }
  .font-size-md-down-5 {
    font-size: 3rem;
  }
}

/*------------------------------------
  Font Weight
------------------------------------*/

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

/*------------------------------------
  Line Height
------------------------------------*/

.text-lh-sm {
  line-height: 1.2;
}

.text-lh-md {
  line-height: 1.8;
}

/*------------------------------------
  Text Colors
------------------------------------*/

.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-white-70[href]:hover {
  color: var(--white);
}

.text-white-85 {
  color: rgba(255, 255, 255, 0.85);
}

.text-white-85[href]:hover {
  color: var(--white);
}


/*------------------------------------
  Transform
------------------------------------*/

.transform-rotate-1 {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  -webkit-transform-origin: 30% 20%;
  transform-origin: 30% 20%;
}

/*------------------------------------
  Transitions
------------------------------------*/

/* 3D Hover */

.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover, .transition-3d-hover:focus {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08) !important;
}

/*------------------------------------
  Z-Index
------------------------------------*/

.z-index-n1 {
  z-index: -1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-4 {
  z-index: 4;
}

/*------------------------------------
  Animate.css
------------------------------------*/

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

/*------------------------------------
  Area Chart
------------------------------------*/

.u-area-chart {
  position: relative;
  line-height: 0;
}

.u-area-chart .ct-point {
  stroke-width: .375rem;
}

.u-area-chart__tooltip {
  color: var(--secondary);
  background-color: var(--white);
  border-radius: 0.3125rem;
  box-shadow: 0 0.375rem 0.9375rem 0.0625rem rgba(140, 152, 164, 0.2);
  padding: 1rem;
  font-size: .75rem;
  font-weight: 400;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.u-area-chart__tooltip::before {
  border: 8px var(--white);
  margin-left: -.5rem;
}

.u-area-chart__tooltip .chartist-tooltip-value {
  font-weight: 600;
}

.info:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(249,251,253)' fill-opacity='1'%3E%3Cpolygon points='0,250 100,250 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: -1;
  top: -250px;
}

.position-relative.space-2.space-md-3.space-lg-4.notebook:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(249,251,253)' fill-opacity='1'%3E%3Cpolygon points='0,250 100,250 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: -1;
  top: -250px;
}

.js-header-fix-moment .flags.ml-auto {
  display: none;
}

.js-header-fix-moment div#navBar {
  padding-top: 0 !important;
}

.js-header-fix-moment .navbar-expand-md .u-header__navbar-nav .u-header__nav-link {
  padding-top: 1.5rem;
}

.js-header-fix-moment a.btn.btn-sm:not(.myaccount) {
  background: linear-gradient(to right, #ff6d1f 0%, #ffca22 100%) !important;
  color: var(--white) !important;
  border: none;
  transition: box-shadow .2s ease-in-out;
}

.js-header-fix-moment a.btn.btn-sm:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08) !important;
}

.ribbon:before {
  content: "";
  border-top: 3px solid #2b6f2e;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
  left: 6px;
}

.ribbon:after {
  content: "";
  border-top: 3px solid #2b6f2e;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
  right: 10px;
}

.completed.ribbon:before {
  content: "";
  border-top: 3px solid #616161;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
  left: 6px;
}

.completed.ribbon:after {
  content: "";
  border-top: 3px solid #616161;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
  right: 10px;
}

/*------------------------------------
  Cubeportfolio
------------------------------------*/

.u-cubeportfolio .u-cubeportfolio__item {
  font-size: .875rem;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  margin: 0;
  padding-left: .75rem;
  padding-right: .75rem;
}

.u-cubeportfolio .u-cubeportfolio__item:first-child {
  padding-left: 0;
}

.u-cubeportfolio .u-cubeportfolio__item:last-child {
  padding-right: 0;
}

.u-cubeportfolio .u-cubeportfolio__item:hover {
  color: var(--dark);
}

.u-cubeportfolio .u-cubeportfolio__item.cbp-filter-item-active {
  color: var(--primary);
  background-color: transparent;
  border: transparent;
}

/* Load more disabled class */

.u-cubeportfolio .cbp-l-loadMore-stop {
  pointer-events: none;
  color: var(--secondary);
}

/* Zoom overlay effect */

.u-cubeportfolio .cbp-caption-zoom .cbp-caption-activeWrap {
  background-color: rgba(55, 125, 255, 0.9);
}

/*------------------------------------
  Hover Animation Effects
------------------------------------*/

.u-cubeportfolio--reveal-v1 .cbp-l-caption-body {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: 0.4s ease-in-out;
}

.u-cubeportfolio--reveal-v1 .cbp-caption:hover .cbp-l-caption-body {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

/*------------------------------------
  Custombox
------------------------------------*/

.custombox-lock {
  overflow: auto;
}

.u-custombox-no-scroll.custombox-lock {
  margin-right: 1.0625rem;
  overflow: hidden;
}

/*------------------------------------
  Datatable
------------------------------------*/

.u-datatable .dataTables_length, .u-datatable .dataTables_filter, .u-datatable .dataTables_info, .u-datatable .dataTables_paginate {
  display: none;
}

.u-datatable__striped .odd {
  background-color: #f8fafd;
}

.u-datatable .u-datatable__trigger {
  cursor: pointer;
}

.u-datatable .u-datatable__trigger-icon::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  margin-right: .75rem;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.u-datatable__content td[colspan] {
  padding-left: 0;
  padding-right: 0;
}

.u-datatable .opened .u-datatable__trigger-icon::before {
  content: "\f106";
}

.u-datatable__thead-icon {
  display: block;
  line-height: .7;
  cursor: pointer;
  color: #bdc5d1;
}

.u-datatable__thead-icon:hover {
  color: var(--primary);
}

/*------------------------------------
  Dropzone
------------------------------------*/

.u-dropzone {
  width: 100%;
  background-color: #f8fafd;
  border: 2px dashed #e7eaf3;
  border-radius: 0.3125rem;
  cursor: pointer;
  padding: 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.u-dropzone .dz-message {
  width: 100%;
  text-align: center;
}

.u-dropzone .dz-details {
  margin-bottom: 1rem;
}

.u-dropzone .dz-file-preview {
  background-color: var(--white);
  border-radius: 0.3125rem;
  padding: 1rem;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}

.u-dropzone .dz-file-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-dropzone .dz-filename {
  margin-bottom: .25rem;
}

.u-dropzone .dz-img {
  max-width: 4rem;
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
  padding: 0.25rem;
  margin-right: .75rem;
}

.u-dropzone .dz-close-icon, .u-dropzone .dz-size {
  color: var(--secondary);
}

.u-dropzone .dz-title {
  font-size: 0.75rem;
  font-weight: 600;
}

.u-dropzone .dz-size {
  font-size: 80%;
}

.u-dropzone .dz-file-abbr {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  line-height: 4;
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary);
  text-align: center;
  background-color: rgba(55, 125, 255, 0.1);
  border-radius: 0.3125rem;
  margin-right: .75rem;
}

.u-dropzone [data-dz-thumbnail]:not([src]) {
  display: none;
  margin-bottom: 0;
}

.u-dropzone .dz-progress {
  margin-bottom: 1rem;
}

.u-dropzone.dz-started .u-dropzone__message, .u-dropzone .dz-processing .dz-error-mark, .u-dropzone .dz-processing .dz-success-mark {
  display: none;
}

.u-dropzone .dz-processing.dz-error .dz-error-mark, .u-dropzone .dz-processing.dz-success .dz-success-mark {
  display: block;
}

.u-dropzone .dz-processing .dz-error-mark {
  color: var(--danger);
}

.u-dropzone .dz-processing .dz-success-mark {
  color: var(--success);
}

/*------------------------------------
  DatePicker
------------------------------------*/

.u-datepicker {
  position: relative;
}

.flatpickr-calendar {
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
}

.flatpickr-calendar::before, .flatpickr-calendar::after {
  display: none;
}

.flatpickr-months {
  display: flex;
  align-items: center;
  position: relative;
  padding: .25rem 0;
  height: max-content;
  background-color: var(--primary);
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.flatpickr-month, .flatpickr-prev-month, .flatpickr-next-month {
  position: relative !important;
  height: max-content !important;
}

.flatpickr-current-month {
  position: relative !important;
  width: 100% !important;
  height: max-content !important;
  color: var(--white) !important;
  left: initial !important;
  padding: 0;
}

.flatpickr-prev-month svg, .flatpickr-prev-month i, .flatpickr-next-month svg, .flatpickr-next-month i {
  fill: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);
}

.flatpickr-prev-month:hover svg, .flatpickr-prev-month:hover i, .flatpickr-next-month:hover svg, .flatpickr-next-month:hover i {
  fill: var(--white);
  color: var(--white);
}

.flatpickr-weekday {
  color: var(--dark);
  text-transform: uppercase;
  font-weight: 500;
}

.flatpickr-days {
  margin-bottom: .25rem;
}

.flatpickr-day {
  color: var(--dark);
  border-color: transparent;
}

.flatpickr-day:focus, .flatpickr-day:hover {
  color: var(--primary);
  background-color: rgba(55, 125, 255, 0.1);
}

.flatpickr-day.inRange {
  background-color: #e7eaf3;
  box-shadow: -0.3125rem 0 0 #e7eaf3, 0.3125rem 0 0 #e7eaf3;
}

.flatpickr-day.today {
  color: var(--white);
  background-color: var(--primary);
}

.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: #e7eaf3;
}

.flatpickr-day.prevMonthDay:focus, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:focus, .flatpickr-day.nextMonthDay:hover {
  color: #97a4af;
  background-color: #e7eaf3;
}

.flatpickr-day.disabled {
  color: #e7eaf3;
}

.flatpickr-day.disabled:hover {
  color: #97a4af;
  background-color: #e7eaf3;
}

.flatpickr-day.selected {
  color: var(--primary);
  background-color: transparent;
  border-color: var(--primary);
}

.flatpickr-day.selected:focus.prevMonthDay, .flatpickr-day.selected:focus.nextMonthDay, .flatpickr-day.selected:hover.prevMonthDay, .flatpickr-day.selected:hover.nextMonthDay {
  color: var(--primary);
  background-color: transparent;
}

.flatpickr-day.selected.startRange, .flatpickr-day.selected.endRange {
  color: var(--white);
  background-color: var(--primary);
}

.numInputWrapper span.arrowUp, .numInputWrapper span.arrowDown {
  display: none;
}

.numInputWrapper:hover {
  background-color: transparent;
}

/* Positions */

.u-datepicker--top .flatpickr-calendar {
  top: auto !important;
  bottom: 3.5rem !important;
}

/*------------------------------------
  Dzsparallaxer
------------------------------------*/

.dzsparallaxer {
  color: inherit;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/

.u-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.u-fancybox-theme .fancybox-bg {
  background-color: var(--dark);
}

.fancybox-controls--canzoomIn .fancybox-placeholder, .fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: inherit;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation, .fancybox-slide--current.has-animation, .fancybox-slide--next.has-animation, .fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated, .fancybox-slide--current.has-animation.animated, .fancybox-slide--next.has-animation.animated, .fancybox-slide--previous.has-animation.animated {
  display: block;
}

.u-blur-30 {
  -webkit-filter: blur(30px);
  filter: blur(30px);
}

/*------------------------------------
  Fileuploader
------------------------------------*/

/* Default Style */

.fileuploader {
  margin-top: 0;
}

/* Front Style */

.u-fileuploader-input {
  display: block;
  cursor: pointer;
  background: var(--white);
  border: 2px dashed #e7eaf3;
  border-radius: 0.3125rem;
  padding: 2rem 0;
  text-align: center;
}

.u-fileuploader-input__icon {
  display: block;
  color: var(--primary);
  font-size: 2.5rem;
}

/* Dragging State */

.u-fileuploader-input__inner, .u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__icon {
  transition: all 0.3s ease-in-out;
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__inner {
  opacity: .6;
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__icon {
  -webkit-transform: translateY(18px);
  transform: translateY(18px);
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__btn {
  -webkit-transform: none;
  transform: none;
  opacity: 1;
}

/*------------------------------------
  Onscroll Animation
------------------------------------*/

[data-animation]:not(.u-in-viewport) {
  visibility: hidden;
}

[data-animation].js-carousel {
  visibility: visible;
}

.u-in-viewport {
  visibility: visible;
}

/*------------------------------------
  Range Slider
------------------------------------*/

.u-range-slider {
  height: 1.25rem;
}

.u-range-slider .irs {
  height: 1.25rem;
}

.u-range-slider .irs-line {
  top: .5rem;
  height: 0.5rem;
}

.u-range-slider .irs-line-left {
  left: 0;
  height: 0.5rem;
  background-color: #e7eaf3;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.u-range-slider .irs-line-mid {
  height: 0.5rem;
  background-color: #e7eaf3;
}

.u-range-slider .irs-line-right {
  right: 0;
  height: 0.5rem;
  background-color: #e7eaf3;
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.u-range-slider .irs-bar {
  top: .5rem;
  height: 0.5rem;
  background-color: var(--primary);
}

.u-range-slider .irs-bar-edge {
  top: .5rem;
  left: .0625rem;
  height: 0.5rem;
  width: .6875rem;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
  background-color: var(--primary);
}

.u-range-slider .irs-slider {
  top: -.1875rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--white);
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0.1875rem 0.75rem rgba(140, 152, 164, 0.3125);
}

.u-range-slider .irs-slider.state_hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.u-range-slider .irs-from, .u-range-slider .irs-to, .u-range-slider .irs-single {
  display: inline-block;
  min-width: 2.5rem;
  background-color: var(--white);
  color: var(--dark);
  font-size: 0.875rem;
  text-shadow: none;
  text-align: center;
  border-radius: 0.3125rem;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
  padding: .5rem;
}

/* Indicator */

.u-range-slider-indicator {
  height: 5rem;
}

.u-range-slider-indicator .irs {
  height: 5rem;
}

.u-range-slider-indicator.u-range-slider-grid {
  height: 8rem;
}

.u-range-slider-indicator.u-range-slider-grid .irs {
  height: 8rem;
}

.u-range-slider-indicator .irs-line {
  top: 3.875rem;
}

.u-range-slider-indicator .irs-bar {
  top: 3.875rem;
}

.u-range-slider-indicator .irs-bar-edge {
  top: 3.875rem;
}

.u-range-slider-indicator .irs-slider {
  top: 3.1875rem;
}

/* Grid */

.u-range-slider-grid {
  height: 4.5rem;
}

.u-range-slider-grid .irs {
  height: 4.5rem;
}

.u-range-slider-grid .irs-grid {
  height: 2.5rem;
}

.u-range-slider-grid .irs-grid-text {
  display: inline-block;
  min-width: 2.5rem;
  line-height: 1;
  font-size: 0.875rem;
  color: var(--secondary);
  border-radius: 6.1875rem;
  padding: 0.25rem;
}

.u-range-slider-grid .irs-grid-text.current {
  background: rgba(55, 125, 255, 0.1);
  color: var(--primary);
}

.u-range-slider-grid .irs-grid-pol {
  height: .75rem;
  background-color: #e7eaf3;
}

.u-range-slider-grid .irs-grid-pol.small {
  display: none;
}

/*------------------------------------
  Select
------------------------------------*/

.dropdown-select.bootstrap-select li .dropdown-item.opt {
  padding-left: 1.5rem;
}

.dropdown-select.bootstrap-select .bs-searchbox {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.dropdown-select.bootstrap-select .dropdown-header .text {
  font-size: 80%;
  font-weight: 500;
  color: var(--dark);
  text-transform: uppercase;
}

.dropdown-select .form-control {
  margin-bottom: .5rem;
}

/*------------------------------------
  Slick
------------------------------------*/

[data-lazy] {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.slick-cloned [data-scs-animation-in] {
  opacity: 0;
}

.u-slick {
  background: url(../svg/circle-preloader.svg) no-repeat 50% 50%;
}

.u-slick .js-next, .u-slick .js-prev {
  opacity: 0;
}

.u-slick.slick-initialized {
  background: none;
}

.u-slick.slick-initialized .js-slide, .u-slick.slick-initialized .js-thumb {
  visibility: visible;
  height: auto;
  overflow: visible;
}

.u-slick.slick-initialized .js-next, .u-slick.slick-initialized .js-prev {
  opacity: 1;
}

.u-slick .js-slide, .u-slick .js-thumb {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.u-slick .js-slide:first-child, .u-slick .js-thumb:first-child {
  height: auto;
}

/*------------------------------------
  Summernote Editor
------------------------------------*/

.note-modal-footer {
  height: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 20px 30px;
}

.note-modal-footer .note-btn {
  float: inherit;
}

.u-summernote-editor .note-editor.note-frame {
  border-color: #e7eaf3;
}

.u-summernote-editor .note-editor.note-frame .note-statusbar {
  background-color: #f8fafd;
  border-top-color: #e7eaf3;
}

.u-summernote-editor .note-toolbar {
  background-color: #f8fafd;
}

.u-summernote-editor .note-toolbar>.note-btn-group {
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
}

.u-summernote-editor .note-toolbar .note-dropdown-menu {
  min-width: 12.5rem;
  border-color: #e7eaf3;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
  padding: .5rem;
}

.u-summernote-editor .note-toolbar .note-color .note-dropdown-menu {
  min-width: 21.5rem;
}

.u-summernote-editor .note-btn:focus, .u-summernote-editor .note-btn:hover {
  color: var(--primary);
  background-color: transparent;
}

.u-summernote-editor .note-dropdown-item:hover {
  background-color: #f8fafd;
}

.u-summernote-editor .dropdown-toggle::after {
  display: none;
}

/*------------------------------------
  Tagsinput
------------------------------------*/

.u-tagsinput .bootstrap-tagsinput {
  width: 100%;
  border-color: #e7eaf3;
  border-radius: 0.3125rem;
  box-shadow: none;
  padding: .25rem;
  padding-bottom: 0;
}

.u-tagsinput .bootstrap-tagsinput::before {
  content: "|";
  display: inline-block;
  width: 1px;
  line-height: 1;
  font-size: .625rem;
  opacity: 0;
  padding: .75rem 0;
}

.u-tagsinput .bootstrap-tagsinput .tag {
  position: relative;
  display: inline-block;
  font-size: .875rem;
  color: var(--secondary);
  background-color: rgba(119, 131, 143, 0.1);
  border-radius: 0.3125rem;
  padding: .25rem 1.875rem .25rem .75rem;
  margin-bottom: .25rem;
  margin-right: 0;
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"] {
  position: absolute;
  right: .5rem;
  top: 50%;
  color: var(--secondary);
  font-size: 1.25rem;
  outline: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"]::after {
  content: "\00d7";
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"]:hover {
  color: var(--dark);
  box-shadow: none;
}

/*------------------------------------
  Typed.js
------------------------------------*/

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*------------------------------------
  File for your custom SCSS style
------------------------------------*/
