.main-theme,
:root {
	--soft-primary: rgba(55, 125, 255, 0.1);

	--main-bg: #ffffff;
	--main-ct: #1e2022;
	--main-title: #007bd2;
	--main-text: #77838f;
	--main-icon: #77838f;
	--main-border: #ebebeb;
	--main-transition: 350ms;

	--bg-pr: #007bd2;
	--ct-pr: #ffffff;
	--ic-pr: #ffffff;
	--bg-pr-hover: #d7e6ff;
	--ct-pr-hover: #007bd2;
	--ic-pr-hover: #007bd2;
	--bg-pr-focus: ;
	--ct-pr-focus: ;
	--ic-pr-focus: ;
	--bg-pr-active: ;
	--ct-pr-active: ;
	--ic-pr-active: ;
	--bg-pr-gradient: linear-gradient(45deg, #00c1da 0%, #003bca 100%);

	--bg-se: rgba(55, 125, 255, 0.1);
	--ct-se: #007bd2;
	--ic-se: #007bd2;
	--bg-se-hover: #007bd2;
	--ct-se-hover: #ffffff;
	--ic-se-hover: #ffffff;
	--bg-se-focus: ;
	--ct-se-focus: ;
	--ic-se-focus: ;
	--bg-se-active: ;
	--ct-se-active: ;
	--ic-se-active: ;
	--bg-se-gradient: ;

	--bg-success: #00c853;
	--ct-success: #ffffff;
	--ic-success: #d4d4d4;
	--bg-success-hover: #7bc800;
	--ct-success-hover: #000000;
	--ic-success-hover: #2b2b2b;
	--bg-success-focus: ;
	--ct-success-focus: ;
	--ic-success-focus: ;
	--bg-success-active: ;
	--ct-success-active: ;
	--ic-success-active: ;
	--bg-success-gradient: linear-gradient(to right, #00c854 0%, #7bc800 100%);

	--bg-danger: #ff6d1f;
	--ct-danger: #ffffff;
	--ic-danger: #d4d4d4;
	--bg-danger-hover: #ffca22;
	--ct-danger-hover: #000000;
	--ic-danger-hover: #2b2b2b;
	--bg-danger-focus: ;
	--ct-danger-focus: ;
	--ic-danger-focus: ;
	--bg-danger-active: ;
	--ct-danger-active: ;
	--ic-danger-active: ;
	--bg-danger-gradient: linear-gradient(to right, #ff6d1f 0%, #ffca22 100%);

	--bg-warning: ;
	--ct-warning: ;
	--ic-warning: ;
	--bg-warning-hover: ;
	--ct-warning-hover: ;
	--ic-warning-hover: ;
	--bg-warning-focus: ;
	--ct-warning-focus: ;
	--ic-warning-focus: ;
	--bg-warning-active: ;
	--ct-warning-active: ;
	--ic-warning-active: ;
	--bg-warning-gradient: ;

	--bg-disabled: ;
	--ct-disabled: ;
	--ic-disabled: ;
	--bg-disabled-hover: ;
	--ct-disabled-hover: ;
	--ic-disabled-hover: ;
	--bg-disabled-focus: ;
	--ct-disabled-focus: ;
	--ic-disabled-focus: ;
	--bg-disabled-active: ;
	--ct-disabled-active: ;
	--ic-disabled-active: ;
	--bg-disabled-gradient: ;
}
